<template>
  <div class="absolute rounded-[12px] bg-white p-8 top-0 translate-y-8 drop-shadow-xl">
      <ul class="list-none m-0 p-0 flex flex-col space-y-5">
          <li v-for="(item, i) in items" :key="`nav-dropdown-${i}`" class="text-[14px] text-gray-900 hover:text-red-500 leading-5 font-medium select-none">
              {{item.title}}
          </li>
      </ul>
  </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array
        }
    }
}
</script>

<style>

</style>