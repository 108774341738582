import { format, zonedTimeToUtc } from 'date-fns-tz'

export const convertDateTimeToUserTimezone = (dateTime, formatString) => {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const userDateTime = zonedTimeToUtc(dateTime, currentTimezone)
  return format(new Date(userDateTime), formatString)
}

export const getTimeStampFromUTC = (dateTime) => {
  return new Date(`${dateTime.replace(/-/g, '/')} UTC`).getTime()
}

export const isPastEvent = (eventTimeStamp) => {
  // Sets the current date and event date (plus one hour since most events last about an hour) to time in miliseconds for comparison (i.e.: 1631734700814)
  const eventEndTimeStamp = new Date(eventTimeStamp).getTime() + (1 * 60 * 60 * 1000)
  return Date.now() > eventEndTimeStamp
}
