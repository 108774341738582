<template>
  <div :id="formId">
    <div v-if="simpleForm" class="simple-form">
      <form :id="`mktoForm_${formId}`" v-if="state !== 'success'"></form>
      <p
        v-if="state === 'success' && successMessage.length > 0"
        class="message is-success"
        v-html="successMessage"
      >
        {{ successMessage }}
      </p>
      <p v-if="state === 'fail'" class="message is-error">
        {{ failureMessage }}
      </p>
    </div>
    <div class="marketo-form" v-else>
      <h3 class="form-title" v-if="title">{{ title }}</h3>
      <p v-if="description" class="form-description sub-heading">
        {{ description }}
      </p>
      <form :id="`mktoForm_${formId}`" v-if="state !== 'success'"></form>
      <p v-if="state === 'success'" class="message is-success">
        {{ successMessage }}
      </p>
      <p v-if="state === 'fail'" class="message is-error">
        {{ failureMessage }}
      </p>
    </div>
    <div
      v-if="state === 'success' && successMessage.length > 0"
      class="spacer-block"
    ></div>
    <img
      v-if="state === 'success' && linkedInConversionID"
      height="1"
      width="1"
      style="display: none"
      alt=""
      :src="`https://px.ads.linkedin.com/collect/?pid=3181713&conversionId=${linkedInConversionID}&fmt=gif`"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useFormStore } from '~/store/form'
import { useUserStore } from '~/store/user'
import { useGlobalStore } from '~/store/global'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    formId: {
      type: String,
      default: '1014'
    },
    simpleForm: {
      type: Boolean,
      default: false
    },
    successMessage: {
      type: String,
      default: ''
    },
    failureMessage: {
      type: String,
      default: 'Error'
    },
    type: {
      type: String,
      default: ''
    },
    successRedirect: {
      type: String,
      default: ''
    },
    ctaText: {
      type: String,
      default: ''
    },
    disableCookie: {
      type: Boolean,
      default: false
    },
    analyticsLabel: {
      type: String,
      default: ''
    },
    linkedInConversionID: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: 'ready',
      routeType: null,
      marketoFormMounted: false
    }
  },
  watch: {
    marketoFormMounted() {
      if (this.marketoFormMounted) {
        const emailElement = document.getElementById('Email')
        if (emailElement) {
          emailElement.addEventListener('blur', (e) => {
            this.setEmail(e.target.value)
          })
        }

        const firstNameElement = document.getElementById('FirstName')
        if (firstNameElement) {
          firstNameElement.addEventListener('blur', (e) => {
            this.setFirstName(e.target.value)
          })
        }

        const lastNameElement = document.getElementById('LastName')
        if (lastNameElement) {
          lastNameElement.addEventListener('blur', (e) => {
            this.setLastName(e.target.value)
          })
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState(useFormStore, ['email', 'firstName', 'lastName']),
    ...mapState(useUserStore, ['userId']),
    ...mapState(useGlobalStore, ['queryParams'])
  },
  methods: {
    ...mapActions(useFormStore, ['setEmail', 'setFirstName', 'setLastName']),

    init() {
      const vm = this
      const formId = vm.formId
      let buttonText = this.ctaText ? this.ctaText : "Let's do this"
      createUtmFields()
      if (vm.type === 'Webinar') {
        const currentDate = new Date().getTime()
        const eventDate = new Date(vm.$attrs.eventDate).getTime()

        buttonText = currentDate >= eventDate ? 'Watch Now' : 'Register'
      }
      if (vm.type === 'Guide' || vm.type === 'eBook') {
        buttonText = 'Download Now'
      }
      if (vm.type === 'email') {
        buttonText = 'Sign Me Up'
      }

      if (
        this.$route.path.includes('subscribe') ||
        window.location.href.includes('story')
      ) {
        this.routeType = 'subscribe'
      } else if (this.$route.path.includes('demo')) {
        this.routeType = 'demo'
      } else if (this.$route.path.includes('head-to-head-on-headless')) {
        this.routeType = 'headless'
        buttonText = 'Access All Videos'
      } else if (this.$route.path.includes('headless-commerce-101')) {
        this.routeType = 'download'
      }

      vm.checkProgression()
      MktoForms2.loadForm(
        '//app-ab42.marketo.com',
        '750-WWD-574',
        parseInt(vm.formId),
        function (form) {
          const values = [...document.querySelectorAll('input.mktoRequired')]
          vm.marketoFormMounted = true
          form.onSuccess(function (values, followUpUrl) {
            document.querySelector('.mktoButton').innerText = buttonText
            vm.state = 'success'
            if (vm.firstName) {
              vm.$identify({
                id: vm.userId,
                name: `${vm.firstName} ${vm.lastName}`,
                email: vm.email
              })
            }

            if (values.Email.length > 0) {
              vm.setEmail(values.Email)
            }
            document.getElementById(`mktoForm_${formId}`).style.display = 'none'
            if (vm.type !== '') {
              vm.$emit('handleForm')
            }

            if (vm.routeType === 'subscribe') {
              // Do not change name- tracked specifically in Segment
              vm.$track({
                name: 'Lead-Subscribe',
                category: 'Content',
                label: 'Subscribed To Blog'
              })
            } else if (vm.routeType === 'demo') {
              // Do not change name- tracked specifically in Segment
              vm.$track({
                name: 'Lead-Demo',
                category: 'Form Submission',
                label: vm.analyticsLabel
              })
            } else if (vm.routeType === 'download') {
              vm.$track({
                name: 'Lead-Download',
                category: 'Form Submission',
                label: 'Download Headless Commerce 101'
              })
            } /* else {
              vm.$track({
                name: `Lead-${vm.$route.params.handle}`,
                category: 'Form Submission'
              })
            } */

            // If is headless landing, set gated cookie. If not, set blog cookie.
            if (vm.routeType === 'headless') {
              vm.$cookies.set('nac_headless', '1', {
                maxAge: 60 * 60 * 24 * 365,
                path: '/'
              })
            } else {
              vm.$cookies.set('nac_mkt_' + vm.formId, '1', {
                maxAge: 60 * 60 * 24 * 365,
                path: '/'
              })
            }

            if (vm.formId === '1206') {
              vm.$track({
                name: 'Lead-Demo',
                category: 'Form Submission',
                label: 'Demo'
              })

              const emailValue = document.getElementById('Email').value
              const firstNameValue = document.getElementById('FirstName').value
              const lastNameValue = document.getElementById('LastName').value
              const companyValue = document.getElementById('Company').value
              const websiteValue = document.getElementById('Website').value
              const ecommercePlatformValue = document.getElementById(
                'Ecommerce_Platform__c'
              ).value
              const annualRevenueInitValue = document.getElementById(
                'AnnualRevenue'
              ).value

              let annualRevenueValue

              switch (annualRevenueInitValue) {
                case '$0-$5M':
                  annualRevenueValue = 5000000
                  break
                case '$5M-$10M':
                  annualRevenueValue = 10000000
                  break
                case '$10M-$50M':
                  annualRevenueValue = 50000000
                  break
                case '$50M-$100M':
                  annualRevenueValue = 100000000
                  break
                case '$100M+':
                  annualRevenueValue = 100000001
              }

              window.ChiliPiper.submit('nacelle', 'nacelle_router', {
                lead: {
                  Email: emailValue,
                  Company: companyValue,
                  FirstName: firstNameValue,
                  LastName: lastNameValue,
                  Website: websiteValue,
                  Ecommerce_Platform__c: ecommercePlatformValue,
                  AnnualRevenue: annualRevenueValue
                }
              })
            }
            vm.setEmail(null)

            // If there is a successRedirect then do it now.
            if (vm.successRedirect.length > 0) {
              vm.$router.push(vm.successRedirect)
            }
            return false
          })
          form.onValidate(function (res) {
            form.submittable(true)
          })
          destyleMktoForm(form)
          injectLabelsInPlaceholders()
          styleRequestDemoCheckbox()
          if (document.querySelector('.mktoButton')) {
            document.querySelector('.mktoButton').innerText = buttonText
          }
          if (
            values.length === 1 &&
            values.filter((item) => item.id === 'Email')
          ) {
            const simpleForm = document.querySelector('.simple-form')
            simpleForm && simpleForm.classList.add('email-only')
          }
        }
      )

      function removeElement(elementId) {
        const element = document.getElementById(elementId)
        element.parentNode.removeChild(element)
      }

      function destyleMktoForm(mktoForm, moreStyles) {
        const formEl = mktoForm.getFormElem()[0]
        const arrayify = getSelection.call.bind([].slice)

        // remove element styles & classes from <form> and children
        const styledEls = arrayify(formEl.querySelectorAll('[style]')).concat(
          formEl
        )
        styledEls.forEach(function (el) {
          el.removeAttribute('style')
        })
        removeElement('mktoForms2BaseStyle')
        removeElement('mktoForms2ThemeStyle')
      }

      function styleRequestDemoCheckbox() {
        const elementLabel = document.getElementById(
          'InstructInterested_In_Demo__c'
        )

        if (elementLabel) {
          const elementWrapper = document
            .querySelector('#InstructInterested_In_Demo__c')
            .closest('.mktoFieldWrap')

          const inputWrapper = document
            .querySelector('#Interested_In_Demo__c')
            .closest('.mktoCheckboxList')
          // set label text
          document.getElementById('LblInterested_In_Demo__c').innerHTML =
            "Yes! I'd also like to request a live 30-minute demo of Nacelle."
          elementLabel.remove()

          // add  mktoCheckboxWrapper for css targeting
          elementWrapper.setAttribute(
            'class',
            'mktoFieldWrap mktoCheckboxWrapper'
          )

          // add span for custom checkbox
          const checkbox = inputWrapper.appendChild(
            document.createElement('span')
          )
          checkbox.classList.add('checkbox')
        }
      }

      function injectLabelsInPlaceholders() {
        const form = document.getElementById(`mktoForm_${formId}`)
        if (!form) {
          return
        }
        const inputs = form.querySelectorAll(
          `input[type=text],
        input[type=email],
        input[type=url],
        textarea`
        )
        inputs.forEach((input, index) => {
          if (
            buttonText !== 'Download Now' &&
            vm.type.toLowerCase() !== 'webinar'
          ) {
            if (!vm.email && index === 0) {
              input.focus()
            } else if (vm.email && index === 1) {
              input.focus()
            }
          }
          if (input.name) {
            input.placeholder = input.name.replace(/([A-Z])/g, ' $1').trim()
            if (input.name === 'Website') {
              input.addEventListener('blur', () => {
                if (input.value !== '' && !input.value.includes('http')) {
                  input.value = 'http://' + input.value
                }
              })
            }
            if (input.name === 'Email' && vm.email) {
              input.value = vm.email
            }
            if (input.name === 'Email') {
              input.placeholder = 'Work Email'
            }
            if (input.name === 'mktoCommentCapture') {
              input.placeholder = 'Your Message'
            }
          }
        })
        const relativeURL = document.querySelector('input[name=relativeURL]')
        relativeURL.value = vm.$route.path
      }

      function createUtmFields() {
        vm.$nextTick(() => {
          const form = document.getElementById(`mktoForm_${vm.formId}`)
          for(const query in vm.queryParams) {
            const input = document.createElement("input");

            input.setAttribute("type", "hidden");

            input.setAttribute("name", query);

            input.setAttribute("value", vm.queryParams[query]);

            //append to form element that you want .
            form.appendChild(input);
          }
        })
      }
    },
    checkProgression() {
      // const submittedCookie = this.$cookies.get('nac_mkt_' + this.formId)
      // if (submittedCookie && !this.disableCookie) {
      //   this.state = 'success'
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.marketo-form {
  max-width: 550px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 60px;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 10rem;
  background: linear-gradient(233.09deg, #00baff 0%, #125b76 100%);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
}
.spacer-block {
  height: 10rem;
}
p {
  margin-bottom: 0;
}
.message {
  // padding-top: 1rem;
  margin: 0;
  color: #ffffff;
  text-align: center;

  &.is-error {
    background: linear-gradient(233.09deg, #f56789 9.9%, #c4103b 100%);
    background-clip: text;
    color: transparent;
  }
}

label {
  display: none;
}

.marketo-form /deep/ {
  color: #ffffff;
  form {
    width: 100% !important;
  }
  em {
    font-style: normal;
    font-size: 14px;
  }
  label {
    display: none;
    &:last-child {
      display: none;
    }
  }
  .mktoFieldWrap {
    margin-top: 25px;
    // height: 50px;
  }

  .mktoCheckboxWrapper {
    display: flex;
    position: relative;
    height: 1.5rem;

    &:hover input ~ .checkbox {
      background-color: rgba(255, 255, 255, 0.25);
    }

    label {
      display: block;
      order: 1;
      cursor: pointer;
      padding-left: 1rem;
      margin-top: 2px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }

    .mktoCheckboxList {
      display: block;
      position: relative;
      width: 1.5rem;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkbox {
          background-color: #fff;

          &:after {
            display: block;
          }
        }
      }

      .checkbox {
        position: absolute;
        left: 0;
        top: 0;
        height: 1.5rem;
        width: 1.5rem;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;

        &:after {
          content: '';
          position: absolute;
          display: none;

          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid #00baff;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    .mktoInstruction {
      visibility: hidden;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='url'],
  select,
  textarea {
    padding: 8px 24px;
    width: 100%;
    max-width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    background: transparent;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 23px;
    color: #ffffff;
    box-sizing: border-box;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgb(0, 28, 39);
    }
  }

  select:not(select[multiple='multiple']) {
    position: relative;
    appearance: none;
    background: transparent
      url('data:image/svg+xml;utf8,<svg height="24" width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.41 8.295L12 12.875L16.59 8.295L18 9.705L12 15.705L6 9.705L7.41 8.295Z" fill="white" />
</svg>')
      no-repeat right 5px top 50%;
  }

  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  ::-ms-placeholder,
  ::placeholder {
    opacity: 0.6;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 23px;
  }
  input::placeholder {
    color: white;
  }
  .mktoErrorMsg {
    margin: 5px 5px 0;
  }
  a {
    color: #fff;
  }
  em {
    font-size: 14px;
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 14px;
    height: 45px;
    width: 221px;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    margin-top: 30px;
    background-image: url('/nacelle-arrow-right.svg') !important;
    background-repeat: no-repeat;
    background-position: 95%;
    padding-right: 20px;
  }
}

.marketo-form /deep/ {
  .mktoForm .mktoButtonWrap.mktoDownloadButton button.mktoButton {
    background: transparent;
  }
  .mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton::before {
    all: unset !important;
  }
  .mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton {
    position: relative !important;
    left: auto !important;
    line-height: 24px !important;
    text-shadow: none !important;
    white-space: nowrap !important;
    border-radius: 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 16px !important;
    padding-right: 60px !important;
    height: 45px;
    min-width: 221px;
    width: auto;
    background: transparent;
    border: 1px solid #ffffff !important;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 400;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: left;
    cursor: pointer;
    margin-top: 30px !important;
    background-image: url('/nacelle-arrow-right.svg') !important;
    filter: brightness(0) invert(1);
    background-repeat: no-repeat !important;
    background-position: 95% !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@include touch {
  form {
    width: auto !important;
  }

  .marketo-form {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 80vw;
    margin: 0 auto;
    margin-bottom: 8rem;
    h3,
    p {
      margin: 0 28px;
    }
  }
  .marketo-form /deep/ {
    input[type='text'],
    input[type='email'],
    input[type='url'],
    textarea {
      border-radius: 5px;
      padding-left: 17px;
      padding-right: 17px;
      width: 100%;
      height: 38px;
      font-size: 13px;
      letter-spacing: 0.36px;
      line-height: 16px;
    }
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    ::-ms-placeholder,
    ::placeholder {
      font-size: 13px;
      letter-spacing: 0.36px;
      line-height: 16px;
    }
    label {
      font-size: 13px;
      letter-spacing: 0.36px;
      line-height: 16px;
    }
    .mktoErrorMsg {
      font-size: 13px;
      letter-spacing: 0.36px;
      line-height: 16px;
    }
    button {
      font-size: 13px;
      letter-spacing: 0.36px;
      line-height: 16px;
      height: 35px;
    }
  }
}

.simple-form {
  display: flex;
  justify-content: center;
  &.email-only {
    ::v-deep {
      .mktoHtmlText.mktoHasWidth {
        display: none;
      }
      form {
        input {
          @media screen and (min-width: 1024px) {
            width: 300px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        .mktoButtonWrap.mktoDownloadButton button {
          font-size: 16px !important;
        }
      }
    }
  }
  /deep/ {
    form {
      display: flex;
      align-items: center;
    }

    label {
      display: none;
    }

    .mktoFieldWrap {
      position: relative;
    }

    .mktoError {
      position: absolute;
      color: #ffffff;
      width: 100%;
      top: 50px;
      left: 0;
      @media (max-width: 768px) {
        top: 110px;
      }
    }

    input {
      border-radius: 5px 0 0 5px;
      padding-left: 24px;
      padding-right: 24px;
      height: 41px !important;
      border: 1px solid #00baff !important;
      border-right: 0;
      background: transparent !important;
      font-size: 16px !important;
      letter-spacing: 0.5px;
      line-height: 23px;
      color: #ffffff !important;
      box-sizing: border-box;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        opacity: 0.6;
        color: #ffffff;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 23px;
      }
    }

    .mktoForm .mktoButtonWrap.mktoDownloadButton button.mktoButton {
      background: #00baff !important;
    }
    .mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton::before {
      all: unset !important;
    }
    .mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton {
      position: relative !important;
      left: auto !important;
      top: 0;
      line-height: 24px !important;
      text-shadow: none !important;
      white-space: nowrap !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding-left: 16px !important;
      padding-right: 14px !important;
      height: 41px;
      width: 221px;
      background: transparent;
      border-left: 0;
      border-radius: 0 5px 5px 0 !important;
      margin-top: 0 !important;
      background-color: #00baff !important;
      border: 1px solid #00baff;
      border-left: 0;
      color: #ffffff;
      flex-basis: 154px;
      width: 154px;
      box-sizing: border-box;
      height: 41px;
      border-left: 0;
      color: #ffffff;
      font-size: 13px;
      justify-content: center;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    @include touch {
      form {
        width: 100% !important;
        flex-direction: column;
        align-items: inherit;
        margin: 28px;
      }
      input {
        border-radius: 5px;
        padding-left: 17px;
        padding-right: 17px;
        padding: 20px 17px;
        width: 100%;
        height: 33px !important;
        border: 1px solid #00baff;
        border-right: 1px solid #00baff !important;
        background-color: transparent;
        font-size: 13px !important;
        letter-spacing: 0.36px;
        line-height: 16px !important;
        color: #ffffff;
        box-sizing: border-box;
        margin-bottom: 10px;
        &:placeholder {
          opacity: 0.6;
          color: #ffffff;
          font-size: 13px;
          letter-spacing: 0.36px;
          line-height: 16px;
        }
      }

      .mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton {
        flex-basis: auto;
        border-left: 1px !important;
        height: 42px;
        width: 100%;
        border-radius: 5px !important;
        color: #ffffff;
        font-weight: bold !important;
        letter-spacing: 0.33px !important;
        line-height: 15px !important;
        text-align: center !important;
        cursor: pointer;
        justify-content: center;
      }
    }
  }
}
</style>
