<template>
  <div class="flex justify-center" :id="`siteform-${id}`">
    <ClientOnly>
      <BaseForm
        :title="title"
        :description="description"
        :fields="fields"
        :submitText="submitText"
        :submitEndpoint="submitEndpoint"
        :id="id"
        :theme="theme"
        :isMarketo="isMarketo"
        :disableSimpleForm="disableSimpleForm"
        @form-success="handleSuccess"
        @form-failure="handleFailure"
        v-if="!alreadyFilled && !status.hasSubmitted"
      />
      <template v-if="alreadyFilled">
        <div :class="['rich-text', theme.form]">
          <RichTextRenderer
            :class="theme.successMessage"
            :document="
              hasContent(alreadyFilledMessage)
                ? alreadyFilledMessage
                : successMessage
            "
          />
        </div>
      </template>
      <template v-else-if="status.hasSubmitted">
        <div v-if="status.isSuccess" :class="['rich-text', theme.form]">
          <RichTextRenderer
            :class="theme.successMessage"
            :document="successMessage"
          />
          <img
            v-if="linkedInConversionId"
            class="hidden"
            alt=""
            :src="`https://px.ads.linkedin.com/collect/?pid=3181713&conversionId=${linkedInConversionId}&fmt=gif`"
          />
        </div>
        <div v-else :class="theme.form">
          <RichTextRenderer
            :class="theme.failureMessage"
            :document="failureMessage"
          />
        </div>
      </template>
    </ClientOnly>
  </div>
</template>

<script>
import BaseForm from './comps/BaseForm'
import successCbs from './js/SuccessCallbacks'
import siteFormThemes from './themes/SiteForm.themes'

export default {
  components: {
    BaseForm
  },
  props: {
    name: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    submitEndpoint: {
      type: String,
      default: ''
    },
    alreadyFilledMessage: {
      type: [Object, String],
      default: ''
    },
    successMessage: {
      type: [Object, String],
      default: () => ({
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Thank you!'
              }
            ]
          }
        ]
      })
    },
    failureMessage: {
      type: String,
      default: () => ({
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Sorry, there was an error. Your form has not been submitted.'
              }
            ]
          }
        ]
      })
    },
    formStyle: {
      type: String,
      default: 'dark'
    },
    id: {
      type: [String, Number]
    },
    isMarketo: {
      type: Boolean,
      default: false
    },
    linkedInConversionId: {
      type: String
    },
    disableSimpleForm: {
      type: Boolean
    },
    formSubmitFlow: {
      type: Array
    },
    formSubmitResources: {
      type: Object
    },
    alwaysShowForm: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    status: {
      hasSubmitted: false,
      isSuccess: false
    }
  }),
  computed: {
    theme() {
      return siteFormThemes[this.formStyle]
    },
    lsKey() {
      if (!('$route' in this)) return ''
      return `${this.$route.path}\#${this.id}`
    },
    alreadyFilled() {
      if (this.alwaysShowForm) return false
      return process.client ? window.localStorage.getItem(this.lsKey) : null
    }
  },
  methods: {
    setLocalStorage(val) {
      process.client && window.localStorage.setItem(this.lsKey, val)
      window.dispatchEvent(
        new CustomEvent('form-localstorage-changed', {
          detail: {
            storage: localStorage.getItem(this.lsKey)
          }
        })
      )
    },
    setSubmitStatus(val) {
      this.status.hasSubmitted = true
      this.status.isSuccess = val
      this.setLocalStorage(val)
    },
    handleSuccess({ submittedForm, redirect }) {
      this.setSubmitStatus(true)
      const submissionFlow = {
        resources: this.formSubmitResources,
        entries: this.formSubmitFlow,
        formId: this.id
      }
      if (this.name in successCbs) {
        successCbs[this.name](submittedForm, this, submissionFlow)
      } else {
        successCbs.default(submittedForm, this, submissionFlow)
      }
    },
    handleFailure() {
      this.setSubmitStatus(false)
    },
    hasContent(doc) {
      if (!doc) return false
      return doc?.content.filter((node) => 'content' in node).length
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-image-wrapper img {
  width: 100%;
}
.rich-text > * {
  color: inherit;
}
</style>
