<template>
  <div>
    <h2
      class="text-lg font-bold tracking-[.5px] leading-2 mt-6 mb-2 text-white"
    >
      {{ title }}
    </h2>
    <ul class="text-base list-none m-0 p-0">
      <li v-for="(link, j) in sections" :key="j" class="py-2 px-0">
        <nuxt-link
          :to="'/' + link.link.cached_url"
          class="text-[#B4B6C4] no-underline hover:underline leading-5"
        >
          {{ link.title }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['title', 'sections']
}
</script>
