<template>
  <div class="relative">
    <div
      class="py-5 mt-4 mx-8 sm:m-0 sm:p-12 flex items-center justify-center"
      :class="{
        'bg-transparent text-center': type === 'Centered',
        'mb-2 sm:pb-2 sm:px-12 sm:pt-12': hasForm,
        'mb-10 sm:p-12': !hasForm
      }"
    >
      <div class="text-hero-container max-w-[770px]">
        <div>
          <p v-if="eyebrow" class="eyebrow mb-5">{{ eyebrow }}</p>
          <h1
            class="page-title"
            :class="textColor"
            v-html="headingTitleFormatter"
            v-if="title && !hasRichTextTitle"
          >
            {{ headingTitleFormatter }}
          </h1>
          <div class="rich-text" v-else>
            <RichTextRenderer :document="rtTitle" />
          </div>

          <p class="sub-heading" :class="textColor" v-if="subheading">
            {{ subheading }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center mb-10" v-if="hasForm">
      <StoryblokResolver :block="siteForm" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextHero',
  props: {
    eyebrow: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    rtTitle: {
      type: Object,
      default: () => ({})
    },
    subheading: {
      type: String,
      required: false
    },
    useOutlineStyle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'Centered'
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => {
        return ['light', 'dark'].indexOf(value) !== -1
      }
    },
    form: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    headingTitleFormatter() {
      const split = this.title?.split('.')
      if (split?.length > 1 && this.useOutlineStyle) {
        return `<span class="outline">${split[0]}</span> ${split[1]}`
      }
      return this.title
    },
    textColor() {
      return this.theme === 'dark' ? 'text-white' : 'text-black'
    },
    hasRichTextTitle() {
      return Object.entries(this.rtTitle).length
    },
    hasForm() {
      return this.form?.length
    },
    siteForm() {
      return this.form?.length && this.form[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.text-hero-container {
  ::v-deep h1 {
    .outline {
      color: white;
      -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #00baff;
      display: block;
    }
  }
}

.rich-text h1 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
  line-height: 34px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 60px;
  }
}
</style>
