<template>
  <button
    :id="id"
    :name="id"
    ref="select"
    v-bind="$attrs"
    :class="theme.select.el"
    type="button"
    aria-haspopup="listbox"
    :aria-expanded="!!showMenu"
    @click.capture="showMenu = !showMenu"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <SelectLabel
      ref="selectLabel"
      :theme="theme"
      :hasSelection="hasSelection"
      :selected="_selected"
      :hasError="hasError"
      :menuShown="showMenu"
      :isHovering="isHovering"
    />
    <SelectDropdown
      ref="selectDropdown"
      v-bind="$props"
      :selected="_selected"
      :showMenu="showMenu"
      @input="handleInput"
    />

    <Icon
      alt="Chevron for Dropdown Menu"
      ref="selectDropdownIcon"
      :class="[
        theme.select.selectDropdownIcon.el,
        {
          [theme.select.selectDropdownIcon.placeholder]: !hasSelection,
          [theme.select.selectDropdownIcon.active]: showMenu || isHovering,
          [theme.select.selectDropdownIcon.selection]: hasSelection,
          [theme.select.selectDropdownIcon.error]: hasError
        }
      ]"
      file="NacelleChevronDown"
    />
  </button>
</template>

<script>
import { createPopper } from '@popperjs/core'
import Icon from '~/components/BaseIcon/BaseIcon.vue'
import SelectDropdown from './comps/Select/SelectDropdown.vue'
import SelectLabel from './comps/Select/SelectLabel'
export default {
  inheritAttrs: false,
  components: {
    SelectDropdown,
    SelectLabel,
    Icon
  },
  props: {
    id: {
      type: String
    },
    optionValues: {
      type: Array,
      default: () => [{}]
    },
    fieldMetaData: {
      type: Object
    },
    theme: {
      type: Object
    },
    hasError: {
      type: Boolean
    }
  },
  data: () => ({
    selected: '',
    showMenu: false,
    popper: null,
    popperOptions: {
      strategy: 'absolute',
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]
    },
    isHovering: false
  }),
  computed: {
    hasSelection() {
      return !!this.selected
    },
    _selected() {
      return this.selected ? this.selected : this.defaultSelection
    },
    defaultSelection() {
      const [placeholder] = this.optionValues.filter((option) => !option.value)
      return placeholder.label
    }
  },
  watch: {
    showMenu() {
      this.popper.update()
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.body.addEventListener('click', this.handleBodyClick, false)
      if (this.$refs.select && this.$refs.selectDropdown) {
        this.popper = createPopper(
          this.$refs.select,
          this.$refs.selectDropdown.$el,
          this.popperOptions
        )
      }
    })
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleBodyClick, false)
  },
  methods: {
    handleBodyClick({ target }) {
      if (
        target === this.$refs.select ||
        target === this.$refs.selectLabel.$el ||
        target === this.$refs.selectDropdownIcon.$el
      )
        return
      this.showMenu = false
    },
    handleInput(e) {
      this.$emit('input', e)
      this.selected = e.target.value
    },
    handleMouseOver() {
      this.isHovering = true
    },
    handleMouseOut() {
      this.isHovering = false
    }
  }
}
</script>

<style scoped>
button {
  padding: 0;
  margin: 0;
  text-align: left;
  width: 0;
}
</style>
