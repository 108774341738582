import submitFlowFns from "../SubmitFlowFns"
// Custom Callbacks
import Webinar1248 from "./Webinar1248"


// Form Names will have spaces removed
export default {
  default: (form, vm, submitFlow) => {
    defaultHandler(form, vm, submitFlow)
  },
  Webinar1248
}

export function defaultHandler(form, vm, { resources, entries, formId }) {
  setCookies(vm, formId)
  const [redirect] = entries.filter(entry => entry.component === 'SFRedirect')
  const filteredEntries = entries.filter(entry => entry.component !== 'SFRedirect')
  
  filteredEntries.forEach(entry => submitFlowFns[entry.component](entry, form, vm))
  if (redirect) submitFlowFns.SFRedirect(redirect)
}

export function setCookies(vm, formId) {
  const cookieName = vm.routeType === 'headless' ? 'nac_headless' : 'nac_mkt_' + formId
  vm.$cookies?.set(cookieName, '1', {
    maxAge: 60 * 60 * 24 * 365,
    path: '/'     
  })
}

