<template>
  <div class="text-link">
    <a
      v-if="external || linkPath.includes('http')"
      :href="linkPath"
      :class="color"
      target="_blank"
    >
      <span>
        <slot />
      </span>
      <img src="/nacelle-arrow-right.svg" alt="arrow-right" />
    </a>
    <nuxt-link v-else :to="linkPath" :class="color" @click.native="handleClick">
      <span>
        <slot />
      </span>
      <img src="/nacelle-arrow-right.svg" alt="arrow-right" />
    </nuxt-link>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    linkPath: {
      type: String,
      default: '/'
    },
    external: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    ...mapMutations('form', ['toggleHeadlessPopup']),
    handleClick() {
      if (this.$nuxt.$route.name === 'head-to-head-on-headless') {
        const gated = this.$cookies.get('nac_headless')
        if (gated) {
          this.$router.push('/head-to-head-on-headless-library')
          return
        }
        this.toggleHeadlessPopup()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-link {
  display: inline-block;
  margin: 5px;
}
a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-decoration: none;
  transition: all 0.3s;
  span {
    color: #00baff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.44px;
    line-height: 20px;
    padding-bottom: 2px;
    border-bottom: 1px #ffffff00 solid;
  }
  img {
    margin-left: 24px;
    filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
      brightness(102%) contrast(104%); //Makes color #00BAFF (Blue)
  }
  &:hover {
    span {
      border-bottom: 1px #00baff solid;
    }
  }
}
.secondary {
  span {
    color: #fff;
  }
  img {
    filter: brightness(0) invert(1);
  }
  &:hover {
    span {
      border-bottom: 1px #fff solid;
    }
  }
}

@include touch {
  img {
    width: 15px;
  }
}
</style>
