<template>
  <header
    id="docs-header"
    data-cy="docs-header"
    class="fixed left-0 top-0 z-20 w-full bg-gray-200 h-16 border-solid border-0 border-b border-opacity-50 border-gray-400 flex flex-wrap items-center lg:h-20"
  >
    <!-- left -->
    <div
      class="flex w-full py-5 lg:w-72 lg:py-0 mx-auto transition duration-300 ease-in-out lg:mx-0 lg:flex lg:opacity-100"
      :class="{
        'hidden': searchExpanded,
        'lg:flex': !searchExpanded
      }"
      :aria-hidden="searchExpanded ? 'true' : 'false'"
    >
      <div class="flex items-center w-10/12 mx-auto">
        <docs-header-button class="lg:hidden" location="header" />
        <div class="flex-1 text-center">
          <nuxt-link to="/docs/" aria-label="Nacelle Docs home">
            <nacelle-docs-logo />
          </nuxt-link>
        </div>

        <div class="lg:hidden">
          <button
            aria-label="expand search"
            @click="expandSearch(true)"
            ref="searchExpand"
            class="cursor-pointer focus:ring-2 ring-white bg-white bg-opacity-0 border-0 p-0"
          >
            <img
              alt=""
              src="/svg/search_darker.svg"
            />
          </button>
        </div>
      </div>
    </div>

    <!-- right -->
    <SearchBar
      class="transition duration-300 ease-in-out absolute inset-0 my-4 lg:flex lg:relative lg:opacity-100 lg:translate-y-0 lg:ml-20 lg:pointer-events-auto"
      :class="{
          'opacity-100': searchExpanded,
          'opacity-0 pointer-events-none translate-y-2': !searchExpanded
        }"
      @close-search="expandSearch(false)"
      :aria-hidden="searchExpanded ? 'false' : 'true'"
      :inert="!searchExpanded && !overLg"
    />
  </header>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useDocsStore } from '~/store/docs'
import DocsHeaderButton from './DocsHeaderButton'
import NacelleDocsLogo from './NacelleDocsLogo'
import SearchBar from '../../SearchBar/SearchBar.vue'

export default {
  name: 'DocsHeader',
  components: {
    DocsHeaderButton,
    NacelleDocsLogo,
    SearchBar
  },
  data() {
    return {
      searchExpanded: false,
      overLg: false
    }
  },
  mounted() {
    if (window.innerWidth >= 1024) {
      this.overLg = true
    }
    // update overLg on resize
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        this.overLg = true
      } else {
        this.overLg = false
      }
    })
  },
  computed: {
    ...mapState(useDocsStore, ['sidebarOpen'])
  },
  methods: {
    ...mapActions(useDocsStore, ['setSidebarOpen']),
    expandSearch(expanded) {
      this.searchExpanded = expanded
      this.$nextTick(() => {
        if (expanded) {
          this.$nextTick(() => {
            // not sure why, but without this nextTick it couldn't focus!
            setTimeout(() => { document.getElementById('searchInput').focus() }, 0)
          })
          this.closeSidebar()
        } else {
          this.$refs.searchExpand.focus()
        }
      })
    },
    closeSidebar() {
      if (!this.overLg) {
        this.setSidebarOpen(false)
      }
    }
  },
  watch: {
    $route() {
      this.expandSearch(false)
    }
  }
}
</script>
