<template>
  <span
    class="block truncate relative"
    :class="{
      [theme.select.selectOptionsText.placeholder]: !hasSelection && !hasError && !menuShown && !isHovering,
      [theme.select.selectOptionsText.textColor]: (hasSelection && !hasError) || isHovering,
      [theme.select.selectOptionsText.error]: hasError
    }"
    ref="selectLabel"
  >
    {{ selected }}
  </span>
</template>

<script>

export default {
  props: {
    selected: {
      type: String
    },
    theme: {
      type: Object
    },
    hasSelection: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean
    },
    menuShown: {
      type: Boolean
    },
    isHovering: {
      type: Boolean
    }
  }
}
</script>
