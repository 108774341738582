<template>
  <div class="select">
    <select @change="$emit('inputchange', $event.target.value)">
      <option v-for="option in optionValues" :key="option" :value="option">{{ option }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    optionValues: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8.7px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
  }
}
</style>
