import Vue from 'vue'
import { Block } from '@marvr/storyblok-rich-text-types'
import ApolloExplorer from '~/components/Docs/DocumentationContent/ApolloExplorer'
import BlogVideo from '~/components/BlogPost/BlogVideo'
import DocTable from '~/components/Docs/DocumentationContent/DocTable'
import DocVideo from '~/components/Docs/DocumentationContent/DocVideo'
import DocCallout from '~/components/Docs/DocumentationContent/DocCallout'
import RichTextCode from '~/components/RichText/RichTextCode'
import RichTextHeading from '~/components/RichText/RichTextHeading'
import RichTextParagraph from '~/components/RichText/RichTextParagraph'
import TableOfContentsItem from '~/components/BlogPost/TableOfContentsItem'
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import WebinarRegistrationConfirmation from '~/components/SiteForm/comps/WebinarRegistrationConfirmation.vue'

Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      ApolloExplorer,
      BlogVideo,
      DocTable,
      DocVideo,
      DocCallout,
      TableOfContentsItem,
      WebinarRegistrationConfirmation
    },
    blocks: {
      [Block.PARAGRAPH]: RichTextParagraph,
      [Block.HEADING]: RichTextHeading,
      [Block.CODE]: RichTextCode
    }
  }
})
