<template>
  <BaseModal
    modalType="left"
    class="blog__popup--trigger hidden md:block fixed left-0 top-1/2 z-[1] -rotate-90 origin-top-left py-2 px-4 rounded-t-none"
  >
    <template v-slot:button>
      <div class="nacelle-button flex items-center w-full">
        <span class="text-base">Subscribe</span>
        <div class="nacelle-button-icon ml-3">
          <img src="/airplane-icon.svg" alt="" class="w-6 h-6" />
        </div>
      </div>
    </template>

    <template v-slot:content>
      <div class="newsletterPopout w-full">
        <div class="newsletterPopout__content flex w-full">
          <div
            class="newsletterPopout__content__title flex-1 relative z-[1] px-5 py-6"
          >
            <div
              v-if="icon"
              class="newsletterPopout__content__title__icon w-12 md:w-16"
            >
              <img :src="icon" alt="" class="invert" />
            </div>
            <h2 class="mt-5 mb-1 text-2xl md:text-3xl font-bold">
              {{ title }}
            </h2>
            <p class="m-0 text-l md:text-2xl">{{ description }}</p>
            <div class="newsletterPopout__skew"></div>
          </div>
          <div class="newsletterPopout__content__form flex-[1.5] bg-white">
            <div class="newsletterPopout__form__content">
              <MarketoForm
                :form-id="marketoId"
                simple-form
                type="email"
                :success-message="successMessageHTML"
                :success-redirect="successRedirect"
              >
              </MarketoForm>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/components/BaseModal/BaseModal'
import MarketoForm from '~/components/MarketoForm'
export default {
  name: 'NewsletterPopout',
  components: { BaseModal, MarketoForm },
  props: {
    icon: {
      type: String,
      default: ''
    },
    marketoId: {
      type: String,
      default: '1014'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default:
        "You're on the list! Keep an eye on your inbox for new content and updates from Nacelle."
    },
    image: {
      type: String,
      default: ''
    },
    successRedirect: {
      type: String,
      default: ''
    }
  },
  computed: {
    successMessageHTML() {
      return `<div><img alt="Email signed up successfully" src="${this.image}" /></div>
      <p>${this.message}</p>
      `
    }
  }
}
</script>

<style lang="scss" scoped>
.blog__popup--trigger {
  background: #00baff;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #00baff;

    .nacelle-button-icon {
      filter: invert(55%) sepia(34%) saturate(5836%) hue-rotate(168deg)
        brightness(109%) contrast(103%);
    }
  }
}

.newsletterPopout {
  width: 60vw;

  @media (max-width: 1200px) {
    width: 75vw;
  }

  &__content {
    max-height: 550px;
    min-height: 375px;

    @media (max-width: 768px) {
      flex-direction: column;
      max-height: 90vh;
      overflow-y: scroll;
    }

    &__title {
      background-color: #3955b4;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 120px;
        left: 0;
        bottom: -49px;
        -webkit-clip-path: polygon(100% 60%, 0% 100%, 0 0%);
        clip-path: polygon(100% 60%, 0% 100%, 0 0%);
        background: linear-gradient(277deg, #16224a 0%, #3955b4 70%);
        z-index: -1;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &__form {
      ::v-deep .simple-form {
        form {
          flex-direction: column;
          margin: 25px 30px;
          @media (max-width: 768px) {
            margin: 15px 20px;
          }
          input {
            width: 100%;
            color: #000000 !important;
            border-radius: 5px !important;
            -webkit-text-fill-color: #000000;
            -webkit-box-shadow: none;

            @media (max-width: 768px) {
              margin-bottom: 0;
            }

            &-webkit-autofill:focus,
            &:focus {
              border: 2px solid #00baff !important;
            }
          }
          .mktoFormRow {
            width: 100%;
            margin: 5px 0;
          }
          .mktoHtmlText {
            height: 36px;
            color: #080d1e;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.25px;
            line-height: 18px;
          }
          .mktoButtonRow {
            align-self: baseline;
            .mktoButtonWrap {
              .mktoButton {
                border-radius: 5px !important;
              }
            }
          }
        }
        .message {
          p {
            color: black;
            font-size: 22px;
            line-height: 28px;
            font-weight: bold;
            margin: -75px 20px;
            @media (max-width: 768px) {
              margin: -50px 20px;
            }
          }
          > div {
            padding-left: 1rem;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ::v-deep .spacer-block {
      height: 8rem;
      @media (max-width: 768px) {
        height: 5rem;
      }
    }
  }

  &__skew {
    background-image: linear-gradient(45deg, #654ea3, #eaafc8);
    transform: skewY(-11deg);
  }
}
</style>
