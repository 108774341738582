<template>
  <BaseSection
    padding="px-0 pt-24"
    bg-color="bg-cyan-900"
    class="max-w-full mx-auto"
  >
    <div class="flex flex-col">
      <div class="max-w-4xl w-full text-center mx-auto">
        <HeroText class="mx-auto mb-5" color="text-white">
          <SplitHeading v-if="isMulticolorHeading" :heading="heading" />
          <span v-else>{{ heading }}</span>
        </HeroText>
        <div class="hero-copy">
          <RichTextRenderer :document="subHeading" />
        </div>
        <Cta :cta="cta" size="large" class="mx-auto" />
      </div>
      <img :src="image.filename" :alt="image.alt" class="max-w-full mx-auto" />
    </div>
  </BaseSection>
</template>

<script>
import Cta from '~/components/V2/Cta/Cta.vue'
import BaseSection from '~/components/V2/Base/BaseSection/BaseSection'
import SplitHeading from '~/components/V2/Base/BaseText/SplitHeading'
export default {
  components: {
    Cta,
    BaseSection,
    SplitHeading
  },
  props: {
    heading: {
      type: String
    },
    subHeading: {
      type: [Object, String]
    },
    image: {
      type: Object
    },
    cta: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isMulticolorHeading() {
      return this.heading.includes('*')
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-copy * {
  color: white !important;
}
</style>
