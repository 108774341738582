<template>
  <div :class="[theme.form]" @keyup="handleKeyUp">
    <h6 v-if="title" :class="[theme.title]">{{ title }}</h6>
    <p v-if="description" :class="[theme.description]">{{ description }}</p>
    <ValidationObserver
      :id="`form-${id}`"
      ref="validationObserver"
      tag="form"
      @submit.prevent="handleSubmit"
      novalidate
      :class="{ 'flex flex-col sm:flex-row': isSimple }"
    >
      <template v-for="field in fields">
        <ValidationProvider
          :key="field.id"
          tag="div"
          v-slot="{ errors }"
          :rules="mapRules(field)"
          :name="field.id"
          :mode="field.dataType === 'select' ? 'aggressive' : 'eager'"
          v-if="field.dataType !== 'hidden'"
        >
          <FormField
            :key="field.id"
            :field="field"
            :errors="errors"
            :theme="theme.formField.fields"
            :isSimple="isSimple"
            v-model="customForm[field.id]"
            :class="[theme.formField.wrapper]"
            @input="handleInput"
          />
        </ValidationProvider>
      </template>
      <button
        :id="`form-${id}-submit`"
        type="submit"
        :class="{
          [theme.button.simple]: isSimple,
          [theme.button.normal]: !isSimple
        }"
      >
        {{ submitText }}
        <ButtonArrow :class="theme.button.icon" />
      </button>
    </ValidationObserver>
    <form
      v-if="isMarketo"
      :id="`mktoForm_${id}`"
      :style="{ visibility: 'hidden', position: 'absolute' }"
    ></form>
  </div>
</template>

<script>
import FormField from './fields/FormField'
import ButtonArrow from './ButtonArrowSvg'
import * as hiddenInputFns from '../js/SiteForm.hiddenInputFns'
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    ButtonArrow,
    FormField,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    id: { type: [String, Number], required: true },
    fields: { type: Array, required: true },
    submitText: { type: String },
    submitEndpoint: { type: String, default: '/' },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isMarketo: {
      type: Boolean,
      default: false
    },
    theme: {
      type: Object
    },
    disableSimpleForm: {
      type: Boolean
    }
  },
  data: () => ({
    marketoForm: null,
    customForm: {},
    hiddenFields: {},
    filteredQueryData: {},
    hiddenInputs: {},
    typeRules: ['email'],
    focusableFields: null
  }),
  computed: {
    isSimple() {
      return (
        this.fields.filter((f) => !['hidden', 'htmltext'].includes(f.dataType))
          .length === 1 && !this.disableSimpleForm
      )
    },
    prefills() {
      return Object.entries(this.fields).reduce((acc, [key, field]) => {
        if (
          field.dataType === 'hidden' &&
          'autoFill' in field &&
          field.autoFill.value
        )
          acc[field.id] = field.autoFill.value
        return acc
      }, {})
    },
    utms() {
      return this.fields.filter(
        (field) =>
          field.dataType === 'hidden' &&
          'autoFill' in field &&
          /^utm/i.test(field.autoFill.parameterName)
      )
    },
    utmList() {
      return this.utms.map((field) => field.autoFill.parameterName)
    },
    marketoUtmMap() {
      return Object.entries(this.utms).reduce((acc, [key, field]) => {
        acc[field.autoFill.parameterName] = field.id
        return acc
      }, {})
    }
  },
  watch: {
    customForm: {
      handler(form) {
        if (this.isMarketo) this.marketoForm.setValues(form)
      },
      deep: true
    }
  },
  mounted() {
    if (this.isMarketo) {
      // Fix for MktoForms2 is undefined error, but doesn't work with this Landing Page url (needs work)
      // MktoForms2.setOptions({
      //   formXDPath: '/rs/750-WWD-574/images/marketo-xdframe-relative.html'
      // })
      MktoForms2.loadForm(
        '//app-ab42.marketo.com',
        '750-WWD-574',
        this.id,
        (form) => {
          this.marketoForm = form
        }
      )
    }
    this.getUtmParams()
    this.getHiddenInputs()
    this.getFocusableFields()
  },
  methods: {
    mapRules(field) {
      if (field.dataType === 'hidden') return
      const rules = {}
      if (field.required) rules.required = true
      if (this.typeRules.includes(field.dataType)) rules[field.dataType] = true
      if (field.dataType === 'phone')
        rules.regex = /^[+|\d|\-| |(|)|\[|\]|\.]{1,30}/
      if (field.dataType === 'url')
        rules.regex =
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
      if (field.maxLength) rules.max = field.maxLength
      return rules
    },
    handleInput(e) {
      this.customForm = { ...this.customForm, [e.target.id]: e.target.value }
    },
    getFocusableFields() {
      const formId = `#form-${this.id}`
      const focusableFieldsList = ['input', 'select', 'textarea', 'button']
      const selectors = focusableFieldsList
        .map((f) => `${formId} ${f}`)
        .join(',')
      this.focusableFields = Array.from(document.querySelectorAll(selectors))
    },
    getUtmParams() {
      if (!('$route' in this)) return
      const queryData = Object.entries(this.$route.query)
      const filteredQueryData = queryData.reduce((acc, [key, value]) => {
        if (this.utmList.includes(key))
          acc[`${this.marketoUtmMap[key]}`] = value
        return acc
      }, {})
      this.filteredQueryData = filteredQueryData
    },
    getHiddenInputs() {
      const fields = this.fields.filter(
        (f) => f.dataType === 'hidden' && !('parameterName' in f.autoFill)
      )
      fields.forEach((f) => {
        if (f.id in hiddenInputFns) {
          this.$nextTick(() => {
            this.hiddenInputs[f.id] = hiddenInputFns[f.id](this)
          })
        }
      })
    },
    mergeHiddenFields() {
      this.hiddenFields = {
        ...this.prefills,
        ...this.filteredQueryData,
        ...this.hiddenInputs
      }
    },
    checkWebsiteUrl() {
      if (
        'Website' in this.customForm &&
        !/^https?\:\/\//.test(this.customForm.Website)
      ) {
        this.customForm.Website = `http://${this.customForm.Website}`
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      // add Hidden Fields / UTM stuff
      this.mergeHiddenFields()
      this.isMarketo && this.marketoForm.addHiddenFields(this.hiddenFields)

      this.checkWebsiteUrl() // add http if the URL doesn't have it (marketo required)
      this.validateForm()
    },
    validateForm() {
      const validationResult = this.$refs.validationObserver.validate()
      validationResult.then((isValid) => {
        if (isValid) {
          if (this.isMarketo) {
            this.handleMarketo()
          } else {
            this.handleCustom()
          }
        }
      })
    },
    handleMarketo() {
      this.marketoForm.onSuccess((submittedForm, redirect) => {
        this.$emit('form-success', { submittedForm, redirect })
        return false
      })
      this.marketoForm.submit()
    },
    handleCustom() {
      const submittedForm = { ...this.customForm, ...this.hiddenFields }
      this.$axios({
        method: 'post',
        url: this.submitEndpoint,
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 20000,
        data: JSON.stringify(submittedForm) //formType: 'lead'
      })
        .then((res) => {
          this.$emit('form-success', { submittedForm })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
          this.$emit('form-failure', { submittedForm })
        })
    },
    handleKeyUp(e) {
      const activeEl = document.activeElement
      const activeElIdx = this.focusableFields.findIndex(
        (f) => f.id === activeEl.id
      )
      const lastIdx = this.focusableFields.length - 1
      if (e.key === 'ArrowUp')
        activeElIdx <= 0
          ? this.focusableFields[0].focus()
          : this.focusableFields[activeElIdx - 1].focus()
      if (e.key === 'ArrowDown')
        activeElIdx >= lastIdx
          ? this.focusableFields[lastIdx].focus()
          : this.focusableFields[activeElIdx + 1].focus()
    }
  }
}
</script>
