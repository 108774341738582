<template>
  <div class="blog-grid-container">
    <div class="blog-grid-filter-container">
      <div class="blog-grid-filters">
        <span>Show me</span>
        <select name="filters" @change="selectFilter">
          <option value="All Things">All Things</option>
          <option v-for="filter in filters" :key="filter" :value="filter">
            {{ filter }}
          </option>
        </select>
      </div>
    </div>
    <div class="blog-grid">
      <blog-post-card
        v-for="post in filteredPosts"
        :key="post.handle"
        v-bind="post"
      />
    </div>
  </div>
</template>

<script>
import BlogPostCard from './BlogPostCard'
export default {
  components: {
    BlogPostCard
  },
  data() {
    return {
      currentFilter: 'All Things',
      posts: []
    }
  },
  methods: {
    selectFilter(event) {
      this.currentFilter = event.target.value
    }
  },
  computed: {
    filters() {
      return this.posts.reduce((categories, post) => {
        if (post.category && !categories.includes(post.category)) {
          categories.push(post.category)
        }
        return categories
      }, [])
    },
    filteredPosts() {
      if (this.currentFilter === 'All Things') {
        return this.posts
      } else {
        return this.posts.filter((post) => post.category === this.currentFilter)
      }
    }
  },
  async fetch() {
    const version = process.env.PREVIEW ? 'draft' : 'published'
    this.posts = await this.$storyapi
      .get('cdn/stories', {
        version,
        is_startpage: 0,
        starts_with: 'blog/',
        sort_by: 'first_published_at:desc',
        per_page: 100
      })
      .then(
        (res) => {
          // filter through posts for only the data needed
          const mappedPosts = []
          res.data.stories.forEach((post) => {
            mappedPosts.push({
              title: post.name,
              image: {
                filename: post.content.image ? post.content.image.filename : '',
                alt: post.content.image ? post.content.image.alt : ''
              },
              handle: '/' + post.full_slug,
              category: post.content.category
            })
          })
          return mappedPosts
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      )
  }
}
</script>

<style lang="scss" scoped>
.blog-grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .blog-grid-filter-container {
    width: 100%;
    max-width: 1170px;
    .blog-grid-filters {
      color: #ffffff;
      font-size: 22px;
      letter-spacing: 0.5px;
      line-height: 30px;
      align-self: flex-start;
      margin: 30px;
      select {
        width: auto;
        height: 100%;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.39px;
        line-height: 28px;
        cursor: pointer;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #00baff;
        color: #00baff;
        padding: 10px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 10px;
        background-image: url('/nacelle-chevron-down.svg');
        background-repeat: no-repeat;
        background-position: 95%;
        padding-right: 20px;
      }
    }
  }
  .blog-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1170px;
    @include touch {
      width: 312px;
    }
  }
}
/deep/ .blog-card:first-child {
  width: 740px;
  height: 650px;

  .blog-card-image-box,
  .blog-card-image-box img {
    height: auto;
    width: 740px;
  }
  @include touch {
    width: 312px;
    height: 480px;

    .blog-card-image-box,
    .blog-card-image-box img {
      height: 257px;
      width: 312px;
    }
  }
}
</style>
