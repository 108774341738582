<template>
  <div
    class="relative w-full max-w-[524px] max-h-[476px] mx-auto lg:mx-[30.5px] my-[50px] lg:my-0 stick"
  >
    <div
      class="icon-bar absolute top-[-11%] left-0 min-w-[70px] lg:min-w-[95px] w-[15%] h-[230px] lg:h-[272px] bg-cyan-900 z-[99]"
    >
      <div
        class="icon mt-[5%] mx-auto px-[10px] z-[999] object-contain flex justify-center"
      >
        <img class="max-w-full max-h-[60px]" :src="logo" />
      </div>
    </div>
    <div class="w-full h-full">
      <div class="grid w-full h-[89%] grid-cols-[11%,auto] row-start-2">
        <div
          class="info-box col-start-2 pl-[21%] pr-[7%] py-[9%] text-white text-[28px] tracking-[.88px] leading-10 rounded-[5px] max-w-[250px]"
        >
          <div
            class="w-full border border-solid border-l-0 border-r-0 border-t-0 border-cyan-500 pb-4 lg:pb-[27px]"
          >
            <div>
              <div class="bio-label">Location</div>
              <div class="bio-info text-base lg:text-[22px] lg:leading-[30px]">
                {{ location }}
              </div>
            </div>
            <div v-if="vertical" class="mt-0 lg:mt-5">
              <div class="bio-label">Vertical</div>
              <div class="bio-info text-base lg:text-[22px] lg:leading-[30px]">
                {{ vertical }}
              </div>
            </div>
          </div>
          <div class="quote-link">
            <text-link :linkPath="websiteUrl">Launch Site</text-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextLink from '~/components/TextLink/TextLink'
export default {
  components: { TextLink },
  props: {
    logo: {
      type: String
    },
    location: {
      type: String
    },
    vertical: {
      type: String
    },
    websiteUrl: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-bar {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
}
.icon img {
  filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
    brightness(102%) contrast(104%);
}

.info-box {
  background: linear-gradient(
      233.09deg,
      rgba(0, 186, 255, 0.2) 0%,
      rgba(18, 91, 118, 0.2) 100%
    ),
    #001c27;
}

.bio-label {
  background: linear-gradient(233.09deg, #f56789 9.9%, #c4103b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 20px;
}
.bio-info {
  margin-top: 10px;
  color: #ffffff;
  letter-spacing: 0.5px;
  line-height: 30px;
  font-weight: bold;
}
</style>
