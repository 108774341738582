import { render, staticRenderFns } from "./CareersAccordion.vue?vue&type=template&id=64c00a52&scoped=true&"
import script from "./CareersAccordion.vue?vue&type=script&lang=js&"
export * from "./CareersAccordion.vue?vue&type=script&lang=js&"
import style0 from "./CareersAccordion.vue?vue&type=style&index=0&id=64c00a52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c00a52",
  null
  
)

export default component.exports