<template>
  <svg
    width="90px"
    height="90px"
    viewBox="0 0 90 90"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>nacelle-clock</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-5.000000, -5.000000)"
        :fill="fillColor"
        fill-rule="nonzero"
      >
        <g transform="translate(5.000000, 5.000000)">
          <path
            d="M45,90 C69.75,90 90,69.75 90,45 C90,20.25 69.75,0 45,0 C20.25,0 0,20.25 0,45 C0,69.75 20.25,90 45,90 Z M45,7.5 C65.7,7.5 82.5,24.3 82.5,45 C82.5,65.7 65.7,82.5 45,82.5 C24.3,82.5 7.5,65.7 7.5,45 C7.5,24.3 24.3,7.5 45,7.5 Z M42.3,47.7 C41.55,46.95 41.25,46.05 41.25,45 L41.25,24.3 C41.25,22.2 42.9,20.55 45,20.55 C47.1,20.55 48.75,22.2 48.75,24.3 L48.75,43.5 L57.6,52.35 C59.1,53.85 59.1,56.25 57.6,57.6 C56.85,58.35 55.95,58.65 54.9,58.65 C53.85,58.65 52.95,58.35 52.2,57.6 L42.3,47.7 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String
    }
  },
  computed: {
    fillColor() {
      return this.color || '#000000'
    }
  }
}
</script>

<style></style>
