export default () => {
  var didInit = false
  function initMunchkin() {
    if (didInit === false) {
      didInit = true
      Munchkin.init('750-WWD-574')
    }
  }

  const rootScript = document.getElementsByTagName('script')[0]
  const marketoScript = document.createElement('script')
  marketoScript.type = 'text/javascript'
  marketoScript.defer = true
  marketoScript.src = '//munchkin.marketo.net/munchkin.js'
  marketoScript.onreadystatechange = function() {
    if (this.readyState === 'complete' || this.readyState === 'loaded') {
      initMunchkin()
    }
  }
  marketoScript.onload = initMunchkin
  rootScript.parentNode.insertBefore(marketoScript, rootScript)
}
