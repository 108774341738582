<template>
  <div class="simple-email-form-container">
    <form class="simple-email-form" @submit.prevent="submitForm">
      <div class="form-inner">
        <email-input label="Work Email" @inputchange="updateEmail" />
        <button type="submit">{{ linkText }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import EmailInput from '~/components/EmailInput'
import { mapMutations } from 'vuex'

export default {
  components: {
    EmailInput
  },

  props: {
    title: String,
    linkText: String,
    linkPath: String
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    submitForm(e) {
      this.$router.push(this.linkPath)
    },
    updateEmail(obj) {
      if (obj) {
        this.email = obj
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-email-form-container {
  display: flex;
  justify-content: center;
}
.form-inner {
  display: flex;
  align-items: center;
}

form /deep/ input {
  border-radius: 5px 0 0 5px;
  padding-left: 24px;
  padding-right: 24px;
  height: 41px !important;
  border: 1px solid #00baff !important;
  border-right: 0;
  background-color: transparent;
  font-size: 16px !important;
  letter-spacing: 0.5px;
  line-height: 23px;
  color: #ffffff !important;
  box-sizing: border-box;
  @include tablet {
    width: 300px;
  }
  &:focus {
    box-shadow: none;
  }
  &:placeholder {
    opacity: 0.6;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 23px;
  }
}

button {
  flex-basis: 154px;
  width: 154px;
  box-sizing: border-box;
  height: 41px;
  border-left: 0;
  border-radius: 0 5px 5px 0;
  padding: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

/deep/ input {
  border: 1px solid #00baff;
  border-right: 0;
  background-color: transparent;
}

button {
  background-color: #00baff;
  border: 1px solid #00baff;
  border-left: 0;
  color: #ffffff;

  &:hover,
  &:focus {
    background-color: #22346b;
  }
}

p {
  padding-top: 0.5rem;
  color: #ffffff;
  text-align: center;
  &.is-error {
    background: linear-gradient(233.09deg, #f56789 9.9%, #c4103b 100%);
    background-clip: text;
    color: transparent;
  }
}

// mobile
@include touch {
  .simple-email-form-container {
    margin: 0 28px;
  }
  .form-inner {
    flex-direction: column;
    align-items: inherit;
    margin: 0;
  }
  form {
    width: 100%;
  }
  form /deep/ input {
    border-radius: 5px;
    padding-left: 17px;
    padding-right: 17px;
    padding: 20px 17px;
    width: 100%;
    height: 33px !important;
    border: 1px solid #00baff;
    border-right: 1px solid #00baff !important;
    background-color: transparent;
    letter-spacing: 0.36px;
    line-height: 16px !important;
    color: #ffffff;
    box-sizing: border-box;
    margin-bottom: 10px;
    &:placeholder {
      opacity: 0.6;
      color: #ffffff;
      font-size: 13px;
      letter-spacing: 0.36px;
      line-height: 16px;
    }
  }

  button {
    flex-basis: auto;
    border-left: 1px !important;
    height: 42px;
    width: 100%;
    border-radius: 5px;
    color: #ffffff;
    // font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.33px;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
