<template>
  <li
    class="flex items-center relative cursor-pointer"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    {{ label }}
    <BaseIcon
      file="NacelleChevronDown"
      class="ml-2 text-[#FAFAFA] w-[10px] h-[6px]"
      alt="ChevronDown"
      v-if="linkList.length"
    />
    <NavDropdown :items="linkList" v-if="linkList.length && isOpen" />
  </li>
</template>

<script>
import BaseIcon from '~/components/BaseIcon/BaseIcon'
import NavDropdown from './NavDropdown.vue'
export default {
  components: {
    BaseIcon,
    NavDropdown
  },
  props: {
    label: {
      type: String
    },
    linkList: {
      type: [Array, null]
    }
  },
  data: () => ({
    isOpen: false,
    timeout: null
  }),
  methods: {
    handleMouseOver() {
      this.isOpen = true
      clearTimeout(this.timeout)
    },
    handleMouseOut() {
      this.timeout = setTimeout(() => {
        this.isOpen = false
      }, 150)
    }
  }
}
</script>

<style></style>
