var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex flex-col m-[6px] items-center text-center px-6 py-5 lg:py-12 border border-solid border-red-500 text-white rounded w-[237px] md:w-[337px] h-full md:h-[271px]",staticStyle:{"background-color":"#08232d"}},[_c('div',{staticClass:"mb-0 lg:mb-8"},[_c('img',{staticClass:"max-h-[100px]",attrs:{"src":_vm.feature.image.filename,"alt":_vm.feature.image.alt}})]),_vm._v(" "),_c('div',[(
        _vm.feature.link &&
        _vm.feature.link.linktype === 'story' &&
        _vm.feature.link.cached_url
      )?_c('nuxt-link',{staticClass:"feature-link no-underline",attrs:{"to":'/' + _vm.feature.link.cached_url}},[_c('h3',{staticClass:"text-xl font-bold mb-1"},[_vm._v(_vm._s(_vm.feature.title))])]):(
        _vm.feature.link &&
        _vm.feature.link.linktype === 'url' &&
        _vm.feature.link.cached_url
      )?_c('a',{staticClass:"feature-link no-underline",attrs:{"href":_vm.feature.link.cached_url,"target":"_blank"}},[_c('h3',{staticClass:"text-xl font-bold mb-1"},[_vm._v(_vm._s(_vm.feature.title))])]):_c('h3',{staticClass:"text-xl font-bold mb-1"},[_vm._v(_vm._s(_vm.feature.title))]),_vm._v(" "),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.feature.text))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }