<template>
  <div class="flex justify-center mx-2 md:mx-10 my-24">
    <div
      class="p-0 rounded border border-solid border-cyan-500 w-full max-w-[1170px]"
    >
      <BaseAccordion
        v-for="(jobs, team, index) in jobsByTeam"
        :key="team"
        :first="index === 0"
        :last="index === jobsByTeam.length - 1"
        :title="team"
      >
        <div>
          <div
            class="flex flex-col md:flex-row py-3 px-4 md:px-11"
            v-for="(job, i) in jobs"
            :key="job.title + i"
            :class="{ 'job-body-unit-last': i === jobs.length - 1 }"
          >
            <h5 class="flex flex-grow items-center m-0 font-light">
              {{ job.title }}
            </h5>
            <div
              class="flex flex-col md:flex-row md:w-1/3 md:items-center justify-between"
            >
              <div class="text-xl tracking-[.5px] leading-8 my-3">
                <div class="flex">
                  <img
                    class="mb-[3px]"
                    src="/nacelle-mapmarker.svg"
                    alt="map marker"
                  />
                  <p class="my-0 mx-2">{{ job.location }}</p>
                </div>
              </div>
              <text-link
                class="font-light max-w-[150px]"
                :linkPath="job.linkPath"
              >
                Apply Now
              </text-link>
            </div>
          </div>
        </div>
      </BaseAccordion>
    </div>
  </div>
</template>

<script>
import BaseAccordion from '~/components/BaseAccordion/BaseAccordion'
import TextLink from '~/components/TextLink/TextLink'
export default {
  props: {
    jobPostings: {
      type: Array,
      default: () => [
        {
          title: '',
          location: '',
          linkPath: '',
          team: ''
        }
      ]
    }
  },
  components: {
    BaseAccordion,
    TextLink
  },
  computed: {
    jobsByTeam() {
      return this.jobPostings.reduce((jobTable, job) => {
        const { team, ...rest } = job
        if (jobTable[job.team]) {
          jobTable[job.team].push(rest)
        } else {
          jobTable = {
            ...jobTable,
            [job.team]: [rest]
          }
        }
        return jobTable
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.job-body-unit-last {
  border-bottom: none !important;
}
</style>
