var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"fixed left-0 top-16 bottom-0 h-[calc(100vh-4rem)] z-10 bg-white w-screen transition ease-in-out duration-300 lg:w-72 lg:border-solid lg:border-0 lg:border-r lg:border-opacity-50 lg:border-gray-400 lg:top-20 lg:height-[calc(100vh-5rem)]",class:{
    'sidebar-closed -translate-x-full opacity-0 lg:translate-x-[-90%] lg:opacity-100':
      !_vm.sidebarOpen,
    'block opacity-100': _vm.sidebarOpen
  },attrs:{"data-cy":"docs-sidebar"}},[_c('button',{staticClass:"hidden lg:block absolute -right-4 top-3 w-8 h-8 p-0 rounded-full flex items-center justify-center bg-gray-300 border-none cursor-pointer shadow-light",attrs:{"aria-label":_vm.sidebarOpen ? 'Close sidebar' : 'Open sidebar',"aria-expanded":_vm.sidebarOpen.toString(),"data-cy":"docs-sidebar-toggle"},on:{"click":function($event){return _vm.setSidebarOpen(!_vm.sidebarOpen)}}},[_c('svg',{staticClass:"transition ease-in-out duration-300 origin-center mt-[2px] ml-[-2px] rotate-180",class:{
        'transform rotate-0 ml-[2px]': !_vm.sidebarOpen
      },attrs:{"width":"8","height":"14","viewBox":"0 0 8 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{class:{
          'fill-current text-gray-900': _vm.sidebarOpen,
          'fill-current text-gray-500': !_vm.sidebarOpen
        },attrs:{"d":"M0.110107 12.42L1.29011 13.6L7.89011 6.99999L1.29011 0.399994L0.110107 1.57999L5.53011 6.99999L0.110107 12.42H0.110107Z","fill":"#9FA1A2"}})])]),_vm._v(" "),_c('div',{staticClass:"scrollable h-full flex flex-col overflow-auto",class:{ 'overflow-hidden': !_vm.sidebarOpen }},[_c('div',{staticClass:"flex-1 w-full transition ease-in-out duration-300",class:{
        'opacity-0': !_vm.sidebarOpen
      },attrs:{"inert":!_vm.sidebarOpen}},[_c('DocsTechStack',{staticClass:"px-6"}),_vm._v(" "),_c('DocsNavigation',{staticClass:"px-6"})],1),_vm._v(" "),_c('footer',{staticClass:"w-full border-solid border-0 border-t border-opacity-50 border-gray-400 bg-white pt-4 pb-8 transition ease-in-out duration-300",class:{
        'opacity-0': !_vm.sidebarOpen
      },attrs:{"inert":!_vm.sidebarOpen}},[_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 text-sm"},[_c('ul',{staticClass:"list-none m-0 p-0"},[_c('li',{staticClass:"mb-2"},[_c('a',{staticClass:"text-gray-600 no-underline",attrs:{"href":"/docs/changelog"}},[_vm._v("\n              Nacelle Changelog\n            ")])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"no-underline text-red-500",attrs:{"href":"/"}},[_vm._v("\n              Go to nacelle.com\n            ")])])])])}]

export { render, staticRenderFns }