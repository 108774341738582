<template>
  <nav id="docs-navigation" @keyup="handleKeyUp">
    <ul class="list-none pl-0">
      <DocsNavigationMenuItem
        v-for="menu in getNav"
        :key="menu.uuid"
        :nodes="menu"
        :depth="0"
        class="mb-6"
      >
        <template v-slot:title>
          {{ menu.title }}
        </template>
      </DocsNavigationMenuItem>
    </ul>
  </nav>
</template>

<script>
/**
 * Recursive menu component for generating the sidebar navigation within the docs section
 */

import { mapState } from 'pinia'
import { useDocsStore } from '~/store/docs'
import DocsNavigationMenuItem from '~/components/DocsNavigation/DocsNavigationMenuItem'

export default {
  components: {
    DocsNavigationMenuItem
  },
  computed: {
    ...mapState(useDocsStore, ['getNav'])
  },
  methods: {
    handleKeyUp(e) {
      // Query to get focusable elements within the sidebar nav
      const navEl = document.getElementById('docs-navigation')
      const focusableElements = navEl.querySelectorAll(['a', 'button'])

      // Convert nodelist of elements into an array
      const focusableElementsArr = Array.from(focusableElements)

      // Grab current active element
      const activeEl = document.activeElement

      // Find current active element within the array of focusable elements
      const activeElIndex = focusableElementsArr.findIndex(
        (f) => f.id === activeEl.id
      )

      const lastIdx = focusableElementsArr.length - 1

      if (e.key === 'ArrowUp') {
        activeElIndex <= 0
          ? focusableElementsArr[0].focus()
          : focusableElementsArr[activeElIndex - 1].focus()
      }
      if (e.key === 'ArrowDown') {
        activeElIndex >= lastIdx
          ? focusableElementsArr[lastIdx].focus()
          : focusableElementsArr[activeElIndex + 1].focus()
      }
    }
  }
}
</script>
