<template>
  <div
    :class="[
      theme.select.selectDropdown.wrapper,
      {
        hidden: !showMenu
      }
    ]"
  >
    <GradientFade :show="showTopGradient" position="top" />
    <ul
      ref="dropdown"
      tabindex="-1"
      role="listbox"
      :class="[theme.select.selectDropdown.menu]"
      :aria-activedescendant="id" @scroll="handleScroll" 
    >
      <li
        v-for="(option, i) in optionValues"
        :key="`select-option-${unique(option.label)}-${i}`"
        :id="`select-option-${unique(option.label)}-${i}`"
        role="option"
        :class="[
          theme.select.selectDropdownItems.el,
          {
            [theme.select.selectDropdownItems.selected]: selected === option.value,
            [theme.select.selectDropdownItems.unselected]: selected !== option.value,
            [theme.select.selectDropdownItems.placeholder]:
              option.isPlaceholder || !option.value
          }
        ]"
        @click="(e) => handleChange(option.value)"
      >
        <span class="block truncate">
          {{ option.label }}
        </span>
        <Checkmark v-if="selected === option.value" />
      </li>
    </ul>
    <GradientFade :show="showBottomGradient" position="bottom" />
  </div>
</template>

<script>
import Checkmark from './SelectCheckmark.vue'
import GradientFade from '../GradientFade.vue'
export default {
  components: {
    Checkmark,
    GradientFade
  },
  props: {
    id: {
      type: String
    },
    optionValues: {
      type: Array,
      default: () => [{}]
    },
    fieldMetaData: {
      type: Object
    },
    theme: {
      type: Object
    },
    selected: {
      type: String,
      default: ''
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isOverflowing: false,
    scrollPos: 0,
    top: 0,
    bottom: 0,
    buffer: 8
  }),
  computed: {
    showTopGradient() {
      return this.isOverflowing && this.scrollPos >= this.top
    },
    showBottomGradient() {
      return this.isOverflowing && this.scrollPos <= this.bottom
    }
  },
  watch: {
    showMenu: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.$nextTick()
          const el = this.$refs.dropdown
          if (el.scrollHeight > el.clientHeight) this.isOverflowing = true
          this.scrollPos = el.scrollTop
          this.top += this.buffer
          this.bottom = el.scrollHeight - el.clientHeight - this.buffer
        }
      }
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('input', {
        target: { id: this.id, value }
      })
    },
    handleScroll(e) {
      if (!this.isOverflowing) return
      this.scrollPos = e.target.scrollTop
    },
    unique(val) {
      if (!val) return ''
      return val.replace(' ', '-')
    }
  }
}
</script>
