<template>
  <button
    :id="`modal-${_uid}`"
    class="border-0 p-0 cursor-pointer focus:ring-2 ring-white rounded"
    ref="openButtonRef"
    @click="openModal"
    :inert="pageHasModalOpen"
  >
    <slot name="button" />

    <Portal v-if="isOpen" to="modal">
      <div
        :class="`${
          modalType === 'center' ? 'justify-center' : null
        } fixed flex items-center inset-0 bg-black bg-opacity-75 z-[101]`"
        @keydown.esc="closeModal"
      >
        <div :id="uid" class="relative w-10/12 max-w-4xl z-[102]">
          <button
            ref="closeButtonRef"
            class="absolute flex justify-center top-0 right-0 bg-cyan-500 p-2 rounded -mt-3 -mr-3 z-10 focus:ring-2 ring-white border-0 shadow"
            @click="closeModal"
            aria-label="close"
          >
            <img
              class="w-4 h-4"
              src="/alertbar-x-icon.svg"
              style="filter: invert(1)"
              alt="Close modal"
            />
          </button>

          <div v-click-outside="closeModal">
            <slot name="content" />
          </div>
        </div>
      </div>
    </Portal>
  </button>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useModalStore } from '~/store/modal'
import { v4 as uuidv4 } from 'uuid'
import { Portal } from 'portal-vue'
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Portal
  },
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    modalType: {
      type: String,
      default: 'center',
      validator: (val) => ['center', 'left']
    }
  },
  computed: {
    openButton() {
      return document.getElementById(`openButton-${this._uid}`)
    },
    uid() {
      return uuidv4()
    },
    ...mapState(useModalStore, ['pageHasModalOpen'])
  },
  methods: {
    ...mapActions(useModalStore, ['isModalOpen']),
    async closeModal() {
      // focus the element that triggered the modal when the modal is closed
      await this.isModalOpen(false)
      await this.$nextTick()
      await this.$nextTick()

      this.isOpen = false
      this.$refs.openButtonRef?.focus()
    },
    async openModal() {
      this.isOpen = true
      await this.isModalOpen(true)
      await this.$nextTick()
      await this.$nextTick()
      this.$refs.closeButtonRef?.focus()
    }
  }
}
</script>
