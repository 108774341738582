<template>
  <section class="max-w-[1170px] mx-auto my-24">
    <div class="flex flex-col md:flex-row">
      <div class="md:w-[40%] px-7">
        <h2 class="eyebrow">{{ eyebrow }}</h2>
        <p>{{ body }}</p>
      </div>
      <div class="md:w-[60%] flex flex-wrap justify-center">
        <a
          v-for="partner in partners"
          :key="partner.id"
          :href="partner.link"
          target="_blank"
          class="max-w-[200px]"
        >
          <img
            :src="partner.logo.filename"
            class="w-full"
            :alt="partner.logo.alt"
          />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    eyebrow: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      required: true
    },
    partners: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
