<template>
  <div class="text-white rounded p-0 max-w-[1170px]">
    <button
      class="accordion-header text-white border-none w-full p-0"
      :aria-expanded="isOpened.toString()"
      @click="toggleOpened()"
      v-on:keyup.down="moveDown()"
      v-on:keyup.up="moveUp()"
      v-on:keyup.home="moveFirst()"
      v-on:keyup.end="moveLast()"
    >
      <div
        class="accordion-header-inner flex justify-between items-center content-center py-5 px-4 md:px-11"
      >
        <div
          class="text-2xl md:text-3xl tracking-wider leading-10 m-0"
          :class="{ 'text-left': titleLeft }"
        >
          {{ title }}
        </div>
        <div
          class="accordion-chevron"
          :class="{ 'accordion-chevron-down': isOpened }"
        ></div>
      </div>
    </button>
    <div
      class="accordion-body"
      :class="{ 'is-opened': isOpened, 'accordion-last': last }"
    >
      <slot v-if="isOpened" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    first: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    },
    openAll: {
      type: Boolean,
      default: true
    },
    titleLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpened: false
    }
  },
  mounted() {
    if (this.openAll) {
      this.isOpened = true
    } else if (this.first) {
      this.isOpened = true
    }
  },
  methods: {
    toggleOpened() {
      this.isOpened = !this.isOpened
    },
    moveDown() {
      // if this.$el has a next sibling
      if (this.$el.nextElementSibling) {
        // focus on the next sibling's button
        this.$el.nextElementSibling.querySelector('button')?.focus()
      }
    },
    moveUp() {
      // if this.$el has a previous sibling
      if (this.$el.previousElementSibling) {
        // focus on the previous sibling's button
        this.$el.previousElementSibling.querySelector('button')?.focus()
      }
    },
    moveFirst() {
      // focus on the first accordion's button
      this.$el.parentNode.querySelector('button')?.focus()
    },
    moveLast() {
      // focus on the last accordion's button
      const length = this.$el.parentNode.children.length
      this.$el.parentNode.querySelectorAll('button')[length - 1]?.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-header {
  background-color: rgba(68, 100, 204, 0.1);
  .accordion-header-inner {
    .accordion-chevron::before {
      color: #00baff;
      border-style: solid;
      border-width: 1px 1px 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: -5px;
      position: relative;
      top: 0.15em;
      transform: rotate(-45deg);
      vertical-align: top;
      width: 0.45em;
      transition: 0.3s ease all;
    }
    .accordion-chevron-down::before {
      transform: rotate(135deg);
    }
  }
}
.accordion-body {
  transition: 0.3s ease all;
  border-bottom: 0.5px #00baff solid;
}
.is-opened {
  border-bottom: 0px #000 solid;
}
.accordion-last {
  border-bottom: none !important;
}
</style>
