var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ 'bg-white py-12 px-5 lg:px-0': _vm.theme === 'light' }},[_c('figure',{staticClass:"max-w-4xl m-0 mx-auto relative my-12",class:_vm.isHorizontal
        ? 'horizontal border-0 lg:border-b border-solid border-cyan-500 pl-20 lg:pb-12'
        : 'flex flex-col items-center'},[_c('blockquote',{staticClass:"text-xl lg:text-2xl",class:[
        _vm.isHorizontal
          ? 'pt-0 mt-2 mb-0 mx-5 leading-7 lg:leading-9'
          : 'pt-14 mt-2 mb-6 mx-10 leading-8 tracking-tight text-center',
        _vm.textColor
      ]},[_vm._v("\n      "+_vm._s(_vm.quote)+"\n    ")]),_vm._v(" "),_c('figcaption',{staticClass:"flex flex-col text-lg",class:_vm.isHorizontal
          ? 'ml-5 mt-11 lg:mt-6'
          : 'lg:flex-row text-center items-center'},[_c('h5',{staticClass:"my-0",class:[
          _vm.isHorizontal ? 'lg:text-lg' : 'lg:text-2xl italic font-normal',
          _vm.textColor
        ]},[_vm._v("\n        "+_vm._s(_vm.name)+"\n      ")]),_vm._v(" "),(!_vm.isHorizontal)?_c('span',{staticClass:"w-16 h-0 border border-solid border-cyan-500 m-3"}):_vm._e(),_vm._v(" "),_c('span',{class:[
          _vm.isHorizontal ? 'lg:text-xl' : 'lg:text-2xl italic',
          _vm.textColor
        ]},[_vm._v(_vm._s(_vm.title))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }