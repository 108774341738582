<template>
  <div>
    <div v-if="isHomepage && mappedStory.hero && mappedStory.hero.length">
      <Carousel v-if="mappedStory.hero.length > 1" has-dots is-bright>
        <Hero
          v-for="(hero, index) in mappedStory.hero"
          :key="index"
          :heading="hero.heading"
          :sub-heading="hero.subheading"
          :image="hero.media"
          :cta="hero.cta.length ? hero.cta[0] : null"
      /></Carousel>
      <Hero
        v-else
        :heading="mappedStory.hero[0].heading"
        :sub-heading="mappedStory.hero[0].subheading"
        :image="mappedStory.hero[0].media"
        :cta="
          mappedStory.hero[0].cta.length ? mappedStory.hero[0].cta[0] : null
        "
      />
    </div>
    <div v-if="mappedStory.sections">
      <div v-for="block in mappedStory.sections" :key="block._uid">
        <StoryblokResolver :block="block" :storyFields="mappedStoryFields" />
      </div>
    </div>
    <div v-else>
      <StoryblokResolver :block="mappedStory" v-editable="mappedStory" />
    </div>
    <GdprModal v-if="gdprCheck" modalType="center"> </GdprModal>
  </div>
</template>

<script>
import GdprModal from '~/components/GdprModal/GdprModal'
import Carousel from '~/components/V2/Carousel/Carousel'
import Hero from '~/components/V2/Hero/Hero.vue'
import { useGdprStore } from '~/store/gdpr'
import { mapState } from 'pinia'

export default {
  components: {
    GdprModal,
    Hero,
    Carousel
  },
  props: {
    story: {
      type: Object,
      required: true
    },
    name: {
      type: String
    }
  },
  created() {
    if (process.env.NODE_ENV !== 'production' && this.name) {
      this.$options.name = this.name.replace(' ', '-')
    }
  },
  computed: {
    ...mapState(useGdprStore, ['isInEu']),
    mappedStory() {
      return {
        publishedAt: this.story.published_at,
        firstPublishedAt: this.story.first_published_at,
        slug: this.story.slug,
        name: this.story.name,
        ...this.story.content
      }
    },
    mappedStoryFields() {
      if (this.mappedStory) {
        const { sections, SEO, _editable, _uid, ...otherContent } =
          this.mappedStory
        return otherContent
      }
      return null
    },
    gdprCheck() {
      return this.isInEu
    },
    isHomepage() {
      return this.mappedStory.component === 'homepage'
    }
  }
}
</script>
