<template>
  <div
    class="testimonial"
    :class="{ 'testimonial-right': quotePosition === 'right' }"
  >
    <img class="desktop-image" :src="image.filename" alt="" />
    <img
      v-if="!mobileImage.filename"
      class="mobile-image"
      :src="image.filename"
      alt=""
    />
    <img v-else class="mobile-image" :src="mobileImage.filename" alt="" />
    <div class="testimonial-text">
      <blockquote>"{{ quote }}"</blockquote>
      <div class="author">
        <img
          class="author-photo"
          v-if="authorPhoto.filename"
          :src="authorPhoto.filename"
          :alt="authorPhoto.title"
        />
        <div>
          <h5>{{ name }}</h5>
          <span>{{ title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quote: {
      type: String,
      default: ''
    },
    authorPhoto: {
      type: Object,
      default: () => ({
        image: '',
        alt: ''
      })
    },
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: () => ({
        image: '',
        alt: ''
      })
    },
    mobileImage: {
      type: Object,
      default: () => ({
        image: '',
        alt: ''
      })
    },
    quotePosition: {
      type: String
    }
  },
  computed: {
    styles() {
      if (this.image) {
        return {
          background: `url(${this.image.filename})`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }
      }
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
.testimonial {
  width: 100%;
  position: relative;
}
.testimonial-text {
  blockquote {
    color: #010204;
    font-size: 26px;
    font-style: italic;
    letter-spacing: 0.81px;
    line-height: 36px;
    margin: 15px 0;
  }
  .author {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 25px;
  }
  .author-photo {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    margin-right: 20px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  h5 {
    margin-top: 0;
    color: #010204;
    margin-bottom: 0;
  }
  span {
    color: #010204;
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 30px;
    font-weight: 100;
  }
}
.desktop-image,
.mobile-image {
  width: 100%;
  z-index: -2;
  clip-path: polygon(0 0, 100% 0, 100% 98%, 0 85%);
}

.testimonial-right {
  .testimonial-text {
    position: absolute;
    max-width: 470px;
    top: 25%;
    left: 60%;
    blockquote,
    h5,
    span {
      color: #fff;
      margin: 25px;
    }
  }
}
.mobile-image {
  display: none;
}
@include touch {
  .testimonial-right {
    .testimonial-text {
      max-width: 470px;
      top: 0;
      left: 60%;
    }
  }

  .testimonial-text {
    top: 0px !important;
    left: 0px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    blockquote {
      font-size: 20px;
      line-height: 28px;
      margin: 10px 20px 20px;
    }
    h5,
    span {
      font-size: 18px;
      line-height: 12px;
      margin: 10px 20px;
    }
    span {
      margin-bottom: 150px;
    }
  }
  .mobile-image {
    display: block;
  }
  .desktop-image {
    display: none;
  }
}
@include tablet {
  .testimonial-right {
    .testimonial-text {
      width: 100vw;
      max-width: 100vw;
      margin: auto;
      align-items: center;
      justify-content: center;
    }
  }
}
@include desktop {
  .testimonial-right {
    .testimonial-text {
      width: auto;
      max-width: auto;
      top: 15%;
    }
  }
}
</style>
