<template>
  <pre :class="attrs.class"><code :class="attrs.class"><slot /></code></pre>
</template>

<script>
import 'prismjs/themes/prism-okaidia.css'

export default {
  props: {
    attrs: {
      type: Object,
      required: true
    }
  }
}
</script>
