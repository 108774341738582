import {
  convertDateTimeToUserTimezone,
  getTimeStampFromUTC,
  isPastEvent as _isPastEvent
} from '../../utils/dateTime'

export default (block, storyFields) => {
  let form
  const timestamp = getTimeStampFromUTC(storyFields.dateTime)
  const isPastEvent = _isPastEvent(timestamp)

  if (storyFields.category !== 'Industry Event' && block.rightCol.length) {
    form = block.rightCol[0].formType[0]
    if (isPastEvent) {
      form.submitText = 'Watch Now'
    }
  }

  return {
    ...block,
    ...storyFields,
    date: convertDateTimeToUserTimezone(storyFields.dateTime, 'PPP'),
    time: convertDateTimeToUserTimezone(
      getTimeStampFromUTC(storyFields.dateTime),
      'h:mm a z'
    ),
    isPastEvent
  }
}
