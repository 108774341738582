<template>
  <section class="pt-16 lg:pt-32 pb-12 w-full flex justify-center text-center">
    <div class="max-w-[1200px]">
      <div>
        <p class="mb-8 text-red-500">{{ title }}</p>

        <div
          :class="{
            'flex justify-center flex-wrap': !hasOnlyFour,
            'grid grid-cols-1 md:grid-cols-2 gap-y-16 md:gap-y-0': hasOnlyFour
          }"
        >
          <Feature
            v-for="(item, index) in feature"
            :feature="item"
            :key="index"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Feature from '~/components/Feature/Feature.vue'

export default {
  components: {
    Feature
  },
  props: {
    title: {
      type: String,
      required: true
    },
    feature: {
      type: Array,
      required: true
    }
  },
  computed: {
    hasOnlyFour() {
      return this.feature && this.feature.length === 4
    }
  }
}
</script>
