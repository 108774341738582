<template>
  <div>
    <template v-if="field.dataType !== 'htmltext'">
      <label class="sr-only" :for="field.id" v-if="!noLabelType">{{ field.label }}</label>
      <div class="relative">
      <component
        :is="inputComponent"
        v-bind="{ ...field, type }"
        v-on="$listeners"
        :theme="theme"
        :class="[
          {
            [theme.shared]: hasShareableStyle,
            [theme.simple]: isSimple,
            [theme[inputType]]: !isSimple && inputType !== 'select',
            [theme.error.el]: hasError && !field.disabled,
            [theme.disabled]: field.disabled
          }
        ]"
        :hasError="hasError"
      />
      <Icon file="CircleExclamation" :class="{[theme.error.icon]: hasError}" v-if="hasError && canShowErrorIcon" alt="Error Icon" />
      </div>
      <transition name="fade" mode="in-out">
        <div
          class="error mt-1"
          :class="[theme.hint.el]"
          v-if="hasError"
        > 
          <Icon file="CircleExclamation" :class="[theme.hint.icon]" alt="Info Icon" />
          <div v-html="field.validationMessage || errors[0]" />
        </div>
      </transition>
    </template>
    <template v-else-if="field.dataType === 'htmltext' && !isSimple">
      <div v-html="htmlTextWithClasses"></div>
    </template>
  </div>
</template>

<script>
import TextInput from './TextInput'
import SelectInput from './SelectInputTUI'
import TextareaInput from './TextareaInput'
import CheckboxInput from './CheckboxInput'
import Icon from "~/components/BaseIcon/BaseIcon"

export default {
  components: {
    CheckboxInput,
    TextInput,
    SelectInput,
    TextareaInput,
    Icon
  },
  props: {
    field: {
      type: Object
    },
    errors: {
      type: Array
    },
    theme: {
      type: Object
    },
    isSimple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    fieldData: ''
  }),
  computed: {
    type() {
      return this.field?.dataType || this.field?.type || 'text'
    },
    hasError() {
      return !!this.errors.length
    },
    inputComponent() {
      return {
        checkbox: 'CheckboxInput',
        email: 'TextInput',
        hidden: 'TextInput',
        password: 'TextInput',
        phone: 'TextInput',
        select: 'SelectInput',
        textarea: 'TextareaInput',
        text: 'TextInput',
        url: 'TextInput'
      }[this.type]
    },
    inputType() {
      return ['email', 'phone', 'text', 'url'].includes(this.type)
        ? 'input'
        : this.type
    },
    noLabelType() {
      return ['checkbox', 'radio'].includes(this.type)
    },
    hasShareableStyle() {
      const shared = ['select', 'textarea', 'password']
      return this.inputType === 'input' || shared.includes(this.type)
    },
    htmlTextWithClasses() {
      if (this.field.dataType !== 'htmltext') return ''
      let field = this.field.text.replace('<em>', `<em class="${this.theme.htmlText.em}">`)
      field = field.replace('<a ', `<a class="${this.theme.htmlText.a}" `)
      return field
    },
    canShowErrorIcon() {
      return !['select', 'checkbox', 'textarea'].includes(this.inputType)
    }
  }
}
</script>