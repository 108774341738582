const { MeiliSearch } = require('meilisearch')
const axios = require('axios')

const isPreview =
  process.env.VERCEL_ENV === 'preview' ||
  process.env.NUXT_ENV_VERCEL_ENV === 'preview' ||
  process.env.NODE_ENV === 'development' ||
  process.env.PREVIEW === 'true'

const client = new MeiliSearch({
  host: process.env.SEARCH_HOST,
  apiKey: process.env.SEARCH_PRIVATE_KEY
})

const storyblokToken = isPreview
  ? process.env.STORYBLOK_PREVIEW_KEY
  : process.env.STORYBLOK_KEY

const version = isPreview ? 'draft' : 'published'

const indexName = isPreview ? 'draftDocs' : 'publishedDocs'

const indexDocs = async () => {
  const cacheVersion = await axios
    .get(`https://api.storyblok.com/v1/cdn/spaces/me?token=${storyblokToken}`)
    .then((res) => res.data.space.version)

  const perPage = 100 // max number of records SB can return per page

  // First, we need to find out how many docs we have total
  const total = await axios
    .get(
      `https://api.storyblok.com/v1/cdn/stories?token=${storyblokToken}&version=${version}&starts_with=docs&per_page=1&cv=${cacheVersion}`
    )
    .then((res) => res.headers.total)

  // Second, we need to find out how many pages we need to fetch
  const pages = Math.ceil(total / perPage)

  // Now we need to request all the docs from all the pages
  const requests = []
  for (let i = 0; i < pages; i++) {
    requests.push(
      axios.get(
        `https://api.storyblok.com/v1/cdn/stories?token=${storyblokToken}&version=${version}&cv=${cacheVersion}&starts_with=docs&per_page=${perPage}&page=${
          i + 1
        }`
      )
    )
  }
  const responses = await Promise.all(requests)
  const docs = responses.reduce((acc, res) => {
    return [...acc, ...res.data.stories]
  }, [])

  // now we need to get all the releases
  const releases = await axios
    .get(
      `https://api.storyblok.com/v1/cdn/stories?token=${storyblokToken}&version=${version}&starts_with=releases&per_page=100&cv=${cacheVersion}`
    )
    .then((res) =>
      res.data.stories.map((release) => {
        return {
          uuid: release.uuid,
          version: release.content.version
        }
      })
    )

  // find the latest content version for each doc
  const docsWithLatestContent = []

  docs.forEach((doc) => {
    if (doc.content.body && doc.content.body.length) {
      doc.content.body.forEach((item) => {
        const matchedRelease = releases.find(
          (release) => release.uuid === item.release
        )
        item.release = matchedRelease
      })
      const latestDocBody = getLatestReleaseVersion(doc.content.body)

      const richText = latestDocBody.body
      if (!richText || !richText.content.length) {
        // no content in body, don't need to index
        return
      }

      const contentArr = []

      const extractText = (contentBlock) => {
        contentBlock.forEach((contentItem) => {
          if (
            !contentItem.content ||
            !contentItem.content.length ||
            contentItem.type === 'code_block'
          ) {
            return
          }

          let contentStr = ''

          contentItem.content.forEach((item) => {
            if (item.content && item.content.length) {
              extractText(item.content)
            } else if (item.text) {
              contentStr += item.text
            }
          })

          contentArr.push(contentStr.replace(/\s{2,}/g, ' '))
        })
      }

      extractText(richText.content)

      contentArr.forEach((item, index) => {
        docsWithLatestContent.push({
          name: doc.name,
          slug: doc.full_slug,
          id: `${doc.id}-${index}`,
          ecommerce_service: doc.content.ecommerce_service,
          deployment_service: doc.content.deployment_service,
          framework: doc.content.framework,
          cms_service: doc.content.cms_service,
          content: item
        })
      })
    }
  })

  const latestDocsIds = docsWithLatestContent.map((doc) => doc.id)

  const existingDocs = await getDocs()

  const existingDocsToRemove = existingDocs.filter((doc) => {
    return latestDocsIds.indexOf(doc.id) === -1
  })

  const idsToRemove = existingDocsToRemove.map((doc) => doc.id)

  deleteDocs(idsToRemove)
  updateDocs(docsWithLatestContent)
}

const updateDocs = async (docs) => {
  client
    .index(indexName)
    .addDocuments(docs)
    .then((res) => console.log('Docs index updated successfully!'))
    .catch((err) => console.log(err))

  client.index(indexName).updateSettings({
    searchableAttributes: ['content', 'name']
  })
}

const getLatestReleaseVersion = (docContentArr) => {
  const versionsArr = []
  docContentArr.forEach((item) => {
    versionsArr.push(item.release.version)
  })
  versionsArr.sort((a, b) => {
    return b < a ? -1 : b > a ? 1 : 0
  })
  const latestVersion = versionsArr[0]
  const latestDoc = docContentArr.find(
    (item) => item.release.version === latestVersion
  )
  return latestDoc
}

const getDocs = async () => {
  return client
    .index(indexName)
    .getDocuments({ limit: 100000 })
    .then((res) => res)
}

const searchDocs = (query) => {
  return client
    .index(indexName)
    .search(query, {
      limit: 10,
      attributesToCrop: ['*'],
      cropLength: 100,
      attributesToHighlight: ['*']
    })
    .then((res) => res)
}

const deleteDocs = (ids) => {
  return client.index(indexName).deleteDocuments(ids)
}

export { searchDocs, indexDocs, getDocs }
