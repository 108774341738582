<template>
  <BaseSection :bg-color="bgColor">
    <div class="flex flex-col lg:flex-row items-center">
      <div class="w-full lg:w-1/2">
        <Rubric class="mb-6">{{ rubric }}</Rubric>
        <PagesHeader class="mb-5">
          <SplitHeading v-if="isMulticolorHeading" :heading="heading" />
          <span v-else>{{ heading }}</span>
        </PagesHeader>
        <RichTextRenderer :document="copy" />
        <Cta v-if="cta.length" :cta="cta[0]" />
      </div>
      <div class="w-full lg:w-1/2">
        <img :src="image.filename" :alt="image.alt" class="max-w-full" />
      </div>
    </div>
  </BaseSection>
</template>

<script>
import BaseSection from '~/components/V2/Base/BaseSection/BaseSection'
import Cta from '~/components/V2/Cta/Cta.vue'
import SplitHeading from '~/components/V2/Base/BaseText/SplitHeading'
export default {
  components: {
    BaseSection,
    Cta,
    SplitHeading
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    rubric: {
      type: String,
      default: ''
    },
    copy: {
      type: Object,
      default: () => {}
    },
    image: {
      type: Object,
      default: () => {}
    },
    cta: {
      type: Array,
      default: () => []
    },
    isGray: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgColor() {
      if (this.isGray) return 'bg-gray-100'
      return 'bg-white'
    },
    isMulticolorHeading() {
      return this.heading.includes('*')
    }
  }
}
</script>
