<template>
  <BaseButton
    :button-path="cta.url"
    :label="cta.text"
    :variant="cta.type"
    :new-tab="cta.newTab"
    size="large"
    class="mt-10"
  />
</template>

<script>
import BaseButton from '~/components/V2/Base/BaseButton/BaseButton'
export default {
  components: {
    BaseButton
  },
  props: {
    cta: {
      type: Object,
      required: true
    }
  }
}
</script>
