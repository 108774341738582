<template>
  <p :class="paragraphClass">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-gray-700'
    }
  },
  computed: {
    paragraphClass() {
      return this.isLegacy
        ? ''
        : `m-0 p-0 text-[22px] leading-8 tracking-normal ${this.color}`
    },
    isLegacy() {
      // TODO: remove '/blog' from here when we introduce an updated blog post design
      return (
        this.$route.path.includes('/docs') || this.$route.path.includes('/blog')
      )
    }
  }
}
</script>
