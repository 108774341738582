<template>
  <div
    class="resource-content-container relative mt-25 px-0 pt-[70px] pb-4 md:pb-0 md:mb-16"
  >
    <div class="flex flex-col md:flex-row my-0 mx-auto relative max-w-[1170px]">
      <div v-if="hasImage" class="lg:w-1/2 max-w-[770px] m-5">
        <img :src="image.filename" :alt="image.alt" class="max-w-full" />
      </div>
      <div v-else class="text-section lg:w-3/5">
        <rich-text-renderer v-if="overview" :document="overview" />
      </div>
      <div
        class="text-section right-side"
        :class="hasImage ? 'lg:w-1/2' : 'lg:w-2/5'"
      >
        <rich-text-renderer v-if="content" :document="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      default: () => ({
        filename: '',
        alt: ''
      })
    },
    overview: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasImage() {
      return this.image.filename
    }
  }
}
</script>

<style lang="scss" scoped>
.right-side ul {
  list-style-image: url('/circle-bullet.png');
  margin-left: 20px;
  margin-top: 50px;
  li {
    padding-left: 20px;
    p {
      position: relative;
      top: -5px;
      margin-top: 36px;
    }
  }
  @include touch {
    list-style-image: url('/circle-bullet-sm.png');
    padding-left: 10px;
    margin-top: 32px;
    li {
      padding-left: 10px;
      p {
        top: 0;
      }
    }
  }
}

.resource-content-container {
  z-index: -2;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 300px);
    background: #fff;
    clip-path: polygon(0 0, 100% 0, 100% 98%, 0 85%);
  }
}

.text-section {
  margin: 0 50px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #010204;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
}
</style>
