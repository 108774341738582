<template>
  <div class="py-5 px-0 mb-[100px] relative">
    <div
      class="flex flex-col md:flex-row items-center justify-around text-center max-w-[1170px] my-12 mx-auto"
    >
      <div
        class="max-w-xs mx-2 mb-16 md:m-0 md:self-start"
        v-for="stat in stats"
        :key="stat._uid"
      >
        <NumTicker v-if="stat.stat" :stat="stat.stat" />
        <h5 class="mb-4" v-if="stat.title">{{ stat.title }}</h5>
        <p class="mb-4" v-if="stat.text">{{ stat.text }}</p>
      </div>
    </div>
    <img class="key-stats-bg-image" :src="backgroundImage.filename" alt="" />
    <div v-if="hasWhiteBackground" class="white-bg" />
  </div>
</template>

<script>
import NumTicker from '~/components/NumTicker'

export default {
  props: {
    stats: Array,
    backgroundImage: Object,
    hasWhiteBackground: {
      type: Boolean,
      default: false
    }
  },
  components: { NumTicker }
}
</script>

<style lang="scss" scoped>
.white-bg {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -100px;
  z-index: -100;
}

.key-stats-bg-image {
  position: absolute;
  top: 0;
  z-index: -99;
  height: calc(100% + 100px);
  width: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
}
/deep/ .ticker {
  color: #fff;
  font-size: 100px;
  font-weight: 900;
  letter-spacing: 1.79px;
  margin: 0;
}
</style>
