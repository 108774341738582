<template>
  <span>
    <span>{{ splitHeading[0] }}</span
    ><span :class="colorString">{{ splitHeading[1] }}</span
    ><span>{{ splitHeading[2] }}</span>
  </span>
</template>

<script>
export default {
  props: {
    colorString: {
      type: String,
      default: 'text-red-700'
    },
    heading: {
      type: String,
      required: true
    }
  },
  computed: {
    splitHeading() {
      return this.heading.split('*')
    }
  }
}
</script>
