<template>
  <div class="container checkbox-grid">
    <div
      v-for="({ label, value, defaultValue }, index) in passIn"
      :key="`${label}-${index}`"
      class="text-sm flex align-center"
    >
      <Checkbox :defaultValue="defaultValue" @input="onCheck" :label="label" :value="value">
        <template v-slot:icon>
          <img
            :src="`/svg/techstack/${value}.svg`"
            class="p-1 w-4"
          />
        </template>
      </Checkbox>
    </div>
  </div>
</template>

<script>
import Checkbox from '~/components/Checkbox/Checkbox'

export default {
  name: 'CheckBoxList',
  components: {
    Checkbox
  },
  props: {
    passIn: {
      type: [Array, Object]
    }
  },
  data() {
    return {
      arrayData: []
    }
  },
  watch: {
    arrayData(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    onCheck(e) {
      if (e.checked) {
        this.arrayData = [...this.arrayData, e]
      } else {
        this.arrayData = this.arrayData.filter(
          (checkbox) => checkbox.id !== e.id
        )
      }
    }
  }
}
</script>
