export default ({ app }, inject) => {
  inject('track', ({ name, category, label, ...properties }) => {
    window.analytics.track(name, {
      category,
      label,
      ...properties
    })
  })

  inject('identify', ({ user, name, email, ...traits}) => {
    if (window) {
      window.analytics.identify(user, {
        name,
        email,
        ...traits
      })
    }
  })
}
