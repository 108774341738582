<template>
  <div class="mx-auto relative max-w-[1296px]" :class="{ bright: isBright }">
    <vue-slick-carousel v-bind="settings">
      <template #prevArrow>
        <div class="bg-red-700">TODO:</div>
      </template>
      <template #nextArrow>
        <div class="bg-red-700">TODO:</div>
      </template>
      <slot />
      <template #customPaging>
        <div class="custom-dot" />
      </template>
    </vue-slick-carousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: this.hasArrows,
        dots: this.hasDots,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    }
  },
  props: {
    hasArrows: {
      type: Boolean,
      default: false
    },
    hasDots: {
      type: Boolean,
      default: false
    },
    isBright: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-dot {
  @apply border-solid border-cyan-900 border-[3px] w-2 h-2 rounded-full opacity-50 transition duration-[300ms];
}
.slick-active .custom-dot {
  @apply border-solid border-cyan-900 bg-cyan-900 border-[3px] w-2 h-2 rounded-full opacity-100;
}
.bright {
  .custom-dot {
    @apply border-red-700;
  }
  .slick-active .custom-dot {
    @apply border-red-700 bg-red-700;
  }
}
</style>

<style lang="scss">
.slick-dots {
  @apply bottom-10;
}
</style>
