module.exports = {
  g2widget: {
    content: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text:
                "Don't just take our word for it. Read what our customers have to say on G2.",
              type: 'text'
            }
          ]
        }
      ]
    },
    ctaText: 'Read Reviews',
    ctaUrl: 'https://www.g2.com/products/nacelle/reviews',
    logo: {
      filename:
        '//images.ctfassets.net/yt02w0x62uoi/6fa3T5CC7O5lSA7LNBRyHn/795670eb8909bb97945b6af2fee82db2/g2-logo-2x.png',
      alt: 'G2 Logo'
    },
    logoWidth: 30,
    component: 'G2Widget'
  },
  legalSection: {
    sections: [
      {
        title: 'Privacy Policy',
        type: 'Link',
        link: {
          cached_url: 'privacy-policy'
        }
      },
      {
        title: 'Terms of Use',
        type: 'Link',
        link: {
          cached_url: 'terms-and-conditions'
        }
      }
    ]
  },
  navSection: [
    {
      description: undefined,
      sections: [
        {
          title: 'For Merchants',
          type: 'Link',
          link: {
            path: 'headless-commerce-for-merchants'
          }
        },
        {
          title: 'For Developers',
          type: 'Link',
          link: {
            cached_url: 'jamstack-ecommerce-for-developers'
          }
        },
        {
          title: 'For Agencies',
          type: 'Link',
          link: {
            cached_url: 'headless-commerce-for-agencies'
          }
        }
      ],
      title: 'Customers'
    },
    {
      description: undefined,
      sections: [
        {
          title: 'Careers',
          type: 'Link',
          link: {
            cached_url: 'careers'
          }
        },
        {
          title: 'Partners',
          type: 'Link',
          link: {
            cached_url: 'become-a-partner'
          }
        },
        {
          title: 'Contact',
          type: 'Link',
          link: {
            cached_url: 'contact-us'
          }
        }
      ],
      title: 'Company'
    },
    {
      description: undefined,
      sections: [
        {
          title: 'Guides & Webinars',
          type: 'Link',
          link: {
            cached_url: 'guides-and-webinars'
          }
        },
        {
          title: 'Blog',
          type: 'Link',
          link: {
            cached_url: 'blog'
          }
        },
        {
          title: 'FAQs',
          type: 'Link',
          link: {
            cached_url: 'faqs'
          }
        }
      ],
      title: 'Resources'
    }
  ]
}
