<template>
  <section class="max-w-7xl mx-auto mt-12 lg:flex lg:w-11/12 lg:mt-0">
    <div class="col-left text-white lg:flex-1">
      <div class="col-left__inner w-11/12 mx-auto">
        <h1 class="page-title mb-12 lg:mt-0 lg:mb-7">
          <span class="block mb-5">{{ title }}</span>
          {{ secondTitle }}
        </h1>
        <div
          v-if="content"
          :class="hideContentOnMobile ? 'hidden lg:block' : 'block'"
          class="sub-heading"
        >
          <rich-text-renderer :document="content" />
        </div>
        <ul
          v-if="partners.length > 0"
          class="list-none p-0 m-0 hidden lg:flex lg:flex-wrap lg:justify-around"
        >
          <li v-for="partner in partners" :key="partner.uid" class="m-6">
            <img
              :src="partner.logo.filename"
              :alt="partner.logo.alt"
              class="w-full max-w-[197px] h-auto"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="col-right lg:flex-1">
      <MarketoForm v-bind="form[0]" :title="''" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    secondTitle: {
      type: String,
      default: ''
    },
    hideContentOnMobile: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    },
    partners: {
      type: Array,
      default: () => []
    },
    form: {
      type: Array,
      default: () => []
    }
  }
}
</script>
