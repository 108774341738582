<template>
  <section
    id="case-study-customer-details"
    class="flex flex-col justify-center items-center mb-[100px] px-5 lg:px-0"
  >
    <div class="flex flex-col lg:flex-row max-w-[1170px]">
      <DetailCard
        :location="location"
        :vertical="vertical"
        :logo="logo"
        :websiteUrl="websiteUrl"
      />
      <div class="text-white w-full lg:w-1/2">
        <h6
          class="m-0 p-0 text-white text-[22px] lg:text-4xl tracking-[.64px] leading-[23px] lg:leading-[47px] text-left mx-0 lg:mx-[45px] my-0 font-normal"
        >
          About {{ name }}
        </h6>
        <p
          class="text-white text-left text-base lg:text-lg tracking-[.39px] leading-[26px] mx-0 lg:mx-[45px] my-4 lg:my-[18px]"
        >
          {{ aboutText }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import DetailCard from './comps/CaseStudyCustomerDetailCard.vue'
export default {
  components: {
    DetailCard
  },
  props: {
    location: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    vertical: {
      type: String,
      required: true
    },
    websiteUrl: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    aboutText: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>
