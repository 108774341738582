<template>
  <div class="flex flex-col bg-cyan-900 rounded-md px-11 py-9">
    <template v-if="type === 'icon'">
      <img :src="image.filename" :alt="image.alt" class="max-w-max" />
      <h4 class="font-bold text-lg text-white tracking-normal mt-7 mb-3">
        {{ heading }}
      </h4>
      <p class="text-base text-white tracking-normal m-0">{{ paragraph }}</p>
    </template>
    <template v-if="type === 'number'">
      <h4
        class="font-fold text-[44px] tracking-normal text-white mt-0 mb-5 text-center"
      >
        {{ heading }}
      </h4>
      <p class="m-0 text-white text-2xl tracking-normal text-center">
        {{ paragraph }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      default: () => ({
        filename: '',
        alt: ''
      })
    },
    heading: {
      type: String
    },
    paragraph: {
      type: String
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ['number', 'icon'].indexOf(value) !== -1
      }
    }
  }
}
</script>
