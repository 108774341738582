<template>
  <div class="phone">
    <input
      type="phone"
      :placeholder="label"
      @input="$emit('inputchange', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style></style>
