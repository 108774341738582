<template>
  <div>
    <a
      :href="host.socialLink + videoHostHref"
      class="video-series-host w-full w-[202px] no-underline flex flex-col justify-center"
    >
      <img
        :src="host.imageUrl"
        :alt="`${host.name}, ${host.title}`"
        class="rounded-[5px] overflow-hidden w-[170px] h-[170px]"
      />
      <div class="max-w-[170px]">
        <p class="mx-0 mb-[5px] text-base">{{ host.name }}</p>
        <p class="m-0 text-[20px] font-bold">{{ host.title }}</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    host: {
      type: Object,
      required: true,
      default: () => ({
        name: '',
        title: '',
        imageUrl: '',
        socialLink: ''
      })
    }
  },
  computed: {
    videoHostHref() {
      return '#' + this.host.name.toLowerCase().split(' ').join('-') || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.video-series-host {
  img {
    border: 1px solid transparent;
  }
  &:hover {
    & img {
      border-color: white;
    }
  }
}
</style>
