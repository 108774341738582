<template>
  <ul class="list-none text-[#FAFAFA] flex justify-between flex-grow mx-[108px]">
    <NavItem
      v-for="({ label, linkList }, i) in navItems"
      :key="`nav-item-${i}`"
      :label="label"
      :linkList="linkList"
    />
  </ul>
</template>

<script>
import NavItem from './NavItem.vue'
import { useGlobalStore } from '~/store/global'
import { mapStores } from "pinia"

export default {
  components: {
    NavItem
  },
  props: {
      navItems: {
          type: Array
      }
  }
}
</script>
