<template>
  <BaseSection padding="px-20 pt-40 pb-24" :bg-color="bgColor">
    <div
      class="bg-cyan-900 rounded-[34px] flex flex-row items-center justify-between shadow-xl py-12 px-[86px]"
    >
      <div class="w-1/3">
        <SubHeading color="text-white" class="mb-5">{{ header }}</SubHeading>
        <Paragraph color="text-[#DEDEDE]">{{ copy }}</Paragraph>
        <Cta v-if="cta.length" :cta="cta[0]" />
      </div>
      <div class="w-1/3 h-full relative">
        <img :src="image.filename" :alt="image.alt" class="absolute -top-80" />
      </div>
      <div class="w-1/3">
        <NumbersRow :data="numbersRowData" />
      </div>
    </div>
  </BaseSection>
</template>

<script>
import Cta from '~/components/V2/Cta/Cta'
import NumbersRow from '~/components/V2/NumbersRow/NumbersRow'
import BaseSection from '~/components/V2/Base/BaseSection/BaseSection'
export default {
  components: {
    Cta,
    NumbersRow,
    BaseSection
  },
  props: {
    header: {
      type: String
    },
    copy: {
      type: String
    },
    cta: {
      type: Array,
      default: () => []
    },
    image: {
      type: Object
    },
    firstRowNumber: {
      type: String
    },
    secondRowNumber: {
      type: String
    },
    thirdRowNumber: {
      type: String
    },
    firstRowText: {
      type: String
    },
    secondRowText: {
      type: String
    },
    thirdRowText: {
      type: String
    },
    backgroundColor: {
      type: String,
      default: 'white',
      validator: (value) => {
        return ['white', 'lightGray', 'darkGray'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    numbersRowData() {
      return [
        { number: this.firstRowNumber, text: this.firstRowText },
        { number: this.secondRowNumber, text: this.secondRowText },
        { number: this.thirdRowNumber, text: this.thirdRowText }
      ]
    },
    bgColor() {
      if (this.backgroundColor === 'lightGray') return 'bg-gray-100'
      if (this.backgroundColor === 'darkGray') return 'bg-[#CBCCD6]'
      return 'bg-white'
    }
  }
}
</script>

<style></style>
