<template>
  <div class="flex flex-col items-center">
    <div
      class="w-full max-w-[900px] m-5 lg:m-0 flex items-center justify-center flex-col"
    >
      <div
        class="my-0 mx-5 lg:mx-0 w-[90%] lg:w-full max-w-[583px] lg:max-w-full"
      >
        <nuxt-link
          class="back h-[26px] w-[141px] text-cyan-500 text-lg no-underline font-medium leading-tight tracking-wide"
          to="/blog"
          >&lt; Back</nuxt-link
        >
        <h1 class="mb-0">{{ title }}</h1>
        <div
          class="flex flex-col mb-10 lg:mb-0 items-start lg:flex-row lg:items-center justify-start"
        >
          <h6 class="mb-0 mt-9 lg:mb-9">{{ category }}</h6>
          <div
            class="hidden lg:block h-[6px] w-[6px] rounded-md bg-white my-0 mx-4"
          ></div>
          <p class="text-base">{{ readableDate }}</p>
          <div class="social-sharing ml-0 flex lg:ml-auto mr-0 text-white">
            <p class="text-base m-0">Share on:</p>
            <ShareNetwork
              network="facebook"
              :url="`https://${hostname}/blog/${slug}/`"
              :title="title"
            >
              <img src="/iconmonstr-facebook-1.svg" alt />
            </ShareNetwork>

            <ShareNetwork
              network="twitter"
              :url="`https://${hostname}/blog/${slug}/`"
              :title="title"
            >
              <img class="twitter-image" src="/iconmonstr-twitter-1.svg" alt />
            </ShareNetwork>

            <ShareNetwork
              network="linkedIn"
              :url="`https://${hostname}/blog/${slug}/`"
              :title="title"
            >
              <img src="/iconmonstr-linkedin-1.svg" alt />
            </ShareNetwork>

            <ShareNetwork
              network="email"
              :url="`https://${hostname}/blog/${slug}/`"
              :title="title"
            >
              <img class="email-image" src="/iconmonstr-email-1.svg" alt />
            </ShareNetwork>
          </div>
        </div>
      </div>
      <img
        class="max-w-[900px] my-0 mx-auto flex justify-center"
        v-if="image.filename"
        :src="image.filename"
        :alt="intro || image.alt"
      />
    </div>
    <div class="post-body mt-[57px] relative w-full">
      <rich-text-renderer v-if="content" :document="content" />
    </div>
    <div
      class="min-h-[70px] bg-white w-full flex justify-center relative"
      :class="authorBio ? 'has-bio' : 'no-bio'"
    >
      <div
        class="author flex w-[583px] pt-8 mt-0 mx-4 mb-20 border-solid border-0 border-t border-cyan-500"
        v-if="authorName"
      >
        <img
          v-if="authorPhoto.filename"
          :src="authorPhoto.filename"
          :alt="authorPhoto.alt"
          class="w-[65px] h-[65px] mr-5 rounded-full"
        />
        <div>
          <p class="name text-black text-sm leading-relaxed">
            {{ authorName }}
          </p>
          <p class="title text-black text-sm leading-relaxed">
            {{ authorTitle }}
          </p>
          <p class="bio text-black text-sm leading-relaxed" v-if="authorBio">
            {{ authorBio }}
          </p>
        </div>
      </div>
    </div>
    <cta
      title="Stay in the know. Get tips & tricks straight to your inbox."
      emailLinkText="Sign Me Up"
      :emailLinkPath="{ cached_url: '/subscribe' }"
      override-split
      class="!mt-[-70px] lg:w-[97%]"
    />

    <storyblok-resolver
      v-if="NewsletterSubscribe.length"
      :block="NewsletterSubscribe[0]"
    />
    <banner v-if="Banner.length" v-bind="Banner[0]" />
  </div>
</template>

<script>
import Cta from '~/components/Cta/Cta'
import Banner from '~/components/Banner/Banner'
import StoryblokResolver from '~/components/StoryblokResolver'
import { convertDateTimeToUserTimezone } from '~/utils/dateTime'

export default {
  components: {
    Cta,
    Banner,
    StoryblokResolver
  },
  props: {
    authorName: {
      type: String,
      default: ''
    },
    authorTitle: {
      type: String,
      default: ''
    },
    authorBio: {
      type: String,
      default: ''
    },
    authorPhoto: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: () => ({})
    },
    intro: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      required: true
    },
    firstPublishedAt: {
      type: String,
      default: ''
    },
    NewsletterSubscribe: {
      type: Array,
      default: () => []
    },
    Banner: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hostname() {
      return process.env.BASE_URL
    },
    readableDate() {
      if (this.firstPublishedAt) {
        return convertDateTimeToUserTimezone(
          this.firstPublishedAt,
          'MMMM do, yyyy'
        )
      } else {
        return 'Draft'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.social-sharing {
  a {
    margin: 0 7px;
    display: flex;
    align-items: flex-end;
  }
  img {
    filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
      brightness(102%) contrast(104%); //Makes color #00BAFF (Blue)
  }
}

.email-image {
  position: relative;
  bottom: -2px;
}

.twitter-image {
  position: relative;
  bottom: -1px;
}

img {
  width: 100%;
}

.post-body {
  &::before {
    content: '';
    height: calc(100% + 350px);
    width: 100%;
    position: absolute;
    top: -300px;
    left: 0;
    z-index: -1;
    background-color: #fff;
    clip-path: polygon(0 200px, 100% 0, 100% 100%, 0 100%);
  }
  /deep/ {
    div:first-child {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      align-items: center;
      justify-content: center;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    p {
      color: #010204;
      max-width: 583px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    .rich-text-image-wrapper {
      width: 100%;
      max-width: 768px;
      margin: 0 auto;
    }
    blockquote {
      max-width: 583px;
    }
    blockquote p {
      color: #00baff;
      font-size: 26px;
      font-style: italic;
      letter-spacing: 0.81px;
      line-height: 36px;
      margin: 1rem;
      width: 100%;
      @include touch {
        font-size: 20px;
        line-height: 28px;
      }
    }
    a {
      color: #c4103b;
    }
    hr {
      max-width: 583px;
      width: 100%;
      border: none;
      border-top: 1px solid #00baff;
    }
    .blog-post-caption {
      font-size: 12px;
      font-style: italic;
    }
  }
}

h6 {
  color: #00baff;
}

.no-bio {
  .author {
    align-items: center;
  }
}

.has-bio {
  .author {
    img {
      position: relative;
      top: 20px;
      right: 5px;
    }
    .name {
      font-weight: bold;
      margin-bottom: 0;
    }
    .title {
      margin: 0;
    }
    .bio {
      font-size: 12px !important;
      margin-top: 6px;
    }
    @include touch {
      flex-direction: column;
      img {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
}

@include touch {
  .post-body {
    /deep/ {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        width: 90%;
      }
      hr {
        margin-left: 10px;
        margin-right: 10px;
        width: 96%;
      }

      ul {
        width: auto !important;
      }
    }
    &::before {
      top: -100px;
      height: calc(100% + 100px);
      clip-path: none;
    }
  }
}

/deep/ .cta-container {
  margin-top: -70px;
}
</style>
