var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"font-medium rounded-md flex items-center hover:cursor-pointer",class:{
    'py-[6px] px-3 text-xs h-[28px]': _vm.size === 'small',
    'py-[10px] px-4 text-sm h-[40px]': _vm.size === 'medium',
    'py-[12px] px-8 text-lg h-[52px]': _vm.size === 'large',
    'bg-red-700 text-white border-none hover:bg-red-900':
      _vm.variant === 'primary',
    'bg-[#DFDFDF] text-cyan-900 border-none hover:bg-[#848484]':
      _vm.variant === 'secondary',
    'bg-cyan-900 text-white border-solid border-cyan-900 hover:text-cyan-900 hover:bg-white':
      _vm.variant === 'primaryDark',
    'bg-[#848484] text-white border-none hover:text-cyan-900 hover:bg-[#E0E0E0]':
      _vm.variant === 'secondaryDark'
  },on:{"click":_vm.handleClick}},[_vm._v("\n  "+_vm._s(_vm.label)+"\n")])}
var staticRenderFns = []

export { render, staticRenderFns }