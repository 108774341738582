<template>
  <section>
    <client-only>
      <!-- this component will only be rendered on client-side -->
      <ABTestVariation
        v-for="variation in showVariations"
        :key="variation._uid"
        v-bind="variation"
        :experiment-name="experimentName"
      />

      <!-- placeholder, rendered on server-side, need this for consistency when being indexed by search engines -->
      <template #placeholder>
        <ABTestVariation
          v-bind="variations[0]"
          :experiment-name="experimentName"
          :is-server="true"
        />
      </template>
    </client-only>
  </section>
</template>

<script>
import ABTestVariation from './ABTestVariation.vue'
export default {
  components: {
    ABTestVariation
  },
  props: {
    variations: {
      type: Array,
      default: () => []
    },
    experimentName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedVariation: null
    }
  },
  computed: {
    isStoryblok() {
      return this.$route.query._storyblok
    },
    showVariations() {
      if (this.isStoryblok) {
        return this.variations.filter((variation) => variation.showInEditor)
      } else {
        if (!this.selectedVariation) {
          return []
        }
        return this.variations.filter(
          (variation) => variation._uid === this.selectedVariation._uid
        )
      }
    }
  },
  mounted() {
    if (!this.isStoryblok) {
      this.selectVariation()
    }
  },
  methods: {
    selectVariation() {
      const expCookie = this.$cookies.get(this.experimentName)
      if (expCookie) {
        this.selectedVariation = this.variations.find(
          (variation) => variation.name === expCookie
        )
        return
      }

      const trafficPercentageArr = this.variations.map(
        (variation) => Number(variation.trafficPercentage) / 100
      )

      const totalPercentage = trafficPercentageArr.reduce(
        (partialSum, a) => partialSum + a,
        0
      )

      if (totalPercentage !== 1) {
        this.$sentry.captureException(
          new Error('Total percentage does not equal 100')
        )
        const maxPercentage = Math.max(...trafficPercentageArr)
        this.selectedVariation = this.variations.filter(
          (variation) =>
            Number(variation.trafficPercentage) / 100 === maxPercentage
        )[0]
        return
      }

      const randomWeightedIndex = this.getWeightedRandom(trafficPercentageArr)

      this.selectedVariation = this.variations[randomWeightedIndex]
    },

    getWeightedRandom(arr) {
      let sum = 0
      const r = Math.random()
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i]
        if (r <= sum) return i
      }
    }
  }
}
</script>
