import { createClient } from '@urql/core'

const isPreview =
  process.env.VERCEL_ENV === 'preview' ||
  process.env.NUXT_ENV_VERCEL_ENV === 'preview' ||
  process.env.NODE_ENV === 'development' ||
  process.env.PREVIEW === 'true'

const storyblokToken = isPreview
  ? process.env.STORYBLOK_PREVIEW_KEY
  : process.env.STORYBLOK_KEY

const version = isPreview ? 'draft' : 'published'

const client = createClient({
  url: 'https://gapi.storyblok.com/v1/api',
  fetchOptions: () => {
    return {
      headers: { token: storyblokToken, version }
    }
  }
})

const query = async (query, variables) => {
  const { data, error } = await client.query(query, variables).toPromise()
  return data || error
}

export default ({ app }, inject) => {
  inject('query', query)
}
