<template>
  <div class="banner">
    <transition name="slide">
      <div
        class="banner-container fixed bottom-0 left-0 w-screen h-28 bg-cyan-500 flex flex-col justify-center items-center z-[99] lg:flex-row lg:h-20"
        v-if="open"
        @mouseleave="leftBanner"
      >
        <p
          class="font-bold text-center mx-5 mt-0 mb-2 lg:mb-0 lg:ml-0 lg:mr-14"
        >
          {{ message }}
        </p>
        <nacelle-button
          class="nacelle-banner-button ml-5 lg:ml-0"
          @click="
            goTo(
              $formRoute({
                url: buttonPath.cached_url,
                tracking: buttonTracking
              })
            )
          "
          color="secondary"
          >{{ buttonText }}</nacelle-button
        >
        <img
          @click="closeBanner"
          class="close-banner cursor-pointer h-4 absolute filter brightness-0 invert right-5 bottom-5 lg:right-10 lg:bottom-[unset]"
          src="/alertbar-x-icon.svg"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import NacelleButton from '~/components/NacelleButton/NacelleButton'
import { mapState, mapActions } from 'pinia'
import { useBannerStore } from '~/store/banner'
export default {
  components: {
    NacelleButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonPath: {
      type: Object,
      default: () => ({
        cached_url: ''
      })
    },
    buttonTracking: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    tracking: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(useBannerStore, ['open'])
  },
  mounted() {
    setTimeout(() => {
      this.setOpen(true)
    }, 1000)
  },
  methods: {
    ...mapActions(useBannerStore, ['setOpen']),
    goTo(path) {
      if (this.tracking) {
        this.$track({
          name: 'Banner Clicked',
          category: 'cta',
          label: this.title
        })
      }
      if (path.includes('http')) {
        window.open(path, '_blank')
      } else {
        this.$router.push(path)
      }
    },
    closeBanner() {
      if (this.tracking) {
        this.$track({
          name: 'Banner Closed',
          category: 'cta',
          label: this.title
        })
      }
      this.setOpen(false)
    },
    leftBanner() {
      if (this.tracking) {
        this.$track({
          name: 'Banner Hovered',
          category: 'cta',
          label: this.title
        })
      }
    }
  }
}
</script>
