<template>
  <div>
    <rich-text-renderer v-if="text.content" :document="text.content" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: Object,
      required: true
    }
  }
}
</script>
