<template>
  <section
    id="video-series-hosts"
    class="flex justify-center min-h-[150px] pb-12"
    :class="backgroundColor !== '' ? `bg-[${backgroundColor}]` : `bg-[#0e1328]`"
  >
    <div class="max-w-[1200px] p-4 pt-0">
      <div
        class="flex flex-col items-center justify-center max-w-[770px] my-[50px] mx-auto"
      >
        <h2
          id="video-series-hosts-title"
          class="w-full m-4 p-0 text-4xl font-normal leading-[47px] text-center"
        >
          {{ title }}
        </h2>
        <p
          id="video-series-hosts-text"
          class="m-0 pt-0 text-xl text-center leading-[30px] tracking-normal"
        >
          {{ text }}
        </p>
      </div>
      <div
        class="grid 2xs:grid-cols-1 xs:grid-cols-2 gap-y-20 md:grid-cols-3 md:gap-0 md:gap-y-20 lg:gap-20 xl:gap-[114px] justify-items-center"
        :class="`lg:grid-cols-${hosts.length}`"
      >
        <VideoSeriesHost
          v-for="(host, i) in hosts"
          :key="`video-series-host-${i}`"
          :host="host"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VideoSeriesHost from './comps/VideoSeriesHost.vue'
export default {
  components: {
    VideoSeriesHost
  },
  props: {
    title: {
      type: String,
      default: 'Get Inside Their Heads'
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: `Meet the all-star lineup of eCommerce experts as they share their unique
      view on headless commerce best practices, trends, predictions, technology,
      and more. And stay tuned as we continue to add more conversations and
      insights.`
    },
    hosts: {
      type: Array,
      default: () => []
    }
  }
}
</script>
