<template>
  <div
    class="text-white text-[22px] tracking-[.5px] leading-[30px] flex flex-col md:flex-row items-center justify-center md:justify-start mt-4 mb-12 w-full"
    v-if="showFilter || showSearch"
  >
    <div
      class="mr-[30px] relative md:mb-8 md:mr-0 md:w-[calc(100%-2rem)]"
      v-if="showSearch"
    >
      <input
        type="text"
        class="bg-[rgba(216,216,216,0.15)] py-[.8rem] px-[1rem] border border-solid border-[#00baff] rounded-[21.5px] min-w-[450px] text-base text-white md:mr-0 md:min-w-[calc(100%-2rem)] w-[calc(100%-2rem)]"
        placeholder="Search"
        @input="selectFilter"
        name="searchFilter"
      />
      <img
        src="/nacelle-search-icon.svg"
        class="absolute top-[50%] right-[25px] transform translate-y-[-50%]"
      />
    </div>
    <div
      class="flex flex-col justify-center items-center md:flex-row"
      v-if="showFilter"
    >
      <span class="">Show me</span>
      <select
        v-if="typeFilters.length"
        name="typeFilter"
        class="video-filter-select focus:ring-2 focus:ring-white focus:rounded"
        @change="selectFilter"
        aria-label="Filter videos by type"
      >
        <option value="All Things">Type</option>
        <option v-for="filter in typeFilters" :key="filter" :value="filter">
          {{ filter }}
        </option>
      </select>
      <select
        v-if="topicFilters.length"
        name="topicFilter"
        class="video-filter-select"
        @change="selectFilter"
      >
        <option value="All Things">Topic</option>
        <option v-for="filter in topicFilters" :key="filter" :value="filter">
          {{ filter }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showFilter: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: false },
    topicFilters: { type: Array, default: () => [] },
    typeFilters: { type: Array, default: () => [] }
  },
  methods: {
    selectFilter(event) {
      const name = event.target.name
      this.$emit('filter-change', { type: name, value: event.target.value })
    }
  }
}
</script>

<style lang="scss" scoped>
.video-filter-select {
  width: auto;
  height: 100%;
  min-width: 180px;
  font-size: 22px;
  font-weight: 400;
  margin: 0 0.5rem;
  letter-spacing: 0.39px;
  line-height: 28px;
  cursor: pointer;
  border-radius: 0;
  background-color: #001c27;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #00baff;
  color: #00baff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/nacelle-chevron-down.svg);
  background-repeat: no-repeat;
  background-position: 95%;
  padding: 10px 35px 10px 10px;
  @media (max-width: 768px) {
    margin: 1rem 0;
    width: calc(100% - 2rem);
  }
}
</style>
