<template>
  <div class="docs-wrapper h-full min-h-screen w-full min-w-screen bg-white">
    <a href="#docs-main" class="sr-only">Skip to main content</a>
    <docs-header />
    <docs-sidebar />
    <main id="docs-main" class="docs-main pt-20 flex" :inert="checkForInert">
      <div class="sidebar-buffer hidden w-72 lg:block" />
      <nuxt />
    </main>
    <docs-feedback />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useDocsStore } from '~/store/docs'
import DocsFeedback from '~/components/Docs/DocsFeedback/DocsFeedback'
import DocsHeader from '~/components/Docs/DocsHeader/DocsHeader'

import DocsSidebar from '~/components/Docs/DocsHeader/DocsSidebar'

export default {
  components: {
    DocsFeedback,
    DocsHeader,
    DocsSidebar
  },
  computed: {
    ...mapState(useDocsStore, ['sidebarOpen']),
    checkForInert() {
      return this.sidebarOpen && window.innerWidth < 1024
    }
  }
}
</script>
