import { defineStore } from 'pinia'

export const useGdprStore = defineStore('gdpr', {
  state: () => ({
    pageHasGdprOpen: false,
    isInEu: false
  }),
  actions: {
    euChecker(inEu) {
      this.isInEu = inEu
    },
    isGdprOpen(isGdprOpen) {
      this.pageHasGdprOpen = isGdprOpen
    },
    isUserInEU(isUserInEU) {
      this.isInEu = isUserInEU
    }
  }
})