<template>
  <div class="cta-container flex justify-center mt-28 w-full mb-20">
    <div
      class="cta w-full max-w-7xl flex relative rounded-none bg-cyan-500 text-white p-3 mb-14 lg:mt-10 lg:mx-10 lg:mb-28 lg:p-14 lg:pb-14 lg:rounded-[5px]"
    >
      <div
        v-if="overrideSplit"
        class="double-cta py-8 w-full flex justify-center items-center flex-col z-[2] lg:mb-0"
      >
        <h3
          class="text-3xl font-bold tracking-wide leading-relaxed lg:text-6xl lg:leading-snug m-0 text-center"
        >
          {{ title }}
        </h3>
        <p v-if="subtitle" class="text-xl font-medium text-center">
          {{ subtitle }}
        </p>
        <div
          class="cta-links flex justify-center content-center items-center flex-col w-full m-0 lg:flex-row lg:max-w-lg lg:mt-9"
          :class="{ 'cta-links-form': emailLinkText }"
        >
          <nacelle-button
            v-if="buttonText"
            class="cta-button"
            @click="
              goTo(
                $formRoute({
                  url: buttonPath.cached_url
                })
              )
            "
            color="secondary"
            :no-icon="noIcon"
          >
            {{ buttonText }}
          </nacelle-button>
          <text-link
            v-if="linkText && linkPath.cached_url"
            :linkPath="linkPath.cached_url"
            color="secondary"
          >
            {{ linkText }}
          </text-link>
          <simple-email-link
            v-if="emailLinkText && emailLinkPath.cached_url"
            class="simple-email-link mt-9 mx-auto mb-0 w-full max-w-lg lg:mt-0 lg:mb-0 lg:w-auto"
            :linkText="emailLinkText"
            :linkPath="emailLinkPath.cached_url"
          >
            {{ emailLinkText }}
          </simple-email-link>
          <marketo-form
            class="cta-marketo-form"
            v-if="!isEmpty(marketoFormProps)"
            v-bind="marketoFormProps"
          />
        </div>
      </div>
      <div
        v-else
        class="single-cta flex justify-between items-center content-center w-full flex-col py-8 z-[2] lg:flex-row lg:py-0"
      >
        <h3
          class="my-5 text-3xl font-bold tracking-wide leading-relaxed lg:text-6xl lg:my-0 w-1/2"
        >
          {{ title }}
        </h3>
        <nacelle-button
          @click="
            goTo(
              $formRoute({
                url: buttonPath.cached_url
              })
            )
          "
          color="secondary"
          :no-icon="noIcon"
          >{{ buttonText }}</nacelle-button
        >
      </div>
      <div class="cta-slant-gradiant"></div>
    </div>
  </div>
</template>

<script>
import NacelleButton from '~/components/NacelleButton/NacelleButton'
import TextLink from '~/components/TextLink/TextLink'
import SimpleEmailLink from '~/components/SimpleEmailLink'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonPath: {
      type: Object,
      default: () => ({
        cached_url: ''
      })
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    linkText: {
      type: String,
      default: ''
    },
    linkPath: {
      type: Object,
      default: () => ({
        cached_url: ''
      })
    },
    emailLinkText: {
      type: String,
      default: ''
    },
    emailLinkPath: {
      type: Object,
      default: () => ({
        cached_url: ''
      })
    },
    overrideSplit: {
      type: Boolean,
      default: false
    },
    marketoFormProps: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    NacelleButton,
    TextLink,
    SimpleEmailLink
  },
  methods: {
    goTo(path) {
      if (path.includes('http')) {
        window.open(path, '_blank')
      } else {
        this.$router.push(path)
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.cta {
  .double-cta {
    .cta-button {
      margin-right: 50px;
      @include touch {
        margin: 30px 0;
      }
    }
  }
}
.cta-slant-gradiant {
  height: 134px;
  width: 100%;
  position: absolute;
  bottom: -74px; // taken from height => leaves 60px out of box
  left: 0;
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(to left top, #22346b, #00baff 70%);
  clip-path: polygon(
    0 0,
    100% 60px,
    100% 60px,
    0 100%
  ); // remaining 60px used here
  @include touch {
    border-radius: 0px 0px 0px 0px;
  }
}
.cta-links-form,
.cta-marketo-form {
  @include touch {
    width: 100% !important;
  }
}
.simple-email-link {
  /deep/ {
    input {
      border: 1px solid #ffffff !important;
      background-color: #d8d8d83f !important;
      &::placeholder {
        color: #fff;
      }
    }
    button {
      background-color: #ffffff !important;
      border: 1px solid #ffffff !important;
      border-left: 0 !important;
      color: #00baff;

      &:hover,
      &:focus {
        background-color: #22346b !important;
        color: #ffffff;
      }
    }
  }
}

/deep/ .cta-marketo-form .simple-form .mktoForm {
  input {
    border: 1px solid #fff !important;
    background-color: #d8d8d83f !important;
  }
  .mktoButtonWrap.mktoDownloadButton button.mktoButton {
    background-color: #fff !important;
    border: 1px solid #ffffff !important;
    border-left: 0 !important;
    color: #00baff;

    &:hover,
    &:focus {
      background-color: #22346b !important;
      color: #ffffff;
    }
  }
}
</style>
