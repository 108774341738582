<template>
  <h2 class="m-0 p-0 text-[44px] leading-[52px] font-bold" :class="[color]">
    <slot />
  </h2>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-cyan-900'
    }
  }
}
</script>
