<template>
  <li>
    <h2
      class="text-gray-500 uppercase font-bold text-sm"
      v-if="$scopedSlots.title"
    >
      <slot name="title" />
    </h2>

    <template v-for="menu in nodes.submenus">
      <div v-if="menu.submenus && menu.submenus.length" :key="menu.id">
        <DocsNavigationSubmenu
          :menu="menu"
          :depth="depth + 1"
          :is-parent-open="isParentOpen"
        />
      </div>

      <nuxt-link
        class="py-2 px-0"
        :id="menu.id"
        :to="'/' + menu.slug"
        @click.native="closeMainMenu"
        v-if="!menu.submenus"
        :key="menu.id"
        >{{ menu.name }}</nuxt-link
      >
    </template>
  </li>
</template>

<script>
import DocsNavigationSubmenu from '~/components/DocsNavigation/DocsNavigationSubmenu'
import { mapActions, mapState } from 'pinia'
import { useDocsStore } from '~/store/docs'

export default {
  name: 'DocsNavigationMenuItem',
  components: {
    DocsNavigationSubmenu
  },
  props: {
    nodes: {
      type: Object,
      required: true
    },
    depth: {
      type: Number,
      required: true
    },
    isParentOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useDocsStore, ['getNav'])
  },
  watch: {
    getNav: function (newNav, oldNav) {
      this.$forceUpdate()
    }
  },
  methods: {
    ...mapActions(useDocsStore, ['setSidebarOpen']),

    closeMainMenu() {
      // closes the main sidebar nav when a selection is made on mobile
      if (window.innerWidth <= 768) {
        this.setSidebarOpen(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/*-- These styles are applied here because doing so with Tailwinds @apply and just applying a class does not work as expected with this being a recursive component --*/
button,
a {
  font-size: 14px;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  color: #6b6f71;
  display: block;

  &:focus-visible {
    border-radius: 5px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 186, 255, var(--tw-ring-opacity));
  }
}

.fade-enter-active {
  transition: 150ms ease-in;
}

.fade-leave-active {
  transition: 25ms;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-5%);
}

.nuxt-link-active {
  color: rgba(197, 16, 59, 1);
  font-weight: bold;
}
</style>
