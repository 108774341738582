<template>
  <div
    class="w-full h-full flex items-center justify-center"
    :class="{
      'transform rotate-180': isOpen
    }"
    v-on="$listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20px"
      height="14px"
      viewBox="0 0 10 7"
      version="1.1"
    >
      <title>noun_down chevron_3079137</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          transform="translate(-376.000000, -426.000000)"
          :fill="fillColor"
          fill-rule="nonzero"
        >
          <g transform="translate(376.000000, 426.000000)">
            <path
              d="M0.146153991,0.699539922 L0.146153991,0.699539922 C-0.0487180088,0.920383657 -0.0487180088,1.27791836 0.146153991,1.49820028 L4.27793649,6.16872783 C4.66818,6.61042076 5.301264,6.61042076 5.691507,6.16872783 L9.85377,1.46431117 C10.046643,1.24572017 10.0491415,0.892707281 9.8587665,0.671296273 C9.6643945,0.445368898 9.344105,0.442543443 9.146735,0.665083543 L5.3382395,4.97074002 C5.142868,5.19158376 4.826576,5.19158376 4.6312045,4.97074002 L0.852689492,0.699539922 C0.657817492,0.478690731 0.341525492,0.478690731 0.146153991,0.699539922"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean
    }
  },
  computed: {
    fillColor() {
      return this.isOpen ? '#ffffff' : '#01FFFF'
    }
  }
}
</script>

<style></style>
