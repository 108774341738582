<template>
  <div
    class="video-container flex relative mx-auto w-full max-w-[1080px]"
    :class="containerClass"
    @click="toggleState"
  >
    <video ref="video" class="mx-auto w-full max-w-[1080px]">
      <source :src="webinarVideo.filename" type="video/mp4" />
    </video>
    <img
      class="max-h-[80px] max-w-[80px] absolute cursor-pointer transition-opacity duration-300 ease-in-out"
      src="/playbutton-nacelle.svg"
    />
  </div>
</template>

<script>
export default {
  props: {
    webinarVideo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isPlaying: false
    }
  },
  computed: {
    containerClass() {
      return this.isPlaying ? 'playing' : 'paused'
    }
  },
  methods: {
    toggleState() {
      if (this.isPlaying) {
        this.$refs.video.pause()
      } else {
        this.$refs.video.play()
      }
      this.isPlaying = !this.isPlaying
    }
  }
}
</script>

<style scoped lang="scss">
.video-container {
  z-index: 1;
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #080d1e;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  img {
    z-index: 999;
    left: calc(100% / 2 - 38px);
    top: calc(100% / 2 - 44px);
  }
}

.video-container.paused {
  &::after {
    opacity: 0.4;
  }
}

.video-container.playing {
  img {
    opacity: 0;
  }
}
</style>
