export default (block) => {
  if (block.form.length) {
    // current requirement is that ActionHero can not have more than one form, so we are only checking the first item in an array
    switch (block.form[0].component) {
      case 'MarketoForm':
        block.marketoFormProps = block.form[0]
        break
      case 'SimpleEmailLink':
        block.simpleEmailLinkProps = block.form[0]
        break
      case 'SiteForm':
        block.siteForm = block.form[0]
        break
    }
  }

  if (block.video.filename) {
    block.videoSrc = block.video.filename
  }

  if (block.backgroundImage.filename) {
    block.backgroundImageSrc = block.backgroundImage.filename
  }

  if (block.image.filename) {
    block.imageSrc = block.image.filename
    block.imageAlt = block.image.alt
  }

  return block
}
