<template>
  <span :id="body.id" />
</template>

<script>
export default {
  props: {
    body: {
      type: Object,
      required: true
    }
  }
}
</script>
