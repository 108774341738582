<template>
  <div
    class="border-solid border-0 border-l-4 p-4 my-5"
    :class="`${bgColor} ${borderColor}`"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <component
          :is="iconName"
          class="h-5 w-5"
          :class="iconColor"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 callout-content" :class="textColor">
        <rich-text-renderer v-if="body.text" :document="body.text" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon
} from '@vue-hero-icons/solid'

export default {
  components: {
    ExclamationIcon,
    XCircleIcon,
    CheckCircleIcon,
    InformationCircleIcon
  },
  props: {
    body: {
      type: Object,
      required: true
    }
  },
  computed: {
    bgColor() {
      switch (this.body.type) {
        case 'warning':
          return 'bg-yellow-50'
        case 'success':
          return 'bg-green-50'
        case 'danger':
          return 'bg-red-50'
        case 'info':
          return 'bg-blue-50'
        default:
          return 'bg-white'
      }
    },
    iconColor() {
      switch (this.body.type) {
        case 'warning':
          return 'text-yellow-400'
        case 'success':
          return 'text-green-400'
        case 'danger':
          return 'text-red-500'
        case 'info':
          return 'text-blue-400'
        default:
          return 'text-black'
      }
    },
    borderColor() {
      switch (this.body.type) {
        case 'warning':
          return 'border-yellow-400'
        case 'success':
          return 'border-green-400'
        case 'danger':
          return 'border-red-500'
        case 'info':
          return 'border-blue-400'
        default:
          return 'border-black'
      }
    },
    textColor() {
      switch (this.body.type) {
        case 'warning':
          return 'text-yellow-700'
        case 'success':
          return 'text-green-700'
        case 'danger':
          return 'text-red-700'
        case 'info':
          return 'text-blue-700'
        default:
          return 'text-black'
      }
    },
    iconName() {
      switch (this.body.type) {
        case 'warning':
          return ExclamationIcon
        case 'success':
          return CheckCircleIcon
        case 'danger':
          return XCircleIcon
        case 'info':
          return InformationCircleIcon
        default:
          return InformationCircleIcon
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-content .callout-content div,
.doc-content .callout-content div * {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  color: inherit !important;
}
</style>
