<template>
  <p class="m-0 p-0 text-[18px] leading-7 font-normal" :class="[color]">
      <slot />
  </p>
</template>

<script>
export default {
  props: {
      color: {
          type: String,
          default: 'text-cyan-900'
      }
  }
}
</script>