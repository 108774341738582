<template>
  <div class="bg-white pt-14 md:pt-[88px] px-7">
    <h2 class="text-red-500 text-lg m-0 font-medium text-center">
      {{ title }}
    </h2>
    <div
      class="w-full flex flex-wrap justify-center gap-10 md:gap-12 pt-9 pb-[68px] md:pb-[88px] mx-auto"
    >
      <div v-for="(logo, index) in logos" :key="index">
        <img :src="logo.src" :alt="logo.alt" class="h-12" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    logos: {
      type: Array,
      required: true
    }
  }
}
</script>
