<template>
  <div
    :id="anchorName"
    class="relative video-series-item"
    ref="videoSeriesItem"
  >
    <div
      class="relative border-2 border-solid border-[#00baff] rounded-[10px] p-4 md:pt-[50px] md:pb-[35px] md:px-16 my-16 first:mt-0"
    >
      <div class="text-white flex flex-col md:flex-row">
        <img
          :src="host.imageUrl"
          alt=""
          class="rounded-full h-[70px] w-[70px] select-none"
        />
        <div class="mt-[10px] md:mt-0 md:ml-5">
          <h3
            class="m-0 p-0 text-[20px] md:text-[28px] leading-6 font-bold md:leading-9"
          >
            {{ videoSeriesTitle }}
          </h3>
          <p class="p-0 m-0 text-xs md:text-base" @click="handleSocialClick">
            <span class="author">{{ host.name }}</span
            >, {{ host.title }}
          </p>
        </div>
      </div>
      <p class="text-base">{{ videoSeriesDescription }}</p>

      <button
        class="video-list-expand absolute bottom-[-20px] left-0 right-0 mx-auto z-10 w-[42px] h-[42px] rounded-full flex items-center justify-center border-2 border-solid border-[#00baff] bg-[#001c27] cursor-pointer focus:ring-2 focus:ring-white"
        @click="openVideoItem"
        :aria-label="isOpen ? 'Hide videos' : 'Show videos'"
        :aria-expanded="isOpen.toString()"
        type="button"
      >
        <MoreIcon :is-open="isOpen" />
      </button>

      <!-- Videos  -->
      <slide-up-down
        :active="isOpen"
        class="video-list-dropdown mt-[38px]"
        ref="videoList"
      >
        <div
          class="grid sm:grid-cols-1 md:grid-cols-2 pt-8 max-w-[1068px] gap-[54px]"
        >
          <VideoCard
            v-for="(video, i) in videos"
            :key="`video-card-${keyName}-${i}`"
            :video="video"
          />
        </div>

        <div class="flex justify-center mb-[28px] mt-[68px]">
          <NacelleButton color="video-cta" @click="handleAllAccessClick"
            >Access All Videos</NacelleButton
          >
        </div>
      </slide-up-down>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useFormStore } from '~/store/form'

import MoreIcon from './comps/MoreIcon.vue'
import VideoCard from './comps/VideoSeriesVideoCard.vue'
import NacelleButton from '../NacelleButton/NacelleButton.vue'
import SlideUpDown from 'vue-slide-up-down'

export default {
  components: {
    MoreIcon,
    VideoCard,
    NacelleButton,
    SlideUpDown
  },
  props: {
    videoSeriesTitle: {
      type: String,
      required: true
    },
    videoSeriesDescription: {
      type: String,
      required: true
    },
    host: {
      type: Object,
      required: true
    },
    videos: {
      type: Array,
      default: () => [{}]
    }
  },
  data: () => ({
    isOpen: false,
    maxHeight: 1200
  }),
  computed: {
    anchorName() {
      if (!this.host) return
      return this.host?.name.toLowerCase().split(' ').join('-')
    },
    keyName() {
      return this.anchorName.replace('#', '')
    }
  },
  methods: {
    ...mapActions(useFormStore, ['toggleHeadlessPopup']),
    openVideoItem() {
      this.isOpen = !this.isOpen
    },
    handleAllAccessClick() {
      this.$emit('all-access-popup')
    },
    handleSocialClick() {
      if (this.host.socialLink) {
        window.open(this.host.socialLink)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.author {
  background: linear-gradient(233.09deg, #f56789 9.9%, #c4103b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: underline;
}

.video-series-item {
  &:hover {
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 8px 0px #00baff;
    box-shadow: 0px 0px 8px 0px #00baff;
  }
}
</style>
