<template>
  <div class="reviews mt-6 mx-0 mb-2">
    <div v-if="logo" class="reviews-logo">
      <img
        :src="logo.filename"
        :alt="logo.alt"
        class="g2-logo"
        :style="`width: ${logoWidth}px`"
      />
    </div>
    <div class="reviews-col">
      <rich-text-renderer
        v-if="content"
        :document="content"
        class="reviews-content"
      />
      <BaseButton
        v-if="ctaUrl"
        :button-path="{ cached_url: ctaUrl }"
        label="Read Reviews"
        variant="primary"
        new-tab
        size="medium"
        class="mt-5"
        @click="clickTracker()"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '~/components/V2/Base/BaseButton/BaseButton'

export default {
  components: { BaseButton },
  props: {
    logo: {
      type: Object,
      default: null
    },
    logoWidth: {
      type: String,
      default: null
    },
    content: {
      type: Object,
      default: null
    },
    ctaText: {
      type: String,
      default: null
    },
    ctaUrl: {
      type: String,
      default: null
    }
  },
  methods: {
    clickTracker() {
      window.analytics.track('Footer G2 CTA', {
        category: 'cta',
        label: this.ctaText
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reviews {
  display: block;

  @include tablet {
    display: flex;
  }
}

footer .reviews {
  max-width: 400px;
}

.reviews-logo {
  margin-right: 17px;

  img {
    height: auto;
  }
}

.reviews-col {
  p {
    color: #b4b6c4 !important;
    font-size: 16px !important;
    line-height: 26px !important;
  }
}
</style>
