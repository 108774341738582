import { defineStore } from 'pinia'

export const useDocsStore = defineStore('docs', {
  state: () => ({
    techStack: {
      cms: [],
      deployment: [],
      ecomm: [],
      framework: []
    },
    techStackDefaultValues: {},
    sidebarOpen: false,
    nav: [],
    initNav: [],
    initDocs: [],
    filteredDocs: [],
    version: {},
    versionBannerInfo: {
      packageName: null,
      version: null
    },
    activeDoc: null
  }),
  getters: {
    activeUrl: (state) => {
      return new URL(window.location).toString()
    },
    filteredNav: (state) => {
      const initNav = state.initNav

      if (!state.filteredDocs.length) return initNav

      const filteredIDs = state.filteredDocs.map((doc) => doc.id)

      return initNav.filter(
        (doc) =>
          filteredIDs.includes(doc.id) ||
          doc.isFolder ||
          doc.parentId === 79658270
      )
    },
    getNav: (state) => buildMenu(state.filteredNav),
    techStackKeys: (state) => Object.keys(state.techStack),
    techStackBaseValues: (state) => {
      return Object.entries(state.techStackDefaultValues).reduce(
        (acc, [key, values]) => {
          values.forEach(({ name, value }) => {
            if (!(key in acc)) acc[key] = {}
            acc[key][name] = value
          })

          return acc
        },
        {}
      )
    },
    versionKeys: (state) => Object.keys(state.version),
    currentTechStackKeys: (state) =>
      Object.entries(state.techStack).reduce((acc, [key, values]) => {
        acc = [...acc, ...values]
        return acc
      }, [])
  },
  actions: {
    async getDoc(path) {
      const { DocItem } = await this.$nuxt.$query(
        `query Doc($id: ID!) {
          DocItem(id: $id) {
            id
            content{
              body
            }
          }
        }`,
        { id: path }
      )
      this.activeDoc = DocItem
    },
    getFilteredDocs() {
      const selectedTech = []
      // find which properties of tech stack have length
      Object.entries(this.techStack).forEach(([key, value]) => {
        selectedTech.push({ [key]: value })
      })

      const filtered = []

      this.initDocs.forEach((doc) => {
        let matched = false
        let passFilter = 0 // incremented when no values assigned to a techStack property

        // for every key in techStack
        Object.entries(this.techStack).forEach(([key, value]) => {
          // only check against it if something is set
          if (value.length) {
            // check if the doc has any items set at that key
            if (doc.techStack[key].length) {
              // if it does, cycle through them and check if they match
              doc.techStack[key].forEach((tech) => {
                if (value.includes(tech)) {
                  matched = true
                }
              })
            } else {
              // if the doc doesn't have any items set at that key, increment passFilter
              passFilter++
            }
          } else {
            // and if there are no values set for that key, increment passFilter
            passFilter++
          }
        })

        if (matched || passFilter === Object.keys(this.techStack).length) {
          filtered.push(doc)
        }
      })

      this.filteredDocs = filtered
    },
    setTechStackDefaultValues(data) {
      this.techStackDefaultValues = data
    },
    setSidebarOpen(isOpen) {
      this.sidebarOpen = isOpen
    },
    setInitDocs(data) {
      const docs = data.map((item) => {
        const flattenedTech = []
        return {
          techStack: {
            cms: item.content.cms_service || [],
            deployment: item.content.deployment_service || [],
            ecomm: item.content.ecommerce_service || [],
            framework: item.content.framework || []
          },
          allTechStack: flattenedTech.concat(
            item.content.cms_service,
            item.content.ecommerce_service,
            item.content.deployment_service,
            item.content.framework
          ),
          id: item.id,
          parentId: item.parent_id,
          uuid: item.uuid,
          name: item.name
        }
      })

      this.initDocs = docs
    },
    setNav(data) {
      this.initNav = data
    },
    setStateFromUrl({ query }) {
      const queryArray = Object.entries(query).filter(([key, value]) =>
        allowedKeys.includes(key)
      )
      if (!queryArray.length) return
      const processed = queryArray.reduce((acc, [key, value]) => {
        if (this.techStackKeys.includes(key)) {
          acc.techStack = {
            ...acc.techStack,
            [key]: Array.isArray(value) ? value : [value]
          }
        } else if (this.versionKeys.includes(key)) {
          acc.version = {
            ...acc.version,
            [key]: value
          }
        } else {
          acc[key] = value
        }

        return acc
      }, {})
      this.$patch({
        ...processed
      })
    },
    setVersion(packageName, versionNumber) {
      this.version = {
        ...this.version,
        [packageName]: versionNumber
      }
    },
    setVersionBanner(packageName, version) {
      this.versionBannerInfo.packageName = packageName
      this.versionBannerInfo.version = version
    }
  }
})

const allowedKeys = [
  'framework',
  'ecomm',
  'cms',
  'deployment',
  'activeDoc',

  // releases
  'dashboard',
  'ingestionApi',
  'storefrontApi',
  'storefrontSdk',
  'clientJsSdk',
  'nextStarter',
  'nuxtStarter',
  'shopifyCheckout',
  'reactHooks',
  'legacy',
  'nacelle'
]

function buildMenu(menus) {
  const navTree = []
  if (!Array.isArray(menus)) return []

  // Grab all root level folders
  const root = menus.filter((item) => item.parentId === 79658270)

  // Recursive function to loop through each node item and create & populate a submenus array with child nodes
  const nodeEval = (item) => {
    if (item.isFolder) {
      item.submenus = []

      menus.forEach((subitem) => {
        // Add item to submenus array if it matches the parent ID
        if (item.id === subitem.parentId) {
          item.submenus.push(subitem)

          // Recursive call to check all children to subitem
          nodeEval(subitem)
        }
      })

      // only push to navTree if its a root level item
      if (item.parentId === 79658270) {
        item.title = item.name
        navTree.push(item)
      }
    }
  }

  root.forEach((item) => {
    nodeEval(item)
  })
  return navTree
}
