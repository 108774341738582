<template>
  <div class="w-[270px] flex flex-col items-center my-4 lg:my-5">
    <img
      :src="image.filename"
      :alt="image.alt"
      class="w-[90px] h-[90px] rounded-full mb-4"
    />
    <p class="text-cyan-900 text-base m-0 text-center">{{ name }}</p>
    <p class="text-red-500 text-base m-0 text-center">{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: () => ({
        filename: '',
        alt: ''
      })
    }
  }
}
</script>
