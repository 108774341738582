<template>
  <section class="py-10 lg:pb-14">
    <div
      class="w-full max-w-[1090px] flex flex-col items-start justify-center lg:flex-row mx-auto"
    >
      <div
        class="box-border w-full lg:w-1/2 flex flex-col px-10 lg:pl-10 lg:pr-0"
      >
        <div
          class="lg:mt-6 mb-2 text-[10px] text-red-500 uppercase leading-[13px] tracking-[6%] font-normal"
        >
          {{ category }}
        </div>
        <div
          class="mb-2 text-[28px] sm:text-[47px] font-bold sm:leading-[57px] tracking-[-2%] text-white"
        >
          {{ name }}
          <a :href="websiteUrl.url" target="_blank"
            ><Icon
              class="text-cyan-500"
              file="ExternalLink"
              v-if="isIndustryEvent"
              :alt="`External Link to website for ${name}`"
          /></a>
        </div>
        <Location
          :date="date"
          :time="time"
          :location="location"
          :isPastEvent="isPastEvent"
        />
        <p
          class="mb-8 w-auto text-[19px] font-medium leading-[26px] tracking-[-1%]"
        >
          {{ description }}
        </p>
        <button
          v-if="isIndustryEvent"
          type="button"
          class="w-auto text-white self-start border-none font-semibold text-[14px] leading-6 tracking-[3%] w-auto h-10 rounded-[10px] px-4 py-2 bg-cyan-500 hover:bg-cyan-700 active:bg-cyan-300 cursor-pointer"
        >
          Schedule to Meet Us
        </button>
      </div>
      <div
        :class="{
          'box-border w-full mt-8 mr-0 sm:mx-10 lg:ml-5 lg:mt-0 lg:w-1/2':
            isIndustryEvent,
          'box-border w-full mt-8 px-10 mr-0 lg:px-0 lg:pr-10 lg:ml-5 lg:mt-0 lg:w-1/2':
            !isIndustryEvent
        }"
      >
        <img
          v-if="isIndustryEvent"
          class="w-full sm:max-w-[530px] sm:max-h-[300px] sm:rounded-[10px]"
          :src="image.filename"
          :alt="image.alt"
        />
        <client-only v-else>
          <EventHeroVideo
            v-if="showWebinarVideo"
            :webinar-video="webinarVideo"
          />
          <StoryblokResolver
            v-else
            v-for="block in rightCol"
            :key="block._uid"
            :block="block"
          />
        </client-only>
      </div>
    </div>
  </section>
</template>

<script>
import StoryblokResolver from '../StoryblokResolver.vue'
import Location from './comps/EventHeroLocation.vue'
import Icon from '~/components/BaseIcon/BaseIcon.vue'
import EventHeroVideo from './comps/EventHeroVideo.vue'

export default {
  components: {
    Location,
    StoryblokResolver,
    Icon,
    EventHeroVideo
  },
  props: {
    rightCol: {
      type: Array
    },
    name: {
      type: String
    },
    category: {
      type: String
    },
    description: {
      type: String
    },
    date: {
      type: String
    },
    time: {
      type: String
    },
    location: {
      type: String
    },
    image: {
      type: Object
    },
    logo: {
      type: Object
    },
    websiteUrl: {
      type: Object
    },
    isPastEvent: {
      type: Boolean
    },
    webinarVideo: {
      type: Object
    }
  },
  computed: {
    isIndustryEvent() {
      return this.category === 'Industry Event'
    },
    showWebinarVideo() {
      return (
        this.webinarVideo &&
        this.webinarVideo.filename &&
        this.webinarFormSubmitted
      )
    }
  },
  data() {
    return {
      webinarFormSubmitted: false
    }
  },
  mounted() {
    this.checkIfWebinarFormSubmitted()
    window.addEventListener('form-localstorage-changed', (event) => {
      this.webinarFormSubmitted = event.detail.storage
    })
  },
  methods: {
    checkIfWebinarFormSubmitted() {
      const id = 1017
      const key = `${this.$route.path}#${id}`
      this.webinarFormSubmitted = process.client
        ? window.localStorage.getItem(key)
        : false
    }
  }
}
</script>
