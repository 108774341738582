<template>
  <div class="min-h-[200px]" id="resourceForm">
    <nacelle-button
      v-if="cookieSubmitted"
      class="resource-button"
      block
      no-icon
      @click="openGuide"
    >
      {{ successMessage }}
    </nacelle-button>
    <marketo-form
      v-if="!cookieSubmitted"
      :formId="formId"
      :type="category"
      :successMessage="successMessage"
      :linkedInConversionId="linkedInConversionID"
      @handleForm="handleForm"
    />
  </div>
</template>

<script>
import MarketoForm from '~/components/MarketoForm'
import NacelleButton from '~/components/NacelleButton/NacelleButton'
export default {
  components: {
    MarketoForm,
    NacelleButton
  },
  props: {
    resourceFile: {
      type: Object,
      default: () => ({
        filename: ''
      })
    },
    resourceLink: {
      type: Object,
      default: () => ({
        cached_url: ''
      })
    },
    formSuccessMessage: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: 'Guide',
      validator: (value) => ['Guide', 'Webinar'].includes(value)
    },
    linkedInConversionID: {
      type: String,
      default: ''
    },
    storyFields: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cookieSubmitted: false
    }
  },
  computed: {
    successMessage() {
      if (this.formSuccessMessage) {
        return this.formSuccessMessage
      }
      return this.category === 'Guide'
        ? 'Click here to access guide.'
        : 'Thank you! A new tab should open up to view the webinar. A copy will also be sent to your inbox.'
    },
    formId() {
      return this.category === 'Guide' ? '1008' : '1248'
    }
  },
  methods: {
    handleForm() {
      if (this.category === 'Webinar') {
        this.handleWebinarForm()
      } else {
        this.handleGuideForm()
      }
    },
    handleWebinarForm() {
      if (this.resourceLink.cached_url) {
        if (this.resourceLink.cached_url.includes('http')) {
          window.open(this.resourceLink.cached_url, '_blank')
        } else {
          this.$router.push(this.resourceLink.cached_url)
        }
      }
    },
    handleGuideForm() {
      if (this.$route.params.handle === 'headless-commerce-101') {
        this.$track({
          name: 'eBook: Headless Commerce 101',
          category: 'Form Submission',
          label: 'eBook Download: Headless Commerce 101'
        })
      } else if (this.$route.params.handle === 'build-vs-buy') {
        this.$track({
          name: 'eBook: Build vs Buy',
          category: 'Form Submission',
          label: 'eBook Download: Build vs Buy'
        })
      } else {
        this.$track({
          name: 'eBook: ' + this.storyFields.title,
          category: 'Form Submission',
          label: 'eBook Download'
        })
      }

      const save = document.createElement('a')
      const url = this.resourceFile.filename
      save.href = url
      save.target = '_blank'
      const filename = url.substring(url.lastIndexOf('/') + 1)
      save.download = filename
      this.cookieSubmitted = true
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search('Chrome') < 0
      ) {
        this.openGuide()
      } else {
        const evt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false
        })
        save.dispatchEvent(evt)
        ;(window.URL || window.webkitURL).revokeObjectURL(save.href)
      }
    },
    openGuide() {
      window.open(this.resourceFile.filename, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-button /deep/ button {
  background: #00baff;
  padding: 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
  margin: auto;
  &:hover {
    background: #22346b;
  }
}

/deep/ {
  .marketo-form {
    margin-bottom: 140px;
  }
}
</style>
