var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.theme.select.selectDropdown.wrapper,
    {
      hidden: !_vm.showMenu
    }
  ]},[_c('GradientFade',{attrs:{"show":_vm.showTopGradient,"position":"top"}}),_vm._v(" "),_c('ul',{ref:"dropdown",class:[_vm.theme.select.selectDropdown.menu],attrs:{"tabindex":"-1","role":"listbox","aria-activedescendant":_vm.id},on:{"scroll":_vm.handleScroll}},_vm._l((_vm.optionValues),function(option,i){
  var _obj;
return _c('li',{key:("select-option-" + (_vm.unique(option.label)) + "-" + i),class:[
        _vm.theme.select.selectDropdownItems.el,
        ( _obj = {}, _obj[_vm.theme.select.selectDropdownItems.selected] = _vm.selected === option.value, _obj[_vm.theme.select.selectDropdownItems.unselected] = _vm.selected !== option.value, _obj[_vm.theme.select.selectDropdownItems.placeholder] = option.isPlaceholder || !option.value, _obj )
      ],attrs:{"id":("select-option-" + (_vm.unique(option.label)) + "-" + i),"role":"option"},on:{"click":function (e) { return _vm.handleChange(option.value); }}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n        "+_vm._s(option.label)+"\n      ")]),_vm._v(" "),(_vm.selected === option.value)?_c('Checkmark'):_vm._e()],1)}),0),_vm._v(" "),_c('GradientFade',{attrs:{"show":_vm.showBottomGradient,"position":"bottom"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }