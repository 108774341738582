<template>
  <div
    class="resource-card"
    :class="{ 'resource-card-gradient': !thumbnailImage }"
  >
    <div class="resource-card-image-box">
      <img
        v-if="thumbnailImage"
        :src="thumbnailImage.filename"
        :alt="thumbnailImage.alt"
      />
    </div>
    <p v-if="category" class="resource-card-category">{{ category }}</p>
    <h4>
      <nuxt-link :to="`${handle}`">{{ title }}</nuxt-link>
    </h4>
    <p class="description">
      {{ description.replace(/[^>]*>/g, '').substring(0, 100) }}...
    </p>
    <div class="card-arrow">
      <p v-if="category === 'Guide' || category === 'Webinar'">Learn More</p>
      <p v-if="category === 'eBook'">Download</p>
      <img src="/nacelle-arrow-right.svg" alt="nacelle arrow right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    thumbnailImage: {
      type: Object
    },
    title: {
      type: String
    },
    handle: {
      type: String
    },
    description: {
      type: String
    },
    category: {
      type: String
    }
  },
  computed: {
    /**
     * Sets the current date and event date to time in miliseconds for comparison (i.e.: 1631734700814)
     * This may be removed in the future based on design specs
     */
    // btnText() {
    //   const currentDate = new Date().getTime()
    //   const eventDate = new Date(this.date).getTime()
    //   return currentDate >= eventDate ? 'Watch Now' : 'Register'
    // }
  }
}
</script>

<style lang="scss" scoped>
.resource-card {
  width: 366px;
  border-radius: 5px 5px 0 0;
  margin: 25px 8px;
  border-bottom: 1px solid #00baff;
  overflow: hidden;
  color: #fff;
  position: relative;
  transition: all 0.3s ease;
  min-height: 500px;
  text-decoration: none;
  &::after {
    position: absolute;
    width: 0;
    content: '';
    height: 3px;
    left: 0;
    bottom: -2px;
    background: #00baff;
    transition: all 0.3s ease;
  }
  &:hover {
    cursor: pointer;
    &::after {
      width: 100%;
    }
  }
  &:focus,
  &:focus-within {
    box-shadow: 0 0 0 2px #fff;
  }
}
h4 {
  font-weight: 400;
}
.summary {
  margin: 10px 0;
}
.resource-card-gradient {
  background: #0e1328;
  // &:hover {
  //   background: #22346b;
  // }
  h4 {
    font-weight: 700;
  }
  // .resource-card-image-box {
  //   height: 30px;
  // }
}
.resource-card-category {
  position: absolute;
  top: -2px;
  left: 21px;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #fff;
  color: #00baff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.42px;
  line-height: 19px;
  text-align: center;
  // text-transform: uppercase;
}
.resource-card-image-box,
.resource-card-image-box img {
  width: 370px;
}

h4 {
  font-size: 28px;
  letter-spacing: 0.88px;
  line-height: 40px;
  margin: 30px 0 10px;
  a {
    text-decoration: none;
    color: #fff;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    &:focus,
    &:focus-within {
      outline: none;
    }
  }
}

.card-arrow {
  display: flex;

  position: absolute;
  bottom: -10px;
  right: 10px;

  p {
    color: #00baff;
    margin-right: 20px;
    font-size: 12pt;
    font-weight: 500;
  }
  img {
    filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
      brightness(102%) contrast(104%); //Makes color #00BAFF (Blue)
  }
}

@include touch {
  .resource-card-image-box,
  .resource-card-image-box img {
    width: 312px;
  }
}
</style>
