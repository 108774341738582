import { useUserStore } from '~/store/user'

export default (entry, form, vm) => {
  const { userId } = useUserStore()
  if (entry.type === 'track') {
    const includedForm = entry.includeForm ? form : {}
    vm.$track({
      name: entry.name,
      category: entry.category,
      label: entry.label,
      ...includedForm
    })
  }

  if (entry.type === 'identify') {
    let formFormatted = {}
    let email
    for (let key in form) {
      formFormatted[key.toLowerCase()] = form[key]
    }
    const includedForm = entry.includeForm ? formFormatted : {}
    if ('email' in formFormatted) {
      email = formFormatted.email
    }
    vm.$identify({
      user: userId,
      email,
      ...includedForm
    })
  }
}
