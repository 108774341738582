export default (block) => {
  const blockCopy = {...block}
  const hasSectionColumn = blockCopy.blocks.some(
    (blockCopy) => blockCopy.component === 'SectionColumn'
  )
  if (!hasSectionColumn) {
    return {
        ...blockCopy,
        blocks: [
          {
            _uid: `col-${Date.now()}`,
            column: [...blockCopy.blocks]
          }
        ]
      }
  }
  return blockCopy
}
