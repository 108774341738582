<template>
  <div class="culture-values">
    <img v-if="image" class="cv-image" :src="image.filename" :alt="title" />
    <h5>{{ title }}</h5>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.culture-values {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 270px;
  margin: 75px 15px;
}
.cv-image {
  box-sizing: border-box;
  height: 88.5px;
  width: 88.5px;
}
h5 {
  font-weight: bold;
  text-align: center;
  margin: 50px 0 5px;
  color: #ffffff;
}
p {
  text-align: center;
}
@include touch {
  .culture-values {
    margin: 75px auto 25px;
  }
  h5 {
    margin: 20px 0 5px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
