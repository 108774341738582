<template>
  <section
    class="w-auto max-w-screen"
    :class="[{ [bgColor]: !!!bgHex }, padding, height]"
    :style="{
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `${bgSize}`,
      backgroundPosition: `${bgPosition}`,
      backgroundColor: !!bgHex && bgHex
    }"
  >
    <div
      class="mx-auto w-auto"
      :class="{
        [maxWidth]: !!maxWidth
      }"
    >
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: 'max-w-[1296px]'
    },
    bgColor: {
      type: String,
      default: 'bg-white'
    },
    padding: {
      type: String,
      default: 'p-10'
    },
    height: {
      type: String,
      default: 'h-auto'
    },
    bgImage: {
      type: String,
      default: ''
    },
    bgPosition: {
      type: String,
      default: ''
    },
    bgCover: {
      type: Boolean,
      default: false
    },
    bgHex: {
      type: String,
      default: ''
    }
  },
  computed: {
    bgSize() {
      return this.bgCover ? 'cover' : 'contain'
    }
  }
}
</script>

<style></style>
