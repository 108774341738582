<template>
  <div>
    <div
      class="group rounded-[6px] bg-[#7A888F] max-w-[262px] appearance-none flex px-[22px] py-[10px] flex items-center"
    >
      <BaseIcon file="IconSearch" class="w-4 h-4 text-[#FAFAFA] mr-3" />
      <input
        type="text"
        class="border-none text-base leading-5 text-[#FAFAFA] bg-transparent placeholder:text-[#FAFAFA] border-none outline-none"
        placeholder="Search"
      />
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon/BaseIcon'
export default {
  components: {
    BaseIcon
  }
}
</script>

<style></style>
