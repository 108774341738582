<template>
  <div v-if="globalSections">
    <div v-for="section in globalSections" :key="section._uid">
      <StoryblokResolver :block="section" :storyFields="section" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useGlobalStore } from '~/store/global'
export default {
  computed: {
    ...mapState(useGlobalStore, ['globalSections'])
  }
}
</script>
