<template>
  <div class="challenge-results bg-white">
    <div
      class="cr-container relative flex flex-col max-w-7xl mx-auto lg:mb-24 lg:flex-row"
    >
      <div class="cr-media lg:flex-1">
        <div class="cr-media-sticky">
          <div class="cr-media-container">
            <img
              class="cr-bg-img"
              :src="backgroundImage.filename"
              :alt="backgroundImage.alt"
            />
            <img
              class="cr-fg-img"
              :src="foregroundImage.filename"
              :alt="foregroundImage.alt"
            />
          </div>
        </div>
      </div>
      <div class="cr-text-block mx-7 lg:mx-0 lg:flex-1">
        <div class="max-w-xl mx-auto mb-16 lg:my-24">
          <div
            v-html="typeof content === 'string' ? content : richTextContent"
            class="text-black"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundImage: {
      type: Object,
      default: () => ({})
    },
    foregroundImage: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    richTextContent() {
      return this.content
        ? this.$storyapi.richTextResolver.render(this.content)
        : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.cr-media {
  .cr-media-sticky {
    padding-bottom: 100px;
    @media screen and (min-width: 768px) {
      padding-bottom: 200px;
    }
    @include desktop {
      position: sticky;
      top: 125px;
    }
  }
  .cr-media-container {
    width: 100%;
    position: relative;

    display: grid;
    grid-template-columns: 45% auto 10%;
    grid-template-rows: 60% auto;

    @include desktop {
      width: 90%;
      grid-template-columns: 250px auto 20%;
    }

    .cr-bg-img {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
      width: 100%;
      height: auto;
    }

    .cr-fg-img {
      grid-column: 2 / 4;
      grid-row: 2 / 3;
      width: 100%;
      height: auto;
    }
  }
}
.cr-text-block {
  /deep/ {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #010204;
    }
  }
}
</style>
