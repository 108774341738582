<template>
  <div>
    <img
      style="position: absolute; top: -1px; left: -1px"
      src="https://tracking.g2crowd.com/attribution_tracking/conversions/4243.gif?e="
    />
    <portal-target name="gdpr"></portal-target>
    <site-header :inert="pageHasModalOpen" />

    <main :inert="pageHasModalOpen">
      <nuxt class="main" />
    </main>
    <GlobalSections />
    <site-footer :content="content" :inert="pageHasModalOpen" />
    <portal-target name="modal"></portal-target>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '~/store/user'
import { useGdprStore } from '~/store/gdpr'
import { useModalStore } from '~/store/modal'
import SiteHeader from '~/components/SiteHeader/SiteHeader'
import SiteFooter from '~/components/V2/SiteFooter/SiteFooter'
import SiteFooterData from '~/components/V2/SiteFooter/SiteFooter.mock.js'
import { euChecker } from '../plugins/euChecker'

export default {
  components: {
    SiteHeader,
    SiteFooter
  },
  data() {
    return {
      content: SiteFooterData
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://${process.env.BASE_URL}${this.$route.path}`
        }
      ]
    }
  },
  watch: {
    $route() {
      if (this.pageHasModalOpen) {
        this.isModalOpen(false)
      }
      if (this.pageHasGdprOpen) {
        this.isGdprOpen(false)
      }
    }
  },
  computed: {
    ...mapState(useModalStore, ['pageHasModalOpen']),
    ...mapState(useGdprStore, ['pageHasGdprOpen']),
    ...mapState(useGdprStore, ['isInEu'])
  },
  methods: {
    ...mapActions(useUserStore, ['getUser']),
    ...mapActions(useGdprStore, ['isGdprOpen', 'isUserInEU', 'euChecker'])
  },
  mounted() {
    this.getUser()
    if (process.client) {
      this.euChecker(euChecker())
    }
  }
}
</script>
<style lang="scss" scoped></style>
