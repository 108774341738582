var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"w-auto max-w-screen",class:[( _obj = {}, _obj[_vm.bgColor] = !!!_vm.bgHex, _obj ), _vm.padding, _vm.height],style:({
    backgroundImage: ("url(" + _vm.bgImage + ")"),
    backgroundRepeat: "no-repeat",
    backgroundSize: ("" + _vm.bgSize),
    backgroundPosition: ("" + _vm.bgPosition),
    backgroundColor: !!_vm.bgHex && _vm.bgHex
  })},[_c('div',{staticClass:"mx-auto w-auto",class:( _obj$1 = {}, _obj$1[_vm.maxWidth] = !!_vm.maxWidth, _obj$1 )},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }