<template>
  <!-- TODO: update styles once we have a mockup -->
  <div id="apollo-container" class="w-full h-[600px] mx-auto">
    <div id="embedded-explorer" class="h-full"></div>
    <script
      type="text/javascript"
      src="https://embeddable-explorer.cdn.apollographql.com/_latest/embeddable-explorer.umd.production.min.js"
    ></script>
  </div>
</template>
<script>
export default {
  props: {
    body: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.embedExplorer()
  },
  methods: {
    embedExplorer() {
      // more config options for embedded explorer can be found here: https://www.apollographql.com/docs/studio/embed-explorer/#options
      const explorer = document.createElement('script')
      explorer.innerHTML = `new window.EmbeddedExplorer({
        target: '#embedded-explorer',
        graphRef: '${this.body.graphRef}',
        endpointUrl: '${this.body.endpointUrl}',
        persistExplorerState: false,
        initialState: {
          document: \`${this.body.query}\`,
          displayOptions: {
            showHeadersAndEnvVars: false,
            docsPanelState: 'open',
            theme: '${this.body.theme}',
          },
        },
      });`
      const element = document.getElementById('apollo-container')
      element.appendChild(explorer)
    }
  }
}
</script>
