<template>
  <section class="relative">
    <div class="hero-gradient">
      <div
        class="hero mx-auto max-w-[790px] flex flex-col text-center text-white lg:mt-12 p-10"
      >
        <h1 class="page-title">
          {{ title }}
          <div>
            <div
              class="min-width-[500px] text-left inline-block h-20 md:h-14 overflow-hidden lg:min-w-[280px]"
            >
              <transition
                name="reveal"
                enter-active-class="revealin"
                leave-active-class="revealout"
              >
                <h1
                  v-show="transition"
                  class="relative mx-auto my-0 overflow-hidden inline-flex text-center text-cyan-500 page-title"
                >
                  {{ titles.items[currentIndex] }}
                </h1>
              </transition>
            </div>
          </div>
        </h1>
        <p class="lg:text-lg mb-5">{{ description }}</p>

        <SiteForm class="hero-site-form" v-if="emailForm" v-bind="emailForm" />
        <MarketoForm
          class="hero-marketo-form"
          v-if="marketoFormProps"
          v-bind="marketoFormProps"
        />
        <SimpleEmailLink
          class="hero-simple-link"
          v-if="simpleEmailLinkProps"
          v-bind="simpleEmailLinkProps"
        />
        <NacelleButton
          v-if="linkText"
          class="nacelle-button"
          @click="goTo(linkPath)"
          :noIcon="true"
          >{{ linkText }}</NacelleButton
        >
      </div>
    </div>
  </section>
</template>

<script>
import MarketoForm from '~/components/MarketoForm'
import SiteForm from '~/components/SiteForm'
import SimpleEmailLink from '~/components/SimpleEmailLink'
import NacelleButton from '~/components/NacelleButton/NacelleButton'

export default {
  components: {
    MarketoForm,
    SiteForm,
    SimpleEmailLink,
    NacelleButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    titles: {
      type: Object,
      default: () => {}
    },
    description: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    linkPath: {
      type: String,
      default: ''
    },
    emailForm: Object,
    marketoFormProps: Object,
    simpleEmailLinkProps: Object
  },
  data() {
    return {
      currentIndex: 0,
      interval: null,
      transition: true
    }
  },
  mounted() {
    this.startInterval()
  },
  methods: {
    /**
     * Starts interval counter for animating through the titles
     */
    async startInterval() {
      setTimeout(this.updateText, 2000)
      await new Promise((resolve) => setTimeout(resolve, 2000))
      this.interval = setInterval(this.updateText, 4000)
    },
    /**
     * Updates the text to the next item when the interval changes
     */
    updateText() {
      this.transition = false
      setTimeout(() => {
        if (this.titles.items[this.currentIndex + 1]) {
          this.currentIndex++
        } else {
          this.currentIndex = 0
        }
      }, 500)
      setTimeout(() => {
        this.transition = true
      }, 1500)
    }
  },
  /**
   * Sets interview to 0 when the component is destroyed (i.e.: route change)
   */
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.hero-gradient {
  min-height: calc(100vh / 2);
}

.title-container {
  min-width: 500px;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 768px) {
    height: 80px;
    overflow: hidden;
  }
  @include touch {
    min-width: 280px;
  }
}
.current-title {
  margin: auto 0;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  color: #00baff;
  text-align: center;
}

// Animations
.revealin {
  color: transparent;
  animation: revealBlock 1s forwards;
  &::after {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00baff;
    animation: revealingIn 1s forwards;
  }
}

.revealout {
  position: relative;
  animation: hideBlock 1s forwards;
  white-space: nowrap;
}

@keyframes revealBlock {
  50% {
    color: transparent;
  }
  51%,
  100% {
    color: #00baff;
  }
}
@keyframes hideBlock {
  0% {
    width: 100%;
  }
  50% {
    width: 0;
    transform: translateX(0);
    color: #00baff;
  }
  100% {
    width: 0;
    color: transparent;
    visibility: hidden;
  }
}

@keyframes revealingIn {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
    width: 100%;
  }
}

@keyframes revealingOut {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
    width: 100%;
  }
}
</style>
