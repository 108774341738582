export default (block) => {
  return {
    ...block,
    videoItems: block.videoItems.map((item) => ({
      ...item,
      host: {
        ...item.host[0],
        imageUrl: item.host[0].image.filename
      },
      // videoType: item.videoType[0],
      videos: item.videos.map((video) => ({
        ...video,
        videoUrl: video.video.filename
      }))
    }))
  }
}
