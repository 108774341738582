<template>
  <div class="flex justify-center mx-2 md:mx-10 md:mt-10">
    <div
      class="p-0 rounded border border-solid border-cyan-500 w-full max-w-[1170px]"
    >
      <BaseAccordion
        v-for="{ title, body, _uid } in entries"
        :key="_uid"
        :title="title"
        titleLeft
      >
        <div
          class="accordion-content my-5 mx-4 md:mx-11 tracking-[.39] leading-[22px]"
        >
          <RichTextRenderer v-if="body" :document="body" />
        </div>
      </BaseAccordion>
    </div>
  </div>
</template>

<script>
import BaseAccordion from '~/components/BaseAccordion/BaseAccordion'
export default {
  props: {
    entries: {
      type: Array,
      default: () => []
    }
  },
  components: {
    BaseAccordion
  }
}
</script>

<style lang="scss" scoped>
.accordion-content {
  /deep/ p {
    font-size: 18px;
  }
  /deep/ a {
    background: linear-gradient(233.09deg, #f56789 9.9%, #c4103b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

    &:hover {
      color: #fff;
    }
  }
}
</style>
