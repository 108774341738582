<template>
  <div class="bg-[#001C27] max-w-screen px-10 pt-20 pb-10">
    <footer
      v-if="loaded"
      class="max-w-[1296px] flex flex-col box-border mx-auto py-0 text-[#B4B6C4]"
    >
      <div class="flex-wrap md:inline-flex justify-between gap-x-20">
        <G2Widget v-if="footer.g2widget" v-bind="footer.g2widget" />

        <nav
          class="grid grid-cols-2 gap-x-5 md:grid-cols-4 md:gap-x-20"
          aria-label="footer"
        >
          <div v-for="(section, i) in footer.navSection" :key="i">
            <FooterMenuSection v-bind="section" />
          </div>
        </nav>
      </div>

      <div
        class="footer-legal flex flex-col md:flex-row justify-between items-center pt-5 mt-10"
      >
        <div
          class="order-last md:order-first flex flex-col md:flex-row items-center gap-4 md:gap-10"
        >
          <img
            class="footer-logo"
            src="/nacelle-footer-logo.svg"
            alt="NACELLE"
          />

          <p class="text-sm text-[#B4B6C4]">
            &copy; {{ currentYear }} Nacelle All Rights Reserved
          </p>

          <div class="flex flex-col md:flex-row gap-4 md:gap-10 text-sm">
            <nuxt-link
              v-for="(link, j) in footer.legalSection.sections"
              :key="j"
              :to="'/' + link.link.cached_url"
              class="text-[#B4B6C4] no-underline hover:underline"
            >
              {{ link.title }}
            </nuxt-link>
          </div>
        </div>

        <div class="social-icons flex items-center gap-x-10 mb-12 md:mb-0">
          <a href="https://www.youtube.com/channel/UCMFFmiVUGG5Ug_HsCRWTMAQ">
            <Icon
              file="YouTube"
              class="text-[#B4B6C4] hover:text-red-700"
              alt="YouTube Logo"
            />
          </a>
          <a href="https://www.linkedin.com/company/getnacelle">
            <Icon
              file="LinkedIn"
              class="text-[#B4B6C4] hover:text-red-700"
              alt="LinkedIn Logo"
            />
          </a>
          <a href="https://www.facebook.com/getnacelle">
            <Icon
              file="Facebook"
              class="text-[#B4B6C4] hover:text-red-700"
              alt="Facebook Logo"
            />
          </a>
          <a href="https://twitter.com/getnacelle">
            <Icon
              file="Twitter"
              class="text-[#B4B6C4] hover:text-red-700"
              alt="Twitter Logo"
            />
          </a>
        </div>
      </div>
    </footer>
    <div v-if="open" class="mt-[100px]"></div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useBannerStore } from '~/store/banner'
import { useGlobalStore } from '~/store/global'
import FooterMenuSection from './FooterMenuSection'
import G2Widget from './G2Widget'
import Icon from '~/components/BaseIcon/BaseIcon.vue'

export default {
  components: {
    FooterMenuSection,
    G2Widget,
    Icon
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    ...mapState(useBannerStore, ['open']),
    ...mapState(useGlobalStore, ['loaded', 'footer'])
  }
}
</script>

<style lang="scss" scoped>
.footer-legal {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.social-icons {
  a {
    img {
      filter: invert(37%) sepia(48%) saturate(3619%) hue-rotate(323deg)
        brightness(95%) contrast(95%);
      transition: 150ms ease-in-out;

      &:hover {
        filter: invert(1);
      }
    }
  }
}
</style>
