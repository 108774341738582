<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
      intersection: { threshold: 1 }
    }"
  >
    <div v-if="isVisible">
      <div class="ticker">
        <div v-for="(item, index) in stat" :key="index">
          <component :is="itemComponent(item)" v-bind="itemProps(item)">
            {{ itemSlot(item) }}
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
  props: ['stat'],
  components: { countTo },
  data() {
    return {
      endVal: 0,
      string: '',
      isVisible: false
    }
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
    },
    itemComponent(item) {
      return isNaN(parseFloat(item)) ? 'div' : 'countTo'
    },
    itemProps(item) {
      if (!isNaN(parseFloat(item))) {
        return { startVal: 0, endVal: parseFloat(item), duration: 2000 }
      }
    },
    itemSlot(item) {
      return isNaN(parseFloat(item)) && item
    }
  },
  computed: {
    // This method might be removed in the future as I found the regex to
    // actually cause the main component functionality to break
    // numbersAndChars() {
    //   return this.stat.match(/[d.]+|\D+/g)
    // }
  }
}
</script>

<style lang="scss" scoped>
.ticker {
  display: flex;
  justify-content: center;
}
</style>
