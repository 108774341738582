<template>
  <div class="flex items-center">
    <label
      :for="`${label}-id`"
      tabindex="0"
      @keyup.enter="onChange"
      class="item"
      :aria-label="`${label}`"
      role="checkbox"
      :aria-checked="checked.toString()"
    >
      <div aria-hidden="true" class="flex items-center">
        <input
          :id="`${label}-id`"
          type="checkbox"
          :value="`${value}`"
          @change="onChange"
          class="hidden"
        />
        <div
          v-if="!checked"
          class="bg-white border-2 border-solid border-gray-500 rounded-md w-4 h-4 flex justify-center items-center mr-2 checkbox"
        />
        <img v-else class="checkmark" src="/svg/checkmark-success.svg" />
        <slot name="icon"></slot>
        <span>{{ label }}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false
    }
  },
  watch: {
    checked: {
      immediate: true,
      handler() {
        this.$emit('input', {
          checked: this.checked,
          id: `${this.label}-id`,
          label: this.label,
          value: this.value
        })
      }
    }
  },
  methods: {
    onChange() {
      this.checked = !this.checked
    }
  },
  mounted() {
    this.checked = this.defaultValue
  }
}
</script>

<style lang="scss" scoped>
input:checked ~ .checkbox {
  border: solid 2px transparent;
}

.item {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1em;
  height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.checkmark {
  border-radius: 5px;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
</style>
