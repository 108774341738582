export default (block) => {
  const mappedBlock = block
  if (block.icon.filename) {
    mappedBlock.icon = block.icon.filename
  }
  if (block.image.filename) {
    mappedBlock.image = block.image.filename
  }
  mappedBlock.successRedirect = block.successRedirect
    ? block.successRedirect.cached_url
    : ''
  return mappedBlock
}
