import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', {
  state: () => ({
    showMenu: false
  }),
  actions: {
    toggleShowMenu() {
      this.showMenu = !this.showMenu
    },
    disableMenu() {
      this.showMenu = false
    }
  }
})
