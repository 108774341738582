<template>
  <component
    :is="block.component"
    :key="block._uid"
    v-bind="mappedBlock"
    :story-fields="storyFields"
    v-editable="block"
  />
</template>

<script>
import storyblokMappers from '../utils/storyblokMappers'

export default {
  props: {
    block: {
      type: Object,
      default: () => ({
        component: '',
        _uid: ''
      }),
      required: true
    },
    storyFields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    mappedBlock() {
      return this.block.component in storyblokMappers
        ? storyblokMappers[this.block.component](this.block, this.storyFields)
        : this.block
    }
  },
  created() {
    if (process.env.NODE_ENV !== 'production') {
      this.$options.name = `Sbr${this.block.component}`
    }
  }
}
</script>
