<template>
  <nav class="max-w-[1290px] w-full mx-auto flex justify-between items-center p-8">
    <Logo />
    <NavItems :navItems="navItems" />
    <NavRight />
  </nav>
</template>

<script>
import Logo from "./components/Logo"
import NavRight from "./components/NavRight"
import NavItems from "./components/NavItems/NavItems"
export default {
    components: {
        Logo,
        NavItems,
        NavRight
    },
    props: {
        navItems: {
            type: Array
        }
    }
}
</script>

<style>

</style>