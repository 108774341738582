var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta-container flex justify-center mt-28 w-full mb-20"},[_c('div',{staticClass:"cta w-full max-w-7xl flex relative rounded-none bg-cyan-500 text-white p-3 mb-14 lg:mt-10 lg:mx-10 lg:mb-28 lg:p-14 lg:pb-14 lg:rounded-[5px]"},[(_vm.overrideSplit)?_c('div',{staticClass:"double-cta py-8 w-full flex justify-center items-center flex-col z-[2] lg:mb-0"},[_c('h3',{staticClass:"text-3xl font-bold tracking-wide leading-relaxed lg:text-6xl lg:leading-snug m-0 text-center"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),(_vm.subtitle)?_c('p',{staticClass:"text-xl font-medium text-center"},[_vm._v("\n        "+_vm._s(_vm.subtitle)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cta-links flex justify-center content-center items-center flex-col w-full m-0 lg:flex-row lg:max-w-lg lg:mt-9",class:{ 'cta-links-form': _vm.emailLinkText }},[(_vm.buttonText)?_c('nacelle-button',{staticClass:"cta-button",attrs:{"color":"secondary","no-icon":_vm.noIcon},on:{"click":function($event){_vm.goTo(
              _vm.$formRoute({
                url: _vm.buttonPath.cached_url
              })
            )}}},[_vm._v("\n          "+_vm._s(_vm.buttonText)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.linkText && _vm.linkPath.cached_url)?_c('text-link',{attrs:{"linkPath":_vm.linkPath.cached_url,"color":"secondary"}},[_vm._v("\n          "+_vm._s(_vm.linkText)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.emailLinkText && _vm.emailLinkPath.cached_url)?_c('simple-email-link',{staticClass:"simple-email-link mt-9 mx-auto mb-0 w-full max-w-lg lg:mt-0 lg:mb-0 lg:w-auto",attrs:{"linkText":_vm.emailLinkText,"linkPath":_vm.emailLinkPath.cached_url}},[_vm._v("\n          "+_vm._s(_vm.emailLinkText)+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.isEmpty(_vm.marketoFormProps))?_c('marketo-form',_vm._b({staticClass:"cta-marketo-form"},'marketo-form',_vm.marketoFormProps,false)):_vm._e()],1)]):_c('div',{staticClass:"single-cta flex justify-between items-center content-center w-full flex-col py-8 z-[2] lg:flex-row lg:py-0"},[_c('h3',{staticClass:"my-5 text-3xl font-bold tracking-wide leading-relaxed lg:text-6xl lg:my-0 w-1/2"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),_c('nacelle-button',{attrs:{"color":"secondary","no-icon":_vm.noIcon},on:{"click":function($event){_vm.goTo(
            _vm.$formRoute({
              url: _vm.buttonPath.cached_url
            })
          )}}},[_vm._v(_vm._s(_vm.buttonText))])],1),_vm._v(" "),_c('div',{staticClass:"cta-slant-gradiant"})])])}
var staticRenderFns = []

export { render, staticRenderFns }