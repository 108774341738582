<template>
  <div
    class="mt-24 mb-20 px-5 lg:px-0"
    :class="{ 'color-bg py-16': hasColorBg }"
  >
    <div class="flex flex-col justify-center items-center">
      <h6 class="text-center mt-8">{{ title }}</h6>
      <h2 class="flex flex-col text-center max-w-screen-lg" :class="textColor">
        {{ description }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: '',
      required: true
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => {
        return ['light', 'dark', 'color-background'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    textColor() {
      return ['dark', 'color-background'].includes(this.theme)
        ? 'text-white'
        : 'text-black'
    },
    hasColorBg() {
      return this.theme === 'color-background'
    }
  }
}
</script>

<style lang="scss" scoped>
.color-bg {
  background: rgb(10, 37, 47);
}
</style>
