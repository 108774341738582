<template>
  <div
    class="relative flex flex-col m-[6px] items-center text-center px-6 py-5 lg:py-12 border border-solid border-red-500 text-white rounded w-[237px] md:w-[337px] h-full md:h-[271px]"
    style="background-color: #08232d"
  >
    <div class="mb-0 lg:mb-8">
      <img
        class="max-h-[100px]"
        :src="feature.image.filename"
        :alt="feature.image.alt"
      />
    </div>
    <div>
      <nuxt-link
        v-if="
          feature.link &&
          feature.link.linktype === 'story' &&
          feature.link.cached_url
        "
        :to="'/' + feature.link.cached_url"
        class="feature-link no-underline"
      >
        <h3 class="text-xl font-bold mb-1">{{ feature.title }}</h3>
      </nuxt-link>
      <a
        v-else-if="
          feature.link &&
          feature.link.linktype === 'url' &&
          feature.link.cached_url
        "
        :href="feature.link.cached_url"
        target="_blank"
        class="feature-link no-underline"
      >
        <h3 class="text-xl font-bold mb-1">{{ feature.title }}</h3>
      </a>
      <h3 v-else class="text-xl font-bold mb-1">{{ feature.title }}</h3>
      <p class="m-0">{{ feature.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.feature-link {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
