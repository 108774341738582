<template>
  <div class="pt-[60px] w-full bg-[#001c27]">
    <div class="max-w-[1250px] mx-auto">
      <h2
        id="recorded"
        class="text-[28px] md:text-[36px] tracking-[.65px] leading-[47px] text-center my-[30px]"
      >
        {{ heading }}
      </h2>
      <VideoFilter
        :typeFilters="typeFilters"
        @filter-change="handleFilterChange"
      />
      <div class="px-[1rem]">
        <VideoItem
          v-for="(videoItem, i) in filteredVideoItems"
          :key="`video-item-${i}`"
          v-bind="videoItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VideoItem from '../VideoSeriesItem/VideoSeriesItem.vue'
import VideoFilter from './comps/VideoSeriesFilter.vue'
import { mapState, mapActions } from 'pinia'
import { useVideoStore } from '~/store/video'

export default {
  components: {
    VideoItem,
    VideoFilter
  },
  props: {
    heading: {
      type: String,
      required: true,
      default: ''
    },
    videoItems: {
      type: Array,
      required: true,
      default: () => [{}]
    }
  },
  data: () => ({
    selectedFilterType: 'All Things'
  }),
  computed: {
    ...mapState(useVideoStore, ['getModalFile', 'getVideoModal']),
    typeFilters() {
      return Array.from(
        new Set(...this.videoItems.map((item) => item.videoType))
      )
    },
    filteredVideoItems() {
      return this.selectedFilterType === 'All Things'
        ? this.videoItems
        : this.videoItems.filter((item) =>
            item.videoType.includes(this.selectedFilterType)
          )
    }
  },
  watch: {
    getModalFile() {
      this.$refs.video.src = this.getModalFile
    },
    getVideoModal(newVal, oldVal) {
      if (newVal) {
        this.$refs.video.play()
        return
      }
      this.$refs.video.pause()
    }
  },
  methods: {
    ...mapActions(useVideoStore, ['toggleVideoModal']),
    handleFilterChange({ name, value }) {
      this.selectedFilterType = value
    }
  }
}
</script>

<style></style>
