<template>
  <div class="bg-[#CBCCD6] pt-20">
    <Heading class="mb-12 w-full text-center">
      {{ heading }}
    </Heading>
    <Carousel has-dots>
      <BaseSection
        bg-color="bg-[#CBCCD6]"
        padding="px-0 pb-28"
        v-for="testimonial in testimonials"
        :key="testimonial._uid"
      >
        <div class="flex justify-center">
          <div class="flex flex-col items-center w-full max-w-[922px]">
            <div
              class="testimonial mx-8 bg-white shadow-xl p-20 rounded-lg mb-12 text-center italic relative"
            >
              <img
                class="w-16 opacity-20 absolute top-4 right-4 md:top-8 md:right-8"
                src="/quote-illustration.svg"
              />
              <rich-text-renderer
                class="copy"
                v-if="testimonial.copy"
                :document="testimonial.copy"
              />
            </div>
            <div class="flex items-center">
              <img
                class="w-12 med:w-16 rounded-full mr-4"
                :src="testimonial.logo.filename"
                v-if="testimonial.logo.filename"
              />
              <img
                v-else
                class="w-12 med:w-16 rounded-full mr-4"
                src="https://via.placeholder.com/150"
              />

              <div class="flex flex-col">
                <span class="block text-base md:text-xl font-bold mb-1">{{
                  testimonial.name
                }}</span>
                <span>{{ testimonial.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </BaseSection>
    </Carousel>
  </div>
</template>

<script>
import Carousel from '~/components/V2/Carousel/Carousel'
import BaseSection from '~/components/V2/Base/BaseSection/BaseSection'
export default {
  components: { Carousel, BaseSection },
  props: {
    heading: {
      type: String
    },
    testimonials: {
      type: Array,
      default: () => {
        return [
          {
            copy: 'Testimonial copy goes here',
            name: 'First Last',
            title: 'Title, Company',
            logo: { filename: 'https://via.placeholder.com/150' }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.testimonial::after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background-color: white;
  position: absolute;
  bottom: -10px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
</style>
