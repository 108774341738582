<template>
  <div class="flex flex-col">
    <div class="text-[50px] leading-8 font-semibold text-[#65BBE8] mb-2">
      {{ number }}
    </div>
    <Paragraph color="text-white">{{ text }}</Paragraph>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: [String, Number]
    },
    text: {
      type: String
    }
  }
}
</script>

<style></style>
