<template>
  <div class="mx-auto my-0 relative max-w-screen-xl">
    <vue-slick-carousel v-bind="settings">
      <template #prevArrow>
        <div
          class="absolute top-1/2 absolute top-1/2 cursor-pointer left-2 md:left-5 z-10 arrow"
        >
          <img
            src="/keyboard_arrow_left_24px.svg"
            alt="slider arrow prev"
            class="w-5 h-5 md:w-7 md:h-12"
          />
        </div>
      </template>
      <template #nextArrow>
        <div
          class="absolute top-1/2 absolute top-1/2 cursor-pointer right-2 md:right-5 rotate-180 z-10 arrow"
        >
          <img
            src="/keyboard_arrow_left_24px.svg"
            alt="slider arrow prev"
            class="w-5 h-5 md:w-7 md:h-12"
          />
        </div>
      </template>
      <div v-for="item in content" :key="item._uid">
        <component :is="item.component" v-bind="item" />
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Quote from '~/components/Quote/Quote.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  components: { VueSlickCarousel, Quote },
  data() {
    return {
      settings: {
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    }
  },
  props: {
    content: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  font-size: 0;
}
</style>
