<template>
  <p
    class="m-0 p-0 text-2xl leading-[58px] tracking-normal font-medium uppercase"
    :class="color"
  >
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-red-700'
    }
  }
}
</script>
