<template>
  <div class="mx-7 mt-[62px] flex flex-col justify-center">
    <h2 class="text-2xl mb-2 w-full mx-auto relative z-10 max-w-[1080px]">
      Featured Event
    </h2>
    <div
      class="featured-event-wrapper h-full lg:h-[300px] after:absolute after:top-[calc(50%-60px)] after:left-0 after:z-10 after:w-full after:h-[calc(50%+60px)] after:bg-white after:content-[''] lg:after:top-[210px] lg:after:h-[150px]"
    >
      <event-card
        v-bind="mappedEvent"
        is-featured
        class="mx-auto max-w-[1080px] relative z-20"
      />
    </div>
  </div>
</template>

<script>
import EventCard from '~/components/Events/EventCard/EventCard'
export default {
  components: {
    EventCard
  },
  props: {
    mappedEvent: {
      type: Object,
      required: true
    }
  }
}
</script>
