// prettier-ignore
export default {
  CaseStudyCard: require('~/components/CaseStudyCard/CaseStudyCard.sb.mapper.js').default,
  Cta: require('~/components/Cta/Cta.sb.mapper.js').default,
  EventLogoGrid: require('~/components/Events/EventLogoGrid/EventLogoGrid.sb.mapper.js').default,
  FeaturedEvent: require('~/components/Events/FeaturedEvent/FeaturedEvent.sb.mapper.js').default,
  NewsAndUpdates: require('~/components/NewsAndUpdates/NewsAndUpdates.sb.mapper.js').default,
  VideoEmbed: require('~/components/VideoEmbed/VideoEmbed.sb.mapper.js').default,
  BlogPost: require('~/components/BlogPost/BlogPost.sb.mapper.js').default,
  ActionHero: require('~/components/ActionHero/ActionHero.sb.mapper.js').default,
  RevealingHero: require('~/components/RevealingHero/RevealingHero.sb.mapper.js').default,
  CaseStudyCustomerDetails: require('~/components/CaseStudyCustomerDetails/CaseStudyCustomerDetails.sb.mapper.js').default,
  VideoSeriesVideoListSection: require('~/components/VideoSeriesVideoListSection/VideoSeriesVideoListSection.sb.mapper.js').default,
  VideoSeriesHosts: require('~/components/VideoSeriesHosts/VideoSeriesHosts.sb.mapper.js').default,
  NewsletterPopout: require('~/components/NewsletterPopout/NewsletterPopout.sb.mapper.js').default,
  SiteForm: require('~/components/SiteForm/SiteForm.sb.mapper.js').default,
  NSection: require('~/components/NSection/NSection.sb.mapper.js').default,
  EventHero: require('~/components/EventHero/EventHero.sb.mapper.js').default
}
