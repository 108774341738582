import { setCookies } from "./index"

export default (form, vm, { resources, formId }) => {
    setCookies(vm, formId)
    if (!resources || form.mkto_webinarStage !== 'on-demand') return
    if (resources.cached_url) {
      if (
        resources.linktype === 'url' &&
        /^https?/.test(resources.cached_url)
      ) {
        setTimeout(() => {
          window.open(resources.cached_url, '_blank')
        }, 1200)
      } else {
        vm.$router.push(resources.cached_url)
      }
    }
  }