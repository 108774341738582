<template>
  <h1
    class="m-0 p-0 text-[72px] leading-[90px] font-bold tracking-normal"
    :class="[color]"
  >
    <slot />
  </h1>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-cyan-900'
    }
  }
}
</script>
