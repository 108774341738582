<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0.333313L5.825 1.50831L10.475 6.16665H0.333334V7.83331H10.475L5.825 12.4916L7 13.6666L13.6667 6.99998L7 0.333313Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
}
</script>
