<template>
  <div class="techstack-wrapper">
    <section class="info-row">
      <p class="text-gray-900 font-bold text-sm flex align-center mb-0">
        Tech Stack
        <img
          ref="infoRef"
          @mouseenter="toggleInfoTooltip(true)"
          @mouseleave="toggleInfoTooltip(false)"
          @focus="toggleInfoTooltip(true)"
          @blur="toggleInfoTooltip(false)"
          class="ml-2"
          src="/info.svg"
          aria-labelledby="tech-stack-tooltip"
          tabindex="0"
        />
      </p>
      <Tooltip
        ref="tooltipRef"
        id="tech-stack-tooltip"
        :tooltipShow="infoTooltipShow"
        toolTipText="Select your tech stack to show the most relevant docs"
      />

      <div class="edit-row flex items-center justify-between h-8">
        <h2
          v-if="currentTechStackKeys.length === 0"
          class="text-gray-600 text-xs inline tracking-[.02em]"
        >
          You have no selections...
        </h2>
        <div v-else>
          <img
            v-for="icon in currentTechStackKeys.slice(0, 4)"
            :key="icon"
            :src="`/svg/techstack/${icon.toLowerCase()}.svg`"
            class="p-1 w-6"
          />
        </div>
        <span ref="usedTechsRef">
          <p
            v-if="currentTechStackKeys.length > 4"
            :aria-label="`Current tech selections include: ${currentTechStackKeys}`"
            tabindex="0"
            @mouseenter="toggleSelectionsTooltip(true)"
            @mouseleave="toggleSelectionsTooltip(false)"
            @focus="toggleSelectionsTooltip(true)"
            @blur="toggleSelectionsTooltip(false)"
            role="dialog"
            class="text-cyan-700 text-sm text-bold pointer-events-auto cursor-pointer"
          >
            ...
          </p>
        </span>
        <Tooltip
          ref="usedTechsTooltipRef"
          id="used-techs-tooltip"
          :tooltipShow="selectionsTooltipShow"
          :toolTipText="currentTechStackKeys.slice(4).join(', ')"
        />
        <button
          class="text-cyan-700 bg-white border-0 text-sm text-bold pointer-events-auto cursor-pointer text-right px-0"
          @click="editIsOpen = !editIsOpen"
          aria-haspopup="true"
          :aria-expanded="editIsOpen.toString()"
          aria-controls="techstack-submenu"
        >
          Edit Selection
        </button>
      </div>
    </section>

    <div v-if="editIsOpen" id="techstack-submenu">
      <section
        v-for="[key, values] of Object.entries(this.techStackBaseValues)"
        :key="key"
        :id="`${key}-services`"
        class="mt-4 mb-2"
      >
        <h2 class="text-gray-600 text-sm">
          {{ formatKeyTitle(key) }}
        </h2>
        <CheckBoxList
          :passIn="transformList(values)"
          @input="(e) => handleCheckList(e, key)"
        />
      </section>

      <NacelleButton
        color="techstack-cta"
        noIcon
        class="mx-0 mt-8 mb-4 w-full"
        @click="handleClick"
        >Done</NacelleButton
      >
    </div>
  </div>
</template>

<script>
import { mapStores, mapState } from 'pinia'
import { useDocsStore } from '~/store/docs'
import { TooltipPlugin } from '~/plugins/tooltip'
import NacelleButton from '~/components/NacelleButton/NacelleButton'
import Tooltip from '~/components/Tooltip/Tooltip'
import CheckBoxList from '~/components/Checkbox/CheckBoxList'

export default {
  name: 'DocsTechStack',
  components: {
    NacelleButton,
    Tooltip,
    CheckBoxList
  },
  data() {
    return {
      infoTooltipShow: false,
      selectionsTooltipShow: false,
      editIsOpen: false,
      popperInstanceInfoIcon: null,
      popperInstanceSelections: null
    }
  },
  computed: {
    ...mapStores(useDocsStore),
    ...mapState(useDocsStore, [
      'techStackBaseValues',
      'currentTechStackKeys',
      'techStack'
    ])
  },
  methods: {
    toggleSelectionsTooltip(isSet) {
      this.selectionsTooltipShow = isSet
      this.popperInstanceSelections.update()
    },

    toggleInfoTooltip(isSet) {
      this.infoTooltipShow = isSet
      this.popperInstanceInfoIcon.update()
    },

    handleCheckList(list, key) {
      const values = list.map((item) => item.value)
      this.docsStore.techStack[key] = values
    },

    async handleClick() {
      this.docsStore.getFilteredDocs()
      await this.$nextTick()
      this.updateUrl()
    },

    transformList(values) {
      return Object.entries(values).reduce((acc, [key, value]) => {
        acc.push({
          label: key,
          value: value,
          defaultValue: this.currentTechStackKeys.includes(value)
        })

        return acc
      }, [])
    },
    updateUrl() {
      this.editIsOpen = false
      this.$router.push({
        query: {
          ...this.$route.query,
          ...this.techStack
        }
      })
    },
    formatKeyTitle(key) {
      let formattedTitle

      switch (key) {
        case 'framework':
          formattedTitle = 'Framework'
          break
        case 'ecomm':
          formattedTitle = 'eCommerce Platform'
          break
        case 'cms':
          formattedTitle = 'CMS'
          break
        case 'deployment':
          formattedTitle = 'Deployment Platform'
          break
      }

      return formattedTitle
    }
  },
  mounted() {
    this.popperInstanceInfoIcon = TooltipPlugin(
      this.$refs.infoRef,
      this.$refs.tooltipRef.$el,
      {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [25, 0]
            }
          }
        ]
      }
    )

    this.popperInstanceSelections = TooltipPlugin(
      this.$refs.usedTechsRef,
      this.$refs.usedTechsTooltipRef.$el,
      {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [25, 5]
            }
          }
        ]
      }
    )
  }
}
</script>

<style lang="scss" scoped>
.techstack-wrapper {
  border-bottom: 1px solid #cacaca;
}
</style>
