<template>
  <div
    class="max-w-[1170px] lg:mx-auto my-6 lg:my-0 px-5 flex flex-col lg:flex-row justify-between"
  >
    <div class="w-full lg:w-1/3">
      <h6>{{ title }}</h6>
      <h2>{{ description }}</h2>
    </div>
    <div class="w-full lg:w-[58%]">
      <div
        v-for="post in mappedPosts"
        :key="post.uuid"
        @click="$router.push(`/blog/${post.slug}`)"
      >
        <h6 class="text-cyan-500 mb-1">{{ post.category }}</h6>
        <div
          class="post-title flex flex-col lg:flex-row relative justify-between border-0 border-b border-solid border-cyan-500"
        >
          <h3 class="my-4 lg:mt-0 lg:mb-8">{{ post.title }}</h3>
          <div class="min-w-[138px]">
            <text-link
              :linkPath="`/blog/${post.slug}`"
              class="max-w-[130px] lg:max-w-full"
              >Read More</text-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextLink from '~/components/TextLink/TextLink'
export default {
  components: {
    TextLink
  },
  props: {
    description: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    mappedPosts: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.post-title {
  &::after {
    position: absolute;
    width: 0;
    content: '';
    height: 3px;
    left: 0;
    bottom: -2px;
    background: #00baff;
    transition: all 0.3s ease;
  }
  &:hover {
    cursor: pointer;
    &::after {
      width: 100%;
    }
  }
}
</style>
