<template>
  <div class="site-form" id="form">
    <complex-form
      v-if="fields.length > 1"
      :title="title"
      :description="description"
      :fields="fields"
      :submitText="submitText"
      :submitEndpoint="submitEndpoint"
      :successMessage="successMessage"
      :failureMessage="failureMessage"
    />
    <simple-email-form
      v-else
      :title="title"
      :description="description"
      :submitText="submitText"
      :submitEndpoint="submitEndpoint"
      :successMessage="successMessage"
      :failureMessage="failureMessage"
      :formStyle="formStyle"
    />
  </div>
</template>

<script>
import ComplexForm from '~/components/ComplexForm'
import SimpleEmailForm from '~/components/SimpleEmailForm'

export default {
  components: {
    ComplexForm,
    SimpleEmailForm
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    submitEndpoint: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: 'Success'
    },
    failureMessage: {
      type: String,
      default: 'Error'
    },
    formStyle: {
      type: String,
      default: 'dark'
    }
  }
}
</script>

<style lang="scss" scoped>
.site-form {
  display: flex;
  justify-content: center;
}
</style>
