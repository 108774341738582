<template>
  <div
    class="box-content w-auto"
    :class="[themeLoader, spacingValues(spacing)]"
  >
    <div
      class="grid grid-cols-1 mx-auto"
      :class="[
        columnGap,
        {
          'sm:grid-cols-2 lg:grid-cols-4': blocks.length === 4,
          'sm:grid-cols-2 lg:grid-cols-3': blocks.length === 3,
          'sm:grid-cols-2 lg:grid-cols-2': blocks.length === 2,
          'max-w-[1080px]': hasContainerMaxWidth
        }
      ]"
    >
      <template v-for="{ column, spacing } in blocks">
        <div :key="column._uid" :class="[spacingValues(spacing)]">
          <StoryblokResolver
            v-for="block in column"
            :block="block"
            :key="block._uid"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import formatSpacing from '~/utils/formatSpacing'
/*
 Tailwind Whitelist.  Upgrading to Tailwind 3 can let us safelist these classes via a regex pattern.
 m-1 m-2 m-3 m-4 m-5 m-8 m-10 m-20 mt-1 mt-2 mt-3 mt-4 mt-5 mt-8 mt-10 mt-20 mb-1 mb-2 mb-3 mb-4 mb-5 mb-8 mb-10 mb-20 mr-1 mr-2 mr-3 mr-4 mr-5 mr-8 mr-10 mr-20 ml-1 ml-2 ml-3 ml-4 ml-5 ml-8 ml-10 ml-20
 p-1 p-2 p-3 p-4 p-5 p-8 p-10 p-20 pt-1 pt-2 pt-3 pt-4 pt-5 pt-8 pt-10 pt-20 pb-1 pb-2 pb-3 pb-4 pb-5 pb-8 pb-10 pb-20 pr-1 pr-2 pr-3 pr-4 pr-5 pr-8 pr-10 pr-20 pl-1 pl-2 pl-3 pl-4 pl-5 pl-8 pl-10 pl-20
 gap-1 gap-2 gap-3 gap-4 gap-5 gap-6 gap-7 gap-8 gap-9 gap-10 gap-11
*/

export default {
  props: {
    blocks: { type: [Array, Object] },
    theme: {
      type: [String],
      default: 'none',
      validator: (value) => {
        return (
          [
            'quoteBlockLight',
            'quoteBlockDark',
            'caseStudySectionDark',
            'none',
            'clippedBlockLight',
            'clippedBlockDark',
            'white',
            'redGradient'
          ].indexOf(value) !== -1
        )
      }
    },
    hasContainerMaxWidth: {
      type: Boolean,
      default: false
    },
    spacing: {
      type: Array
    },
    columnGap: {
      type: String,
      default: 'gap-0'
    }
  },
  computed: {
    themeLoader() {
      return (
        {
          quoteBlockLight: 'quoteBlockLight',
          quoteBlockDark: 'quoteBlockDark',
          caseStudySectionDark: 'caseStudySectionDark',
          white: 'bg-white whiteSection',
          redGradient: 'bg-gradient-to-tr from-red-700 to-red-300'
        }[this.theme] || 'none'
      )
    }
  },
  methods: {
    spacingValues(spacing) {
      if (!spacing || !spacing.length) return []
      if (spacing[0].spacingOverride) {
        return spacing[0].spacingOverride
      }
      return Object.values(formatSpacing(spacing[0]))
    }
  }
}
</script>

<style lang="scss" scoped>
.quoteBlockLight,
.quoteBlockDark,
.clippedBlockLight,
.clippedBlockDark {
  background-color: #ffffff;
  margin: -6rem auto 0;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-top: -13rem;
  }

  &.quoteBlockDark {
    background-color: #001c27;
  }

  &.clippedBlockLight {
    padding-top: 10rem;
    padding-bottom: 4rem;
  }

  &.clippedBlockDark {
    padding-top: 10rem;
    padding-bottom: 4rem;
    background-color: #001c27;
  }
}

.caseStudySectionDark {
  background-color: #001c27;
}

.whiteSection /deep/ {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    color: #001c27;
  }
}
</style>
