<template>
  <div
    :class="{ hidden: !tooltipShow, block: tooltipShow }"
    class="bg-gray-300 border-solid border border-gray-400 block font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-sm z-10 absolute w-5/6"
    :id="id"
    role="tooltip"
  >
    <div>
      <p class="text-gray-900 px-4 py-0 text-xs tracking-[.02em]">
        {{ toolTipText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tooltip',
  props: {
    tooltipShow: {
      type: Boolean,
      default: false
    },
    toolTipText: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  }
}
</script>
