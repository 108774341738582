<template>
  <div
    class="absolute w-full h-1/4 from-[#ffffffff] to-[#ffffff00] transition-opacity duration-500 pointer-events-none"
    :class="{
      'top-0 bg-gradient-to-b': position === 'top',
      'bottom-0 bg-gradient-to-t': position === 'bottom',
      'opacity-1': show,
      'opacity-0': !show
    }"
  />
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'top'
    }
  }
}
</script>
