<template>
  <div
    class="max-w-[1170px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
  >
    <Value
      v-for="(value, index) in values"
      :key="index"
      :title="value.title"
      :description="value.description"
      :image="value.image"
    />
  </div>
</template>

<script>
import Value from '~/components/Value/Value'
export default {
  components: { Value },
  props: {
    values: {
      type: Array,
      required: true
    }
  }
}
</script>
