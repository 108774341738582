export default {
  ArrowForward: () => import('~/assets/icons/arrow-forward.svg'),
  Contentful: () => import('~/assets/icons/techstack/contentful.svg?inline'),
  CheckCircleOutline: () => import('~/assets/icons/check-circle-outline.svg'),
  Facebook: () => import('~/assets/icons/facebook.svg'),
  Gatsby: () => import('~/assets/icons/techstack/gatsby.svg?inline'),
  Netlify: () => import('~/assets/icons/techstack/netlify.svg'),
  Nextjs: () => import('~/assets/icons/techstack/nextjs.svg'),
  Nuxtjs: () => import('~/assets/icons/techstack/nuxtjs.svg'),
  React: () => import('~/assets/icons/techstack/react.svg'),
  Sanity: () => import('~/assets/icons/techstack/sanity.svg'),
  Shopify: () => import('~/assets/icons/techstack/shopify.svg'),
  Vercel: () => import('~/assets/icons/techstack/vercel.svg'),
  Vue: () => import('~/assets/icons/techstack/vue.svg'),
  AirplaneIcon: () => import('~/assets/icons/airplane-icon.svg?inline'),
  AlertbarXIcon: () => import('~/assets/icons/alertbar-x-icon.svg?inline'),
  BellIcon: () => import('~/assets/icons/bell-icon.svg?inline'),
  BlogX: () => import('~/assets/icons/blog-x.svg?inline'),
  Calendar: () => import('~/assets/icons/calendar.svg?inline'),
  DownArrowUiIcon: () => import('~/assets/icons/down-arrow-ui-icon.svg?inline'),
  ExternalLink: () => import('~/assets/icons/external-link.svg?inline'),
  Middot: () => import('~/assets/icons/ellipse.svg?inline'),
  FormatQuote: () => import('~/assets/icons/format-quote.svg?inline'),
  GrayX: () => import('~/assets/icons/gray-x.svg?inline'),
  IconEmail: () => import('~/assets/icons/icon-email.svg?inline'),
  IconFacebook: () => import('~/assets/icons/icon-facebook.svg?inline'),
  IconInfo: () => import('~/assets/icons/icon-info.svg?inline'),
  IconLinkedin: () => import('~/assets/icons/icon-linkedin.svg?inline'),
  IconTwitter: () => import('~/assets/icons/icon-twitter.svg?inline'),
  IconYoutube: () => import('~/assets/icons/icon-youtube.svg?inline'),
  IconSearch: () => import('~/assets/icons/icon-search.svg?inline'),
  KeyboardArrowLeft: () =>
    import('~/assets/icons/keyboard-arrow-left.svg?inline'),
  LinkedIn: () => import('~/assets/icons/linkedin.svg?inline'),
  Location: () => import('~/assets/icons/location.svg?inline'),
  NacelleArrowRight: () =>
    import('~/assets/icons/nacelle-arrow-right.svg?inline'),
  NacelleChevronDown: () =>
    import('~/assets/icons/nacelle-chevron-down.svg?inline'),
  NacelleChevronRight: () =>
    import('~/assets/icons/nacelle-chevron-right.svg?inline'),
  NacelleClock: () => import('~/assets/icons/nacelle-clock.svg?inline'),
  NacelleLogo: () => import('~/assets/icons/nacelle-logo.svg?inline'),
  NacelleHorizontalLight: () =>
    import('~/assets/icons/nacelle-horizontal-light.svg?inline'),
  NacelleMapmarker: () => import('~/assets/icons/nacelle-mapmarker.svg?inline'),
  NacellePlaybutton: () =>
    import('~/assets/icons/nacelle-playbutton.svg?inline'),
  NacelleQuote: () => import('~/assets/icons/nacelle-quote.svg?inline'),
  NacelleResetIcon: () =>
    import('~/assets/icons/nacelle-reset-icon.svg?inline'),
  NacelleSearchIcon: () =>
    import('~/assets/icons/nacelle-search-icon.svg?inline'),
  NacelleSearch: () => import('~/assets/icons/nacelle-search.svg?inline'),
  Play: () => import('~/assets/icons/play.svg?inline'),
  RightArrow: () => import('~/assets/icons/right-arrow.svg?inline'),
  SearchDarker: () => import('~/assets/icons/search-darker.svg?inline'),
  Twitter: () => import('~/assets/icons/twitter.svg'),
  CircleExclamation: () =>
    import('~/assets/icons/circle-exclamation.svg?inline'),
  YouTube: () => import('~/assets/icons/youtube.svg')
}
