<template>
  <div ref="searchBar" class="w-10/12 lg:w-3/5 lg:max-w-md flex border border-solid border-gray-400 bg-white rounded m-auto lg:m-0 items-center">
    <img class="m-2" alt="search icon" src="/svg/search.svg"/>
    <input id="searchInput" class="w-full px-3 py-1 border-0 rounded lg:py-2 " placeholder="Search Documentation" v-model="searchQuery" @focus="addEventListeners()" @keyup="searchDocs(searchQuery)"/>
    <div
      ref="resultsWrapper"
      v-if="searchQuery.length >= 3 && isSearching"
      class="overflow-y-scroll w-full absolute top-9 -ml-px border border-solid border-gray-400 bg-white shadow-md lg:mt-1"
    >
      <ul ref="results" v-if="searchQuery.length >= 3 && isSearching" class="w-full list-none mt-0 p-0">
        <li v-if="searchResults.nbHits === 0" id="noResults" class="m-2 lg:m-4 flex justify-center">
          <p class="text-lg text-black font-bold">Sorry, there are no search results matching.</p>
        </li>
        <nuxt-link :to="`/${result.slug}`" tag="li"
          v-for="(result, index) in searchResults.hits" :key="`${result}-${index}`"
          class="searchResult p-2 border-2 border-solid border-gray-300 rounded cursor-pointer m-2 hover:bg-gray-300 lg:m-4" @click.native="isSearching = false">
          <span class="sr-only">Link to {{result.name}}</span>
          <p class="text-lg text-black font-bold mt-0 mb-2" v-html="result._formatted.name"></p>
          <div class="text-sm text-gray-700 font-normal" v-html="result._formatted.content"></div>
          <p v-for="(tag, index) in makeTags(index)" :key="`${tag}-${index}`" class="inline-flex text-xs text-gray-500 border border-solid border-gray-500 rounded-full px-2 mx-1 mt-2 mb-0 uppercase resultTag">{{tag}}</p>
        </nuxt-link>
      </ul>
    </div>
    <button
      class="w-6 h-6 p-2 mr-2 rounded-full flex items-center justify-center bg-gray-300 border-none cursor-pointer shadow-light"
      @click="searchQuery = '', removeEventListeners(), emitClose()"
    >
      <img
        class="w-2 h-2"
        alt="close icon"
        src="/alertbar-x-icon.svg"
      />
      <span class="sr-only">Close and clear search</span>
    </button>
  </div>
</template>

<script>
import { searchDocs } from '~/plugins/meiliSearch.js'
export default {
  name: 'SearchBar',
  data() {
    return {
      searchQuery: '',
      searchResults: {},
      isSearching: false
    }
  },
  methods: {
    async searchDocs(query) {
      this.searchResults = await searchDocs(query)
    },
    emitClose() {
      this.$emit('close-search')
    },
    calcHeights() {
      let resultsWrapper = this.$refs.resultsWrapper
      let results = this.$refs.results

      if (resultsWrapper) {
        this.$nextTick(() => {
          let resultsHeight = results.getBoundingClientRect().height

          if (resultsHeight > (window.innerHeight - 100)) {
            resultsWrapper.style.height = `${window.innerHeight - 100}px`
          } else {
            resultsWrapper.style.height = `${resultsHeight + 40}px`
          }
        })
      }
    },
    makeTags(index) {
      let tagArray = []
      const result = this.searchResults.hits[index]
      const eCommerceArray = result?.ecommerce_service || []
      const cmsArray = result?.cms_service || []
      const deploymentArray = result?.deployment_service || []
      const frameworkArray = result.framework || []

      tagArray = tagArray.concat(eCommerceArray, cmsArray, deploymentArray, frameworkArray)

      return tagArray
    },
    focusTracker() {
      const el = this.$refs.searchBar
      this.isSearching = el.contains(document.activeElement)
    },
    clickTracker(event) {
      const el = this.$refs.searchBar
      if (!el.contains(event.target)) {
        this.isSearching = false
        this.removeEventListeners()
      }
    },
    addEventListeners() {
      this.searchDocs(this.searchQuery)
      document.addEventListener('focusin', this.focusTracker, false)
      document.addEventListener('click', this.clickTracker, false)
    },
    removeEventListeners() {
      document.removeEventListener('focusin', this.focusTracker, false)
      document.removeEventListener('click', this.clickTracker, false)
    }
  },
  beforeUpdate() {
    this.calcHeights()
  }
}
</script>

<style lang="scss" scoped>
  /deep/ {
    p {
      color: #000;
    }

    em {
      color: #ffffff;
      background-color: #F56789;
      font-style: normal;
      padding: 2px 3px;
    }
  }
</style>
