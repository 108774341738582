<template>
  <form @submit.prevent="submitForm">
    <div class="form-inner">
      <h2 class="form-title">{{ title }}</h2>
      <h5 class="form-description subheading">{{ description }}</h5>
      <div class="columns is-multiline">
        <form-field
          v-for="(field, index) in mappedFields"
          :key="index"
          :label="field.label"
          :option-values="field.optionValues"
          :input-type="field.inputType"
          :columns="field.columns"
          @inputchange="updateValues"
        />
      </div>

      <nacelle-button type="submit" color="secondary">{{
        submitText
      }}</nacelle-button>

      <p v-if="state === 'success'" class="message is-success">
        {{ successMessage }}
      </p>
      <p v-if="state === 'fail'" class="message is-error">
        {{ failureMessage }}
      </p>
    </div>
  </form>
</template>

<script>
import FormField from '~/components/FormField'
import NacelleButton from '~/components/NacelleButton/NacelleButton'

export default {
  components: {
    FormField,
    NacelleButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    submitEndpoint: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: 'Success'
    },
    failureMessage: {
      type: String,
      default: 'Error'
    }
  },
  data() {
    return {
      state: 'ready',
      values: {}
    }
  },
  computed: {
    mappedFields() {
      if (this.fields.length > 0) {
        return this.fields.map((field) => {
          return {
            inputType: 'text',
            label: '',
            optionValues: [],
            columns: 12,
            ...field
          }
        })
      }
      return []
    }
  },
  methods: {
    submitForm(e) {
      this.$axios({
        method: 'POST',
        url: this.submitEndpoint,
        data: { ...this.values, formType: 'lead' }
      })
        .then((res) => {
          this.state = 'success'
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          this.state = 'fail'
        })
    },
    updateValues(value) {
      this.values = {
        ...this.values,
        ...value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  padding-top: 66px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 60px;
  border-radius: 5px;
  background: linear-gradient(233.09deg, #00baff 0%, #125b76 100%);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  // max-width: 1170px;
}

.form-title {
  margin-top: 0;
}

.form-inner {
  margin: 0 auto;
  max-width: 970px;
}

.subheading {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.56px;
  line-height: 30px;
}

/deep/ .nacelle-button {
  margin-left: 0;
}

.message {
  padding-top: 1rem;
  color: #ffffff;

  &.is-error {
    background: linear-gradient(233.09deg, #f56789 9.9%, #c4103b 100%);
    background-clip: text;
    color: transparent;
  }
}
@include touch {
  form {
    padding: 20px;
    width: 100%;
    margin: 20px;
  }
  h5 {
    font-size: 16px;
  }
}
</style>
