<template>
  <div class="blog-grid-container">
    <div class="blog-grid-filter-container">
      <div class="blog-grid-filters">
        <span>Show me</span>
        <select
          name="filters"
          @change="selectFilter"
          class="focus-visible:ring-1 ring-white rounded"
        >
          <option value="All Things">All Things</option>
          <option v-for="filter in categories" :key="filter" :value="filter">
            {{ filter }}
          </option>
        </select>
      </div>
    </div>
    <div class="blog-grid">
      <resource-card
        v-for="resource in filteredResources"
        :key="resource.handle"
        v-bind="resource"
      />
    </div>
  </div>
</template>

<script>
import ResourceCard from '~/components/Resources/ResourceCard'
export default {
  components: {
    ResourceCard
  },
  data() {
    return {
      currentFilter: 'All Things',
      resources: []
    }
  },
  methods: {
    selectFilter(event) {
      this.currentFilter = event.target.value
    }
  },
  computed: {
    categories() {
      return this.resources.reduce((categories, resource) => {
        if (resource.category && !categories.includes(resource.category)) {
          categories.push(resource.category)
        }
        return categories
      }, [])
    },
    filteredResources() {
      if (this.currentFilter === 'All Things') {
        return this.resources
      } else {
        return this.resources.filter(
          (resource) => resource.category === this.currentFilter
        )
      }
    }
  },
  async fetch() {
    const version = process.env.PREVIEW ? 'draft' : 'published'
    this.resources = await this.$storyapi
      .get('cdn/stories', {
        version,
        is_startpage: 0,
        sort_by: 'first_published_at:desc',
        starts_with: 'guides-and-webinars/'
      })
      .then(
        (res) => {
          // filter through posts for only the data needed
          const mappedPosts = []
          res.data.stories.forEach((post) => {
            mappedPosts.push({
              title: post.name,
              thumbnailImage: {
                filename: post.content.thumbnail.filename,
                alt: post.content.thumbnail.alt
              },
              handle: '/' + post.full_slug,
              category: post.content.category,
              description: post.content.description
            })
          })
          return mappedPosts
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      )
  }
}
</script>

<style lang="scss" scoped>
.blog-grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .blog-grid-filter-container {
    width: 100%;
    max-width: 1170px;
    .blog-grid-filters {
      color: #ffffff;
      font-size: 22px;
      letter-spacing: 0.5px;
      line-height: 30px;
      align-self: flex-start;
      margin: 30px;
      select {
        width: auto;
        height: 100%;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.39px;
        line-height: 28px;
        cursor: pointer;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #00baff;
        color: #00baff;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 10px;
        background-image: url('/nacelle-chevron-down.svg');
        background-repeat: no-repeat;
        background-position: 95%;
        padding-right: 45px;
      }
    }
  }
  .blog-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1170px;
    @include touch {
      width: 312px;
    }
  }
}
</style>
