<template>
  <button
    class="font-medium rounded-md flex items-center hover:cursor-pointer"
    :class="{
      'py-[6px] px-3 text-xs h-[28px]': size === 'small',
      'py-[10px] px-4 text-sm h-[40px]': size === 'medium',
      'py-[12px] px-8 text-lg h-[52px]': size === 'large',
      'bg-red-700 text-white border-none hover:bg-red-900':
        variant === 'primary',
      'bg-[#DFDFDF] text-cyan-900 border-none hover:bg-[#848484]':
        variant === 'secondary',
      'bg-cyan-900 text-white border-solid border-cyan-900 hover:text-cyan-900 hover:bg-white':
        variant === 'primaryDark',
      'bg-[#848484] text-white border-none hover:text-cyan-900 hover:bg-[#E0E0E0]':
        variant === 'secondaryDark'
    }"
    @click="handleClick"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => {
        return ['small', 'medium', 'large'].indexOf(value) !== -1
      }
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return (
          ['primary', 'primaryDark', 'secondary', 'secondaryDark'].indexOf(
            value
          ) !== -1
        )
      }
    },
    buttonPath: {
      type: Object,
      default: () => {}
    },
    newTab: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      if (this.buttonPath.cached_url) {
        if (this.newTab) {
          window.open(this.buttonPath.cached_url, '_blank')
        } else if (this.buttonPath.cached_url.includes('http')) {
          window.open(this.buttonPath.cached_url, '_self')
        } else {
          this.$router.push(`/${this.buttonPath.cached_url}`)
        }
      }
      this.$emit('click')
    }
  }
}
</script>
