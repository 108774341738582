const theme = {
  formTextColor: 'text-cyan-900',
  fieldTextColor: 'text-gray-700',
  fieldPlaceholder: 'placeholder-gray-500 hover:placeholder-cyan-300',
  background: {
    default: 'bg-white',
    hover: 'hover:bg-cyan-50'
  },
  sharedWidth: 'w-[calc(100%-22px)]'
}

export default {
  form: `w-full max-w-[550px] py-[30px] px-[60px] rounded-[5px] mx-auto shadow-[0,0,15px,0,rgba(0,0,0,0.3)] bg-transparent text-base ${theme.formTextColor}`,
  title: `mt-0 mb-4 text-xl ${theme.formTextColor}`,
  description: `mb-10 text-base ${theme.formTextColor}`,
  formField: {
    wrapper: 'w-full block mb-2',
    fields: {
      shared: `p-3 my-0 appearance-none border-none rounded text-[13px] md:text-[16px] leading-[26px] ring-2 ring-transparent ring-inset focus:ring-cyan-100 active:ring-cyan-100 transition-all duration-100 ${theme.background.default} ${theme.background.hover}`,
      checkbox: {
        wrapper: 'border-0 pl-0',
        box: 'relative w-[18px] h-[18px] rounded-[2px] mr-4 flex items-center justify-center',
        border: `border border-solid border-white ring-2 ring-transparent active:ring-cyan-100 focus:ring-cyan-100 hover:ring-cyan-100 peer-hover:ring-2 peer-hover:ring-cyan-100`,
        backgroundColor: { checked: 'bg-white', unchecked: 'bg-transparent' },
        focus: 'ring-2 ring-cyan-100',
        checkmarkColor: 'border-cyan-300',
        label: 'peer text-cyan-900'
      },
      input: `${theme.sharedWidth} ${theme.fieldTextColor} ${theme.fieldPlaceholder}`,
      select: {
        el: `py-3 pl-3 pr-10 w-full relative`,
        selectDropdown: {
          wrapper:
            'w-full h-auto z-10 overflow-hidden relative rounded-md shadow-lg',
          menu: `w-full bg-white max-h-60 p-0 m-0 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm list-none select-none cursor-pointer`
        },
        selectDropdownItems: {
          el: 'text-black flex flex-row justify-between px-4 py-2 first:pt-4 last:pb-4 transition-all duration-100',
          selected: 'bg-black/20 text-black font-bold',
          unselected: 'font-normal bg-transparent hover:bg-black/10 ',
          placeholder: 'text-black/50 hover:bg-transparent'
        },
        selectOptionsText: {
          placeholder: 'text-gray-500',
          textColor: theme.fieldTextColor,
          error: 'text-red-500'
        },
        selectDropdownIcon: {
          el: 'absolute right-[20px] top-[40%] w-3 h-3',
          placeholder: 'text-gray-500',
          selection: 'text-gray-700',
          active: 'text-cyan-300',
          error: 'text-red-500'
        }
      },
      textarea: `font-inter text-[16px] ${theme.sharedWidth} ${theme.fieldTextColor} ${theme.fieldPlaceholder}`,
      simple: `min-w-[300px] ${theme.sharedWidth} sm:border-r-0 sm:rounded-r-none ${theme.fieldTextColor} ${theme.fieldPlaceholder}`,
      error: {
        el: `placeholder-red-500 text-red-500 focus:text-gray-700 active:text-gray-700 !ring-red-500 !focus:ring-red-500`,
        icon: `w-5 h-5 absolute right-[16px] top-[30%] text-red-500`
      },
      disabled: 'opacity-50 pointer-events-none cursor-not-allowed',
      hint: {
        el: `relative flex items-center text-white`,
        icon: 'mr-1 w-4 h-4'
      },
      htmlText: {
        em: 'text-[12px] text-cyan-900 !not-italic',
        a: '!text-cyan-900'
      }
    }
  },
  button: {
    normal: ``,
    simple:
      'flex justify-center sm:justify-between items-center px-6 rounded-[4px] sm:rounded-l-none h-[50px] text-base font-bold text-white bg-cyan-500 hover:bg-cyan-700 active:bg-cyan-300 focus:ring-2 focus:ring-cyan-100 focus:ring-inset border-none cursor-pointer',
    icon: 'ml-4 hidden'
  },
  successMessage: `p-6 text-lg w-auto text-center ${theme.formTextColor}`,
  failureMessage: `p-6 text-lg w-auto text-center ${theme.formTextColor}`
}
