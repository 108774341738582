<template>
  <div class="relative" :style="[heroBgImageStyles]">
    <div
      v-if="hasGradient || hasSolidColor"
      :class="{
        'hero-gradient-background': hasGradient,
        'hero-solid-color-background': hasSolidColor
      }"
      :style="heightStyles"
    ></div>
    <div
      class="hero"
      :class="{
        [heroBgImageClasses]: hasBgImage,
        'bg-transparent': background === 'Basic',
        'hero-solid': hasSolidColor,
        'min-h-[400px]': hasGradient,
        'hero-with-image': imageSrc
      }"
      :style="heightStyles"
    >
      <div class="hero-container hero-small-container">
        <div>
          <p v-if="eyebrow" class="eyebrow">{{ eyebrow }}</p>
          <h1 class="page-title" v-html="headingFormatter">
            {{ headingFormatter }}
          </h1>
          <div
            v-html="typeof content === 'string' ? content : richTextContent"
            class="sub-heading"
          ></div>
          <site-form
            class="hero-site-form mr-8"
            v-if="!isEmpty(siteForm)"
            v-bind="siteForm"
          />
          <marketo-form
            class="hero-marketo-form"
            v-if="!isEmpty(marketoFormProps)"
            v-bind="marketoFormProps"
          />
          <simple-email-link
            class="hero-simple-link"
            v-if="!isEmpty(simpleEmailLinkProps)"
            v-bind="simpleEmailLinkProps"
          />
          <text-link v-if="linkPath" :linkPath="linkPath" :color="linkColor">{{
            linkText
          }}</text-link>
          <nacelle-button
            v-if="buttonText"
            :buttonPath="buttonPath"
            :color="buttonColor"
            @click="scrollToElement"
            >{{ buttonText }}</nacelle-button
          >
        </div>
      </div>

      <div v-if="videoSrc" class="hero-image">
        <img :src="imageSrc" :alt="imageAlt" />
        <video autoplay playsinline muted loop class="hero-video">
          <source :src="videoSrc" type="video/mp4" />
        </video>
      </div>
      <div v-else class="hero-image">
        <img :src="imageSrc" :alt="imageAlt" />
      </div>
    </div>
  </div>
</template>

<script>
import SiteForm from '~/components/SiteForm'
import MarketoForm from '~/components/MarketoForm'
import SimpleEmailLink from '~/components/SimpleEmailLink'
import TextLink from '~/components/TextLink/TextLink'
import NacelleButton from '~/components/NacelleButton/NacelleButton'
import { scrollToCustomElement } from '~/utils/scroll'

export default {
  name: 'Hero',
  props: {
    eyebrow: {
      type: String,
      required: false,
      default: ''
    },
    heading: {
      type: String,
      required: false,
      default: ''
    },
    content: {
      type: [Object, String],
      required: false,
      default: ''
    },
    linkText: {
      type: String,
      required: false,
      default: ''
    },
    linkPath: {
      type: String,
      required: false,
      default: ''
    },
    linkColor: {
      type: String,
      required: false,
      default: 'primary',
      validator: (value) => ['primary', 'secondary'].includes(value)
    },
    buttonText: {
      type: String,
      required: false,
      default: ''
    },
    buttonPath: {
      type: String,
      required: false,
      default: ''
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'primary',
      validator: (value) => ['primary', 'secondary'].includes(value)
    },
    videoSrc: {
      type: String,
      required: false,
      default: ''
    },
    imageSrc: {
      type: String,
      required: false,
      default: ''
    },
    imageAlt: {
      type: String,
      required: false,
      default: ''
    },
    backgroundImageSrc: {
      type: String,
      required: false,
      default: ''
    },
    siteForm: {
      type: Object,
      required: false,
      default: () => ({})
    },
    marketoFormProps: {
      type: Object,
      required: false,
      default: () => ({})
    },
    simpleEmailLinkProps: {
      type: Object,
      required: false,
      default: () => ({})
    },
    useOutlineStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    background: {
      type: String,
      validator: (value) =>
        ['Basic', 'Gradient', 'Solid Color', 'Image'].includes(value),
      default: 'Basic'
    },
    backgroundHeight: {
      type: String,
      required: false,
      default: ''
    },
    scrollToId: {
      type: String,
      default: ''
    },
    storyFields: {
      type: Object,
      required: true
    }
  },
  components: {
    SiteForm,
    TextLink,
    MarketoForm,
    SimpleEmailLink,
    NacelleButton
  },
  computed: {
    heroBgImageClasses() {
      const baseClasses = 'm-0 p-8 md:pl-[152px] md:pb-[85px] md:mx-[50px]'
      const heightClasses = !this.backgroundHeight
        ? 'min-h-[500px] lg:min-h-[700px]'
        : null
      return [heightClasses, baseClasses].join(' ')
    },
    heroBgImageStyles() {
      if (!this.hasBgImage) return ''
      return {
        background: `url(${this.backgroundImageSrc})`,
        backgroundSize: 'cover'
      }
    },
    heightStyles() {
      return {
        height: this.backgroundHeight ? `${this.backgroundHeight}px` : ''
      }
    },
    headingFormatter() {
      const split = this.heading.split('.')
      if (split.length > 1 && this.useOutlineStyle) {
        return `<span class="outline">${split[0]}</span> ${split[1]}`
      }
      return this.heading
    },
    richTextContent() {
      return this.content
        ? this.$storyapi.richTextResolver.render(this.content)
        : ''
    },
    hasBgImage() {
      return this.background === 'Image' && this.backgroundImageSrc
    },
    hasSolidColor() {
      return this.background === 'Solid Color'
    },
    hasGradient() {
      return this.background === 'Gradient'
    }
  },

  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
    scrollToElement() {
      if (this.scrollToId) {
        scrollToCustomElement(`#${this.scrollToId}`)
        return
      }
      if (this.storyFields.component === 'Resource') {
        scrollToCustomElement('[id^="siteform-"]')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-gradient-background {
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  height: calc(100% + 200px);
  z-index: -2;
  background: linear-gradient(163.72deg, #1e2748 0%, #0e1328 100%);
}
.hero-solid-color-background {
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  height: calc(100% + 200px);
  z-index: -2;
  background: #00baff;
}
.hero-solid {
  h1 {
    color: #fff !important;
  }
}
.hero-with-image {
  max-width: 1170px;
  margin: 0 auto;
  div {
    flex: 50%;
  }
  .hero-image {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .hero-video {
    position: absolute;
    top: 8%;
    max-width: 44.5%;
    z-index: -1;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  /deep/ .site-form {
    justify-content: flex-start;
  }
  @include touch {
    flex-direction: column;
  }
}
.hero-layout,
.hero-solid {
  /deep/ {
    a {
      span {
        color: #fff;
      }
      img {
        filter: none;
      }
      &:hover {
        span {
          border-bottom: 1px #fff solid;
        }
      }
    }
    input {
      border: 1px solid #ffffff !important;
      background-color: #d8d8d83f;
      &::placeholder {
        color: #fff;
      }
    }
    button {
      background-color: #ffffff;
      border: 1px solid #ffffff;
      border-left: 0;
      color: #00baff;

      &:hover,
      &:focus {
        background-color: #22346b;
        color: #ffffff;
      }
    }
    h6 {
      display: none;
    }
  }
}
.hero {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .hero-container {
    max-width: 770px;
    ::v-deep h1 {
      .outline {
        color: white;
        -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #00baff;
        display: block;
      }
    }
  }
  .hero-image-container {
    display: block;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  h6 {
    margin: 0 0 20px 0;
  }
  .hero-site-form /deep/ form {
    margin: 20px 0 40px;
  }
}

//Marketo Form
.hero-marketo-form /deep/ .simple-form {
  justify-content: flex-start;
  margin-top: 20px;
  @include touch {
    form {
      margin: 20px 0 40px;
    }
  }
}
.hero-solid .hero-marketo-form /deep/ .simple-form {
  input {
    border: 1px solid #ffffff !important;
    background-color: #d8d8d83f !important;
    &::placeholder {
      color: #fff;
    }
  }
  .mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton {
    background-color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-left: 0 !important;
    color: #00baff;

    &:hover,
    &:focus {
      background-color: #22346b !important;
      color: #ffffff;
    }
  }
}

//Simple Email Link Form
/deep/ .simple-email-form-container {
  justify-content: flex-start !important;
  margin: 20px 0 0 !important;
  @include touch {
    form {
      margin: 20px 0 40px;
    }
  }
}
.hero-solid /deep/ .simple-email-form-container {
  form /deep/ input {
    border: 1px solid #ffffff !important;
    background-color: #d8d8d83f !important;
    &::placeholder {
      color: #fff;
    }
  }
  button {
    background-color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-left: 0 !important;
    color: #00baff;

    &:hover,
    &:focus {
      background-color: #22346b !important;
      color: #ffffff;
    }
  }
}

// mobile
@include touch {
  .hero {
    padding: 20px 0px;
    margin: 0px 31px 40px;
  }
  .hero-image {
    margin-top: 30px;
  }
  /deep/ .form-inner {
    margin: 0;
  }

  .hero-solid {
    margin-bottom: 0;
    /deep/ {
      button {
        border: 1px solid #fff !important;
      }
    }
  }
}
</style>
