<template>
  <div class="py-2 align-middle inline-block w-full">
    <div class="shadow overflow-auto">
      <table class="min-w-full border-collapse divide-y divide-gray-200">
        <thead class="bg-gray-300">
          <tr>
            <th
              v-for="heading in body.table.thead"
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider"
              :key="heading._uid"
            >
              {{ heading.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in body.table.tbody"
            :class="rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-200'"
            :key="row._uid"
          >
            <td
              v-for="cell in row.body"
              class="px-6 py-4 text-sm font-medium text-gray-700"
              :key="cell._uid"
              v-html="cell.value"
            />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: Object,
      required: true
    }
  }
}
</script>
