const customDataTypes = {
  'SFSelect': 'select',
  'SFCheckbox': 'checkbox',
  'SFTextarea': 'textarea'
}

export default (block, storyFields) => {
  const form = block.formType.length && block.formType[0]

  const isMarketo = 'marketoForm' in form && !!form.marketoForm.marketoFormId
  return {
    ...form,
    name: form.name.replace(' ', ''),
    fields: isMarketo
      ? form.marketoForm.marketoFormFields.map(mapMarketoFields)
      : form.fields?.map(mapCustomFields),
    id: isMarketo ? form.marketoForm.marketoFormId : form._uid,
    isMarketo,
    formSubmitResources: (() => {
      if (form.formSubmitResources?.length) {
        return form.formSubmitResources[0].resourceLink
      } else if (storyFields && storyFields.category === 'Webinar' && 'webinarLink' in storyFields) {
        return storyFields.webinarLink
      } else {
        return null
      }
    })() 
  }
}

function mapMarketoFields(field) {
  const _field = {
    ...field,
    label: field.label?.replace(':', ''),
    optionValues: field.fieldMetaData?.values || []
  }
  return _field
}

function mapCustomFields(field) {
  const _field = {
    ...field,
    dataType: field.component === 'SFInput' ? field.inputType : customDataTypes[field.component]
  }
  delete field.inputType
  return _field
}