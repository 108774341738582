import Vue from 'vue'
import BaseText from "~/components/V2/Base/BaseText"
import BaseButton from "~/components/V2/Base/BaseButton/BaseButton"
// List Components
const components = {
    ...BaseText,
    BaseButton
}
// Register components
for (const name in components) {
  Vue.component(name, components[name])
}