const spacingMap = {
  margin: 'm',
  marginTop: 'mt',
  marginRight: 'mr',
  marginBottom: 'mb',
  marginLeft: 'ml',
  padding: 'p',
  paddingTop: 'pt',
  paddingRight: 'pr',
  paddingBottom: 'pb',
  paddingLeft: 'pl'
}

export default function formatSpacing(spacing = {}) {
  return Object.entries(spacing).reduce((acc, [key, val]) => {
    if (!/^margin|padding/.test(key)) return acc
    acc[key] = val ? `${spacingMap[key]}-${val}` : ''
    return acc
  }, {})
}
