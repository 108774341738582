<template>
  <div class="flex flex-wrap justify-center mt-20 mb-16">
    <div
      class="h-[70px] py-2 px-5 flex items-center justify-center border border-solid border-[#EAE9F2] rounded m-[10px] min-w-[296px]"
      v-for="card in logoCards"
      :key="card._uid"
    >
      <img
        :src="card.image.filename"
        :alt="card.image.alt"
        class="max-h-full"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logoCards: {
      type: Array,
      default: () => []
    }
  }
}
</script>
