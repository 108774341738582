var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSection',{attrs:{"bg-color":_vm.bgColor,"bg-hex":_vm.backgroundHex,"bg-image":_vm.backgroundImage.filename,"bg-position":_vm.backgroundPosition,"bg-cover":_vm.backgroundCover,"padding":"px-10 py-16"}},[_c('div',{staticClass:"flex",class:{
      'flex-row-reverse': _vm.type === 'contentRight',
      'flex-row': _vm.type === 'contentLeft'
    }},[_c('div',{class:{
        'w-1/2': _vm.isTwoCol,
        'w-full': _vm.isSingleCol,
        'w-2/5': _vm.isNarrowTwoCol,
        'max-w-2xl w-full text-center mx-auto': _vm.type === 'singleCentered'
      }},[_c('div',{class:{
          'ml-20': _vm.type === 'contentRight',
          'mr-20': _vm.type === 'contentLeft' || _vm.isNarrowTwoCol,
          'pt-20': _vm.isTwoCol && _vm.illustration.filename
        }},[_c('Heading',{staticClass:"mb-10",attrs:{"type":"heading"}},[(_vm.isMulticolorHeading)?_c('SplitHeading',{attrs:{"heading":_vm.header}}):_c('span',[_vm._v(_vm._s(_vm.header))])],1),_vm._v(" "),_c('RichTextRenderer',{attrs:{"document":_vm.copy}}),_vm._v(" "),(_vm.cta.length)?_c('Cta',{class:{ 'mx-auto': _vm.type === 'singleCentered' },attrs:{"cta":_vm.cta[0]}}):_vm._e()],1)]),_vm._v(" "),(_vm.numberCards.length && (_vm.isTwoCol || _vm.isNarrowTwoCol))?_c('div',{class:{
        'w-1/2': _vm.isTwoCol,
        'w-3/5': _vm.isNarrowTwoCol
      }},[_c('NumberCards',{attrs:{"number-cards":_vm.numberCards}})],1):_vm._e(),_vm._v(" "),(_vm.iconCards.length && (_vm.isTwoCol || _vm.isNarrowTwoCol))?_c('div',{class:{
        'w-1/2': _vm.isTwoCol,
        'w-3/5': _vm.isNarrowTwoCol
      }},[_c('IconCards',{attrs:{"icon-cards":_vm.iconCards}})],1):_vm._e(),_vm._v(" "),(_vm.illustration.filename && (_vm.isTwoCol || _vm.isNarrowTwoCol))?_c('div',{staticClass:"flex justify-center",class:{
        'w-1/2': _vm.isTwoCol,
        'w-3/5': _vm.isNarrowTwoCol
      }},[_c('img',{staticClass:"max-w-full",attrs:{"src":_vm.illustration.filename,"alt":_vm.illustration.alt}})]):_vm._e()]),_vm._v(" "),(_vm.logoGrid.length)?_c('LogoGrid',{attrs:{"logo-cards":_vm.logoGrid[0].logoCard}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }