import {
  convertDateTimeToUserTimezone,
  getTimeStampFromUTC
} from '~/utils/dateTime'

const mapEvent = (event) => {
  const timeStamp = getTimeStampFromUTC(event.content.dateTime)
  return {
    title: event.name,
    image: {
      filename: event.content.image ? event.content.image.filename : '',
      alt: event.content.image ? event.content.image.alt : ''
    },
    slug: event.full_slug,
    category: event.content.category,
    // event.content.dateTime always comes back in UTC time from Storyblok, regardless of user's timezone selected in SB
    timeStamp,
    dateTime: convertDateTimeToUserTimezone(
      timeStamp,
      'MMM. dd, yyyy @ h:mm a zzz'
    ),
    description: event.content.description,
    location: event.content.location,
    uuid: event.uuid
  }
}

export { mapEvent }
