<template>
  <div class="w-11/12 m-auto">
    <iframe :src="iframeSrc"
      style="width:100%; border:0; border-radius: 4px; overflow:hidden;"
      title="festive-danilo-s9y5o"
      allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
      sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
      class="h-screen"
      >
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'Sandbox',
  props: {
    iframeSrc: {
      type: String,
      required: true
    },
  }
}
</script>
