<template>
  <div>
    <nuxt-link :to="'/' + url" class="no-underline">
      <div
        class="wrapper relative max-w-[524px] h-[400px] lg:h-[476px] mx-auto lg:mx-10 mt-5 lg:mt-8 mb-10 lg:mb-[100px]"
      >
        <div
          class="icon-bar absolute top-0 left-0 min-w-[73px] w-[20%] h-[75%] z-40 bg-cyan-900"
        >
          <div class="flex justify-center relative h-full">
            <div class="z-50 mt-[15%] mx-[10px] lg:mx-auto h-[18%]">
              <img class="logo z-50 max-w-full m-h-full" :src="logoSrc" />
            </div>
            <div class="brand">
              {{ brandName }}
            </div>
          </div>
        </div>
        <div class="description w-full h-full grid">
          <div class="bottom-container">
            <div class="text-box">
              {{ description }}
              <img
                v-if="url"
                class="arrow absolute z-50 bottom-6 right-8"
                src="/nacelle-arrow-right.svg"
                alt="arrow-right"
              />
            </div>
          </div>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      required: true
    },
    brandName: {
      type: String,
      required: true
    },
    logoSrc: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  grid-template-rows: 11% auto;
}
.icon-bar {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
}
.logo {
  filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
    brightness(102%) contrast(104%);
}
.brand {
  position: absolute;
  bottom: 40%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(233.09deg, #f56789 9.9%, #c4103b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  width: 300px;
  font-size: 22px;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
.bottom-container {
  grid-row-start: 2;
  display: grid;
  width: 100%;
  height: 89%;
  grid-template-columns: 11% auto;
}
.text-box {
  position: relative;
  grid-column-start: 2;
  background: linear-gradient(
      233.09deg,
      rgba(0, 186, 255, 0.2) 0%,
      rgba(18, 91, 118, 0.2) 100%
    ),
    #001c27;
  padding-top: 9%;
  padding-right: 7%;
  padding-bottom: 9%;
  padding-left: 21%;
  color: #ffffff;
  font-size: 28px;
  letter-spacing: 0.88px;
  line-height: 40px;
  border-radius: 5px;
  @include touch {
    font-size: 20px;
    letter-spacing: 0.63px;
    line-height: 26px;
  }
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background: linear-gradient(
        233.09deg,
        rgba(0, 186, 255, 0.2) 0%,
        rgba(18, 91, 118, 0.2) 100%
      ),
      #001c27;
  }
}
.wrapper:hover {
  .text-box {
    background: linear-gradient(233.09deg, #00baff 0%, #125b76 100%);
    transition: all 0.08s linear;
  }
  img {
    filter: brightness(0) invert(1);
    transition: all 0.08s linear;
  }
}
.arrow {
  filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
    brightness(102%) contrast(104%); //Makes color #00BAFF (Blue)
}

@include touch {
  .brand {
    font-size: 16px;
    bottom: 48%;
  }
  .text-box {
    font-size: 20px;
    letter-spacing: 0.63px;
    line-height: 26px;
  }
}
</style>
