<template>
  <div class="grid grid-cols-2 gap-4">
    <BaseCard
      v-for="(card, index) in iconCards"
      :key="index"
      :image="card.image"
      :heading="card.heading"
      :paragraph="card.paragraph"
      type="icon"
      :class="{ 'col-span-2': spanFirstCard(index) }"
    />
  </div>
</template>

<script>
import BaseCard from '~/components/V2/Base/BaseCard/BaseCard.vue'

export default {
  components: {
    BaseCard
  },
  props: {
    iconCards: {
      type: Array,
      required: true
    }
  },
  methods: {
    spanFirstCard(index) {
      return !index && [1, 3].includes(this.iconCards.length)
    }
  }
}
</script>
