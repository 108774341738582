<template>
  <Portal v-if="hasAlreadySelected === 'initialized'" to="gdpr">
    <div
      :class="`${
        modalType === 'center' ? 'justify-center' : null
      } fixed flex items-center z-[101] inset-x-0 bottom-0 mb-24`"
      @keydown.esc="closeModal"
    >
      <div class="flex w-10/12 h-auto max-w-screen-xl z-[102] bg-white bg-opacity-100 rounded shadow items-center justify-center flex-col lg:flex-row lg:h-64">

        <div class="relative w-2 h-60 left-1 bg-red-700 rounded-sm hidden lg:block"></div>

        <div class="justify-end self-baseline py-12 hidden lg:flex w-1/6">
          <img
            class="w-10 h-10"
            src="/nacelle-logo.svg"
            alt="Nacelle Logo"
          />
        </div>

        <div class="w-3/4 py-8 lg:pl-3 lg:pr-12">
          <div class="flex lg:hidden items-center justify-center">
            <img
              class="w-10 h-10 inline-flex"
              src="/nacelle-logo.svg"
              alt="Nacelle Logo"
            />
            <p class="text-xl md:text-2xl text-black inline-flex">How Nacelle Uses Cookies</p>
          </div>
          <p class="text-xl md:text-2xl text-black hidden lg:block">How Nacelle Uses Cookies</p>
          <p class="text-l text-black"> We use cookies to improve user experience and analyze website traffic. By clicking "Accept", you agree to our website's cookie use as described in our <nuxt-link to="/privacy-policy"> Privacy Policy</nuxt-link></p>
        </div>

        <div class="w-1/2 flex flex-col justify-evenly md:w-1/3 lg:w-1/4">
          <button
            ref="acceptCookiesRef"
            class="flex justify-center bg-red-500 hover:bg-red-700 cursor-pointer rounded z-10 focus:ring-2 ring-white border-0 shadow h-10 items-center m-4 lg:ml-0 lg:mr-4 xl:mr-8"
            @click="acceptCookies"
            aria-label="close"
          >
            <p>Accept Cookies</p>
          </button>

          <button
            ref="declineCookiesRef"
            class="flex justify-center bg-white hover:bg-red-50 cursor-pointer rounded z-10 focus:ring-2 ring-white border border-red-500 h-10 items-center m-4 lg:ml-0 lg:mr-4 xl:mr-8"
            @click="declineCookies"
            aria-label="close"
          >
            <p class="text-red-500">Decline Cookies</p>
          </button>

        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import { Portal } from 'portal-vue'
import { get, set, clear } from 'idb-keyval'

export default {
  components: {
    Portal
  },
  data() {
    return {
      hasAlreadySelected: null
    }
  },
  props: {
    modalType: {
      type: String,
      default: 'center',
      validator: (val) => ['center', 'left']
    }
  },
  methods: {
    acceptCookies() {
      window.analytics.load(process.env.SEGMENT_SOURCE, { integrations: { All: true}})
      clear();
      set('status', 'accepted')
      this.hasAlreadySelected = 'accepted'
    },
    declineCookies() {
      window.analytics.load(process.env.SEGMENT_SOURCE, { integrations: { All: false}})
      clear();
      set('status', 'declined')
      this.hasAlreadySelected = 'declined'
    }
  },
    async mounted() {
      if (process.client) {
        const status =  await get('status')
        status === 'accepted' ||  status === 'declined' ? this.hasAlreadySelected = status : this.hasAlreadySelected = 'initialized'
      }
  }
}
</script>
