export default (block) => {
  const mappedPosts = block.posts.map((post) => {
    return {
      category: post.content?.category,
      title: post.content?.title,
      uuid: post.uuid,
      slug: post.slug
    }
  })

  block.mappedPosts = mappedPosts

  return block
}