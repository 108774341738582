<template>
  <div class="form-field" :class="dynamicClasses">
    <label class="sr-only">{{ label }}</label>
    <component
      :is="inputComponent"
      :optionValues="optionValues"
      :label="label"
      @inputchange="emitInput"
    />
  </div>
</template>

<script>
import TextInput from '~/components/TextInput'
import EmailInput from '~/components/EmailInput'
import PhoneInput from '~/components/PhoneInput'
import SelectInput from '~/components/SelectInput'
import TextareaInput from '~/components/TextareaInput'

export default {
  components: {
    TextInput,
    EmailInput,
    PhoneInput,
    SelectInput,
    TextareaInput
  },
  props: {
    inputType: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    optionValues: {
      type: Array,
      default: () => []
    },
    columns: {
      type: [Number, String],
      default: 12
    }
  },
  computed: {
    inputComponent() {
      switch (this.inputType) {
        case 'email':
          return 'EmailInput'
        case 'phone':
          return 'PhoneInput'
        case 'select':
          return 'SelectInput'
        case 'textarea':
          return 'TextareaInput'
        default:
          return 'TextInput'
      }
    },
    dynamicClasses() {
      return `column is-${this.columns}`
    },
    labelKey() {
      return this.label.replace(
        /(?:^\w|[A-Z]|\b\w|\s+)/g,
        function (match, index) {
          if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
          return index === 0 ? match.toLowerCase() : match.toUpperCase()
        }
      )
    }
  },
  methods: {
    emitInput(value) {
      const obj = {}

      obj[this.labelKey] = value

      this.$emit('inputchange', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-field {
  box-sizing: border-box;
  padding-bottom: 23px;
}

.form-field /deep/ input,
.form-field /deep/ textarea {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: transparent;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 23px;
  color: #ffffff;
  box-sizing: border-box;

  &::placeholder {
    opacity: 0.6;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 23px;
  }
}

.form-field /deep/ select {
  appearance: none;
  padding-left: 24px;
  padding-right: 24px;
  height: 41px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #22346b;
  border-radius: 5px;
  background-color: #3955b4;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 23px;
  color: #ffffff;
  box-sizing: border-box;
}

.form-field /deep/ input {
  padding-top: 8px;
  padding-bottom: 8px;
}

.form-field /deep/ textarea {
  padding-top: 9px;
  padding-bottom: 9px;
  min-height: 120px;
}
@include touch {
  .form-field /deep/ input,
  .form-field /deep/ textarea {
    border-radius: 5px;
    padding-left: 17px;
    padding-right: 17px;
    width: 100%;
    height: 38px !important;
    font-size: 13px !important;
    letter-spacing: 0.36px;
    line-height: 16px !important;
    &::placeholder {
      opacity: 0.6;
      color: #ffffff;
      font-size: 13px !important;
      letter-spacing: 0.36px;
      line-height: 16px;
    }
  }
}
</style>
