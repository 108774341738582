<template>
  <div>
    <button
      :id="menu.id"
      aria-haspopup="true"
      ref="menuButtonRef"
      :aria-expanded="isOpen.toString()"
      :aria-controls="`${menu.name.toLowerCase().replace(' ', '-')}-${menu.id}`"
      @click.prevent="toggleMenu(menu)"
      :class="`cursor-pointer py-2 px-0 flex justify-between items-center w-full text-left ${
        isOpen ? 'font-bold text-gray-900' : ''
      }`"
    >
      {{ menu.name }}

      <img
        src="/nacelle-chevron-right.svg"
        alt="A Chevron icon"
        aria-hidden="true"
        :class="`${
          isOpen ? 'rotate-90' : ''
        } transform transition ease-in-out duration-150 mr-1`"
      />
    </button>

    <transition name="fade">
      <ul
        v-show="isOpen"
        @keydown.esc.stop="closeMenu"
        :id="`${menu.name.toLowerCase().replace(' ', '-')}-${menu.id}`"
        class="list-none border-solid border-l-2 border-r-0 border-b-0 border-t-0 border-gray-300 pl-4"
      >
        <DocsNavigationMenuItem
          :nodes="menu"
          :depth="depth + 1"
          :is-parent-open="isOpen"
        />
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DocsNavigationSubmenu',
  components: {
    DocsNavigationMenuItem: () => import('./DocsNavigationMenuItem.vue')
  },
  props: {
    menu: {
      type: Object,
      required: true
    },
    depth: {
      type: Number,
      required: true
    },
    isParentOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  watch: {
    isParentOpen: function () {
      if (!this.isParentOpen) {
        this.isOpen = false
      }
    }
  },
  methods: {
    toggleMenu(menu) {
      // format menu name to match ID
      const activeMenu = `${menu.name}-${menu.id}`
        .toLowerCase()
        .replace(' ', '-')

      this.isOpen = !this.isOpen
      if (this.isOpen) {
        // $nextTick here to wait for the DOM to render the previously hidden UL before we can grab it and focus the first element
        this.$nextTick(() => {
          this.$nextTick(() => {
            const subMenu = document.getElementById(activeMenu)
            const firstItem = subMenu.querySelector('a, button')

            firstItem?.focus()
          })
        })
      }

      // Focus the initial menu trigger if closing a menu
      if (!this.isOpen) {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.menuButtonRef?.focus()
          })
        })
      }
    },

    async closeMenu() {
      this.isOpen = false

      await this.$nextTick()
      await this.$nextTick()
      this.$refs.menuButtonRef?.focus()
    },

    openParentOnLoad() {
      if ($nuxt.$route.path.includes(this.menu.real_path)) {
        this.isOpen = true
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.openParentOnLoad()
    })
  }
}
</script>

<style lang="scss" scoped>
/*-- These styles are applied here because doing so with Tailwinds @apply and just applying a class does not work as expected with this being a recursive component --*/
button,
a {
  font-size: 14px;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  color: #6b6f71;
  display: block;

  &:focus-visible {
    border-radius: 5px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 186, 255, var(--tw-ring-opacity));
  }
}

.fade-enter-active {
  transition: 150ms ease-in;
}

.fade-leave-active {
  transition: 0ms;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-5%);
}

.nuxt-link-active {
  color: rgba(197, 16, 59, 1);
  font-weight: bold;
}
</style>
