<template>
<div class="bg-cyan-900">
   <Navigation :navItems="mainNav" />
</div>

</template>

<script>
import { mapState } from 'pinia'
import { useGlobalStore } from '~/store/global'
import Navigation from "~/components/V2/Navigation/Navigation"

export default {
  components: {
    Navigation
  },
  props: {
    test: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrolled: false,
      menuOpen: false,
      simpleNav: true
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(useGlobalStore, [
      'loaded',
      'logo',
      'mainNav',
    ]),
    getLinkList() {
        return this.mainNav
    },
  },
  methods: {
    // TODO: esc handlers
    // TODO: tab handlers
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    scrollHandler() {
      const throttle = (func, limit) => {
        let inThrottle
        return function () {
          const args = arguments
          const context = this
          if (!inThrottle) {
            func.apply(context, args)
            inThrottle = true
            setTimeout(() => (inThrottle = false), limit)
          }
        }
      }

      window.addEventListener(
        'scroll',
        throttle(() => {
          if (window.scrollY > 100) {
            this.scrolled = true
          } else {
            this.scrolled = false
          }
        }, 50)
      )
    },

  },
  beforeDestroy() {
    window.removeEventListener('scroll', () => {})
  },
  watch: {
    $route() {
      this.menuOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-down-leave-active,
.slide-down-enter-active {
  transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
.slide-down-enter {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}
.slide-down-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.slide-down-leave-active {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease-in-out 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
