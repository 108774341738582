<template>
  <div v-if="content.length">
    <storyblok-resolver
      v-for="contentItem in content"
      :key="contentItem._uid"
      :block="contentItem"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    content: {
      type: Array,
      default: () => []
    },
    experimentName: {
      type: String,
      required: true
    },
    isServer: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.isServer && !this.isStoryblok) {
      window.analytics?.track('AB Test Seen', {
        experiment_name: this.experimentName,
        variation_name: this.name
      })
      this.$cookies.set(this.experimentName, this.name, {
        maxAge: 60 * 60 * 24 * 7
      })
    }
  },
  computed: {
    isStoryblok() {
      return this.$route.query._storyblok
    }
  }
}
</script>
