<template>
  <div class="text-center">
    <p class="text-cyan-500 text-sm flex items-center justify-center">
      <Icon
        file="CheckCircleOutline"
        alt="Checkmark Circle"
        class="mr-2"
      />Registration complete
    </p>
    <p class="text-cyan-900 text-2xl">
      We look forward to seeing you at our upcoming webinar.
    </p>
    <p class="text-gray-700 text-sm">
      You will receive an email with your registration information shortly.
    </p>
  </div>
</template>

<script>
import Icon from '~/components/BaseIcon/BaseIcon'
export default {
  components: {
    Icon
  }
}
</script>
