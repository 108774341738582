<template>
  <div>
      <NumberRow v-for="({number, text}, i) in data" :number="number" :text="text" :key="`numbers-row-${i}`" class="mb-12 last:mb-0" />
  </div>
</template>

<script>
import NumberRow from "./NumberRow.vue"
export default {
components: {
    NumberRow
},
props: {
    data: {
        type: Array
    }
}
}
</script>