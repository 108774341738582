<template>
  <div class="bg-white pb-40 px-7 -mb-60">
    <div class="max-w-[1080px] w-full mx-auto">
      <div
        class="event-grid-filters mb-10 text-2xl md:text-3xl text-cyan-900 self-start"
      >
        <span class="mr-3">Show me</span>
        <select
          name="filters"
          @change="selectFilter"
          class="focus-visible:ring-1 ring-cyan-500"
        >
          <option value="All Events">All Events</option>
          <option
            v-for="category in categories"
            :key="category.id"
            :value="category.value"
          >
            {{ category.dimension_value }}
          </option>
        </select>
      </div>
      <h2 class="text-cyan-900 text-2xl mb-8">Upcoming Events</h2>

      <div
        v-if="upcomingFilteredEvents.length"
        class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6"
      >
        <event-card
          v-for="event in upcomingFilteredEvents"
          :key="event.uuid"
          v-bind="event"
        />
      </div>
      <div class="no-found" v-else>No upcoming events found.</div>
      <h2 class="text-cyan-900 text-2xl mb-8 mt-16 lg:mt-[72px]">
        Past Events & Recordings
      </h2>
      <div
        v-if="visiblePastFilteredEvents.length"
        class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6"
      >
        <event-card
          v-for="event in visiblePastFilteredEvents"
          :key="event.uuid"
          v-bind="event"
          is-past
        />
      </div>
      <div class="no-found" v-else>No past events and recordings found.</div>
      <div
        v-if="pastFilteredEvents.length > visiblePastFilteredEvents.length"
        class="flex justify-center mt-6 md:mt-8"
      >
        <nacelle-button color="primary" no-icon @click="loadMore"
          >Load More</nacelle-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import NacelleButton from '~/components/NacelleButton/NacelleButton'
import EventCard from '~/components/Events/EventCard/EventCard'
import { mapEvent } from '~/components/Events/mapEvent'

export default {
  components: {
    EventCard,
    NacelleButton
  },
  data() {
    return {
      eventStories: [],
      categories: [],
      currentFilter: 'All Events',
      visiblePastNumber: 4
    }
  },
  computed: {
    events() {
      return this.eventStories.map((story) => mapEvent(story))
    },
    filteredEvents() {
      if (this.currentFilter === 'All Events') {
        return this.events
      } else {
        return this.events.filter(
          (event) => event.category === this.currentFilter
        )
      }
    },
    pastFilteredEvents() {
      return this.filteredEvents
        .filter((event) => this.isPastEvent(event.timeStamp))
        .sort((a, b) => b.timeStamp - a.timeStamp)
    },
    upcomingFilteredEvents() {
      return this.filteredEvents
        .filter((event) => !this.isPastEvent(event.timeStamp))
        .sort((a, b) => a.timeStamp - b.timeStamp)
    },
    currentTimeStamp() {
      return new Date().getTime()
    },
    visiblePastFilteredEvents() {
      return this.pastFilteredEvents.slice(0, this.visiblePastNumber)
    }
  },
  async fetch() {
    const version = process.env.PREVIEW ? 'draft' : 'published'
    this.eventStories = await this.$storyapi
      .get('cdn/stories', {
        version,
        is_startpage: 0,
        starts_with: 'events/',
        per_page: 100
      })
      .then(
        (res) => res.data.stories,
        (err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      )
    this.categories = await this.getCategories()
  },
  methods: {
    selectFilter(event) {
      this.currentFilter = event.target.value
    },
    async getCategories() {
      return this.$storyapi
        .get('cdn/datasource_entries', {
          datasource: 'event-categories',
          dimension: 'plural'
        })
        .then((res) => {
          return res.data.datasource_entries
        })
    },
    isPastEvent(eventTimeStamp) {
      /**
       * Sets the current date and event date (plus one hour since most events last about an hour) to time in miliseconds for comparison (i.e.: 1631734700814)
       */
      const eventEndTimeStamp = eventTimeStamp + 1 * 60 * 60 * 1000
      return this.currentTimeStamp > eventEndTimeStamp
    },
    loadMore() {
      this.visiblePastNumber += 4
    }
  }
}
</script>

<style scoped lang="scss">
.event-grid-filters select {
  @apply h-full text-2xl  md:text-3xl text-red-500 cursor-pointer rounded-none bg-transparent border-0 border-b border-red-500 shadow-none appearance-none w-auto p-0 pt-2 pr-11 bg-no-repeat;
  // needed to change color of a background svg here, that's why I'm using it this way
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='7px' viewBox='0 0 10 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Enoun_down chevron_3079137%3C/title%3E%3Cg id='Dark-Theme' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='5.1-Blog' transform='translate(-376.000000  -426.000000)' fill='%23ec3d67' fill-rule='nonzero'%3E%3Cg id='noun_down-chevron_3079137' transform='translate(376.000000  426.000000)'%3E%3Cpath d='M0.146153991 0.699539922 L0.146153991 0.699539922 C-0.0487180088 0.920383657 -0.0487180088 1.27791836 0.146153991 1.49820028 L4.27793649 6.16872783 C4.66818 6.61042076 5.301264 6.61042076 5.691507 6.16872783 L9.85377 1.46431117 C10.046643 1.24572017 10.0491415 0.892707281 9.8587665 0.671296273 C9.6643945 0.445368898 9.344105 0.442543443 9.146735 0.665083543 L5.3382395 4.97074002 C5.142868 5.19158376 4.826576 5.19158376 4.6312045 4.97074002 L0.852689492 0.699539922 C0.657817492 0.478690731 0.341525492 0.478690731 0.146153991 0.699539922' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: 95% 65%;
}
</style>
