import Caption from './Caption'
import Heading from './Heading'
import HeroText from './HeroText'
import SubHeading from './SubHeading'
import Paragraph from './Paragraph'
import Rubric from './Rubric'
import PagesHeader from './PagesHeader'

export default {
  Caption,
  Heading,
  HeroText,
  SubHeading,
  Paragraph,
  Rubric,
  PagesHeader
}
