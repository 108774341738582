<template>
  <nav
    data-cy="docs-sidebar"
    class="fixed left-0 top-16 bottom-0 h-[calc(100vh-4rem)] z-10 bg-white w-screen transition ease-in-out duration-300 lg:w-72 lg:border-solid lg:border-0 lg:border-r lg:border-opacity-50 lg:border-gray-400 lg:top-20 lg:height-[calc(100vh-5rem)]"
    :class="{
      'sidebar-closed -translate-x-full opacity-0 lg:translate-x-[-90%] lg:opacity-100':
        !sidebarOpen,
      'block opacity-100': sidebarOpen
    }"
  >
    <button
      :aria-label="sidebarOpen ? 'Close sidebar' : 'Open sidebar'"
      :aria-expanded="sidebarOpen.toString()"
      data-cy="docs-sidebar-toggle"
      class="hidden lg:block absolute -right-4 top-3 w-8 h-8 p-0 rounded-full flex items-center justify-center bg-gray-300 border-none cursor-pointer shadow-light"
      @click="setSidebarOpen(!sidebarOpen)"
    >
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="transition ease-in-out duration-300 origin-center mt-[2px] ml-[-2px] rotate-180"
        :class="{
          'transform rotate-0 ml-[2px]': !sidebarOpen
        }"
      >
        <path
          d="M0.110107 12.42L1.29011 13.6L7.89011 6.99999L1.29011 0.399994L0.110107 1.57999L5.53011 6.99999L0.110107 12.42H0.110107Z"
          fill="#9FA1A2"
          :class="{
            'fill-current text-gray-900': sidebarOpen,
            'fill-current text-gray-500': !sidebarOpen
          }"
        />
      </svg>
    </button>
    <div
      class="scrollable h-full flex flex-col overflow-auto"
      :class="{ 'overflow-hidden': !sidebarOpen }"
    >
      <div
        class="flex-1 w-full transition ease-in-out duration-300"
        :class="{
          'opacity-0': !sidebarOpen
        }"
        :inert="!sidebarOpen"
      >
        <DocsTechStack class="px-6" />
        <DocsNavigation class="px-6" />
      </div>

      <footer
        class="w-full border-solid border-0 border-t border-opacity-50 border-gray-400 bg-white pt-4 pb-8 transition ease-in-out duration-300"
        :class="{
          'opacity-0': !sidebarOpen
        }"
        :inert="!sidebarOpen"
      >
        <div class="px-6 text-sm">
          <ul class="list-none m-0 p-0">
            <li class="mb-2">
              <a href="/docs/changelog" class="text-gray-600 no-underline">
                Nacelle Changelog
              </a>
            </li>
            <li>
              <a href="/" class="no-underline text-red-500">
                Go to nacelle.com
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useDocsStore } from '~/store/docs'
import DocsNavigation from '~/components/DocsNavigation/DocsNavigation'
import DocsTechStack from './DocsTechStack'

export default {
  name: 'DocsSidebar',
  components: {
    DocsNavigation,
    DocsTechStack
  },
  methods: {
    ...mapActions(useDocsStore, ['setSidebarOpen'])
  },
  computed: {
    ...mapState(useDocsStore, ['techStack', 'sidebarOpen', 'nav']),
    isPreview() {
      return (
        this.$nuxt.context.isDev ||
        this.$route.query._storyblok ||
        this.$route.query.preview ||
        process.env.PREVIEW
      )
    }
  },
  mounted() {
    // sets an initial state
    const width = window.innerWidth
    if (width < 1024) {
      this.setSidebarOpen(false)
    } else {
      this.setSidebarOpen(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.docs-sidebar {
  overflow-y: auto;
  overflow-x: auto;

  @media (min-width: 1024px) {
    overflow-x: visible;
  }
}

.scrollable::-webkit-scrollbar {
  @apply w-0;
}
</style>
