var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content w-auto",class:[_vm.themeLoader, _vm.spacingValues(_vm.spacing)]},[_c('div',{staticClass:"grid grid-cols-1 mx-auto",class:[
      _vm.columnGap,
      {
        'sm:grid-cols-2 lg:grid-cols-4': _vm.blocks.length === 4,
        'sm:grid-cols-2 lg:grid-cols-3': _vm.blocks.length === 3,
        'sm:grid-cols-2 lg:grid-cols-2': _vm.blocks.length === 2,
        'max-w-[1080px]': _vm.hasContainerMaxWidth
      }
    ]},[_vm._l((_vm.blocks),function(ref){
    var column = ref.column;
    var spacing = ref.spacing;
return [_c('div',{key:column._uid,class:[_vm.spacingValues(spacing)]},_vm._l((column),function(block){return _c('StoryblokResolver',{key:block._uid,attrs:{"block":block}})}),1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }