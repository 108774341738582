export default (block) => {
  const logos = []
  block.events.forEach((event) => {
    if (event.content.logo && event.content.logo.filename) {
      logos.push({
        src: event.content.logo.filename,
        alt: event.content.logo.alt
      })
    }
  })
  block.logos = logos
  return block
}
