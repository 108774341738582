<template>
  <div class="flex justify-between">
      <NavSearch />
      <BaseButton label="Login" variant="secondaryDark" class="mx-[14px]"/>
      <BaseButton label="Book a Demo" />
  </div>
</template>

<script>
import NavSearch from "./NavSearch/NavSearch"
export default {
    components: {
        NavSearch
    }
}
</script>

<style>

</style>