<template>
  <div
    class="case-study-section-container mb-12"
    :class="{
      'light-section': theme === 'light',
      'mt-[200px]': hasTopSkew,
      'mt-[50px]': !hasTopSkew
    }"
  >
    <div
      :class="`case-study-bg ${hasTopSkew ? 'dual-skew' : 'single-skew'}`"
    ></div>
    <div
      class="flex justify-center content-center items-center flex-col max-w-[1170px] mx-auto my-0"
    >
      <div class="title w-[90%] lg:w-full">
        <h6 v-if="title">{{ title }}</h6>
        <h2 v-if="description">{{ description }}</h2>
      </div>
      <div
        class="flex flex-col lg:flex-row flex-nowrap lg:flex-wrap justify-center w-full"
      >
        <div
          v-for="caseStudy in caseStudies"
          :key="caseStudy._uid"
          class="case-study-column px-5 md:px-0"
        >
          <CaseStudyCard
            :description="caseStudy.description"
            :brandName="caseStudy.brandName"
            :logoSrc="caseStudy.brandLogo.filename"
            :url="caseStudy.linkPath.cached_url"
          />
        </div>
      </div>

      <NacelleButton
        v-if="buttonText"
        @click="goTo(buttonPath.cached_url)"
        class="-mt-12"
      >
        {{ buttonText }}
      </NacelleButton>
    </div>
  </div>
</template>

<script>
import CaseStudyCard from '~/components/CaseStudyCard/CaseStudyCard.vue'
import NacelleButton from '~/components/NacelleButton/NacelleButton.vue'

export default {
  name: 'CaseStudySection',
  components: { CaseStudyCard, NacelleButton },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => {
        return ['light', 'dark'].indexOf(value) !== -1
      }
    },
    caseStudies: {
      type: Array,
      required: true
    },
    hasTopSkew: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonPath: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    goTo(path) {
      if (path.includes('http')) {
        window.open(path, '_blank')
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  h6 {
    margin: 0;
    padding: 0 20px;
  }
  h2 {
    color: #fff;
    margin-top: 20px;
    max-width: 969px;
    padding: 0 20px;
  }
}
.case-study-column {
  flex: 0 50%;
  @include touch {
    flex: auto !important;
  }
}
.light-section {
  position: relative;
  width: 100%;
  &::after {
    content: '';
    display: block;
    // height: 150%;
    width: 100%;
    border-radius: 5px;
    position: absolute;
    z-index: -1000;
    top: 0;
    left: 0;
    background: linear-gradient(
        159.31deg,
        rgba(0, 0, 0, 0) 13.71%,
        #001c27 76.35%
      ),
      linear-gradient(
        270deg,
        rgba(195, 20, 50, 0.6) 0%,
        rgba(177, 0, 61, 0.6) 14.29%,
        rgba(156, 0, 68, 0.6) 28.57%,
        rgba(132, 0, 72, 0.6) 42.86%,
        rgba(108, 0, 73, 0.6) 57.14%,
        rgba(83, 7, 70, 0.6) 71.43%,
        rgba(59, 11, 64, 0.6) 85.71%,
        #001c27 100%
      ),
      #001c27;
  }
  .case-study-bg {
    background-color: #ffffff;
    position: absolute;
    top: -22%;
    height: 80%;
    width: 100%;
    z-index: -2;
  }
  .dual-skew {
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
  }
  .single-skew {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  }
  h6 {
    text-align: center;
    margin: 10px 0;
  }
  h2 {
    margin: 10px auto 70px;
    color: #010204 !important;
    font-size: 36px;
    letter-spacing: 0.64px;
    line-height: 47px;
    text-align: center;
    max-width: 969px;
  }
  @include touch {
    margin-top: 100px;
    .case-study-bg {
      background-color: #fff;
      position: absolute;
      top: -5%;
      height: 40%;
      width: 100%;
      z-index: -2;

      .dual-skew {
        clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
      }
      .single-skew {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
      }
    }
    h2 {
      margin: 10px 28px 50px;
      font-size: 18px;
      letter-spacing: 0.32px;
      line-height: 23px;
      text-align: center;
    }
  }
}

@include touch {
  .case-study-section-container {
    margin-top: 50px;
    h3 {
      margin: 0 31px;
    }
    p {
      margin: 10px 31px;
    }
  }
}
</style>
