<template>
  <button
    :aria-label="sidebarOpen ? 'Close sidebar' : 'Open sidebar'"
    :aria-expanded="sidebarOpen.toString()"
    data-cy="docs-sidebar-toggle"
    class="border-0 bg-opacity-0 bg-white w-7 p-1 cursor-pointer"
    @click="sidebarToggle"
  >
    <span class="block w-full h-[2px] bg-gray-500"></span>
    <span class="block w-full h-[2px] bg-gray-500 my-1"></span>
    <span class="block w-full h-[2px] bg-gray-500"></span>
  </button>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useDocsStore } from '~/store/docs'

export default {
  name: 'DocsHeaderButton',
  methods: {
    ...mapActions(useDocsStore, ['setSidebarOpen']),
    sidebarToggle() {
      this.setSidebarOpen(!this.sidebarOpen)
    }
  },
  computed: {
    ...mapState(useDocsStore, ['sidebarOpen'])
  }
}
</script>
