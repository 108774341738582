<template>
  <div class="tb-container py-[130px] lg:p-[100px] mt-24 lg:mt-0">
    <div class="tb-red-bg">
      <div class="tb-red-bg-inner"></div>
    </div>
    <p class="text-black text-center mb-12">{{ title }}</p>
    <div
      class="flex flex-wrap justify-center items-center mb-12 max-w-[1170px] mx-auto"
    >
      <div class="py-4 px-5" v-for="image in logos" :key="image.id">
        <img
          class="max-w-[150px] lg:max-w-full"
          :src="image.filename"
          :alt="image.alt"
        />
      </div>
    </div>
    <NacelleButton
      v-if="ctaText && ctaLink.cached_url"
      @click="goTo(ctaLink.cached_url)"
      color="primary"
      >{{ ctaText }}</NacelleButton
    >
  </div>
</template>

<script>
import NacelleButton from '~/components/NacelleButton/NacelleButton'

export default {
  components: {
    NacelleButton
  },
  props: {
    title: String,
    ctaText: String,
    ctaLink: Object,
    logos: Array
  },
  methods: {
    goTo(path) {
      if (path.includes('http')) {
        window.open(path, '_blank')
      } else {
        this.$router.push('/' + path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tb-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    z-index: -1;
    background: #fff;
    -webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
  }

  .tb-red-bg {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
    background: #c5103b;
    clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%);
  }
  .tb-red-bg-inner {
    position: absolute;
    top: 5%;
    height: 90%;
    width: 100%;
    z-index: -2;
    background: linear-gradient(233.09deg, #f56789 -10%, #c4103b 120%);
    clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%);
  }
}
</style>
