import { defineStore } from 'pinia'

export const useBannerStore = defineStore('banner', {
  state: () => ({
    open: false
  }),
  actions: {
    setOpen(bool) {
      this.open = bool
    }
  }
})
