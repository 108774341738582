<template>
  <textarea
    v-bind="$attrs"
    :placeholder="label"
    v-on="$listeners"
    :id="id"
    :name="id"
  ></textarea>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    id: {
      type: String
    }
  }
}
</script>
