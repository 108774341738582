<template>
  <div class="relative flex" :class="theme.checkbox.wrapper">
    <label
      class="order-last mr-2 select-none"
      :class="theme.checkbox.label"
      :for="id"
      >{{ label }}</label
    >
    <span
      :class="[
        theme.checkbox.box,
        theme.checkbox.border.el,
        {
          [theme.checkbox.backgroundColor.checked]: checked,
          [theme.checkbox.border.checked]: checked,
          [theme.checkbox.backgroundColor.unchecked]: !checked,
          [theme.checkbox.border.unchecked]: !checked,
          [theme.checkbox.focus]: isFocused
        }
      ]"
    >
      <i
        class="absolute top-[1px] border-r-3 border-b-3 border-t-0 border-l-0 border-solid w-[6px] h-[10px] transform rotate-45 duration-100 scale-1 ease-out transform-center"
        :class="{
          'opacity-0 scale-0': !checked,
          [theme.checkbox.checkmarkColor]: checked
        }"
      ></i>
    </span>
    <input
      v-bind="$attrs"
      :placeholder="label"
      :id="id"
      :name="id"
      class="absolute w-5 h-5 appearance-none outline-none border-none"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String
    },
    id: {
      type: String
    },
    hidden: { type: Boolean },
    theme: {
      type: Object
    },
    defaultValue: {
      type: String
    }
  },
  data: () => ({
    checked: false,
    isFocused: false
  }),
  created() {
    if (this.defaultValue === 'yes') this.checked = true
  },
  methods: {
    handleChange(e) {
      e.target.value = this.checked ? 'on' : 'off'
      this.$emit('input', e)
      this.checked = !this.checked
    },
    handleFocus(e) {
      this.isFocused = true
    },
    handleBlur(e) {
      this.isFocused = false
    }
  }
}
</script>
