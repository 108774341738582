<template>
  <input
    v-bind="$attrs"
    :placeholder="label"
    v-on="$listeners"
    :id="id"
    :name="id"
    :class="{ hidden: hidden }"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String
    },
    id: {
      type: String
    },
    hidden: { type: Boolean }
  }
}
</script>

<style scoped>
::-moz-placeholder {
  opacity: 1;
}
</style>
