<template>
  <div style="color: white">
    {{ testField }}
  </div>
</template>

<script>
export default {
  props: {
    testField: String
  }
}
</script>

<style></style>
