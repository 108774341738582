const revenueMap = {
  '$0-$5M': 5000000,
  '$5M-$10M': 10000000,
  '$10M-$50M': 50000000,
  '$50M-$100M': 100000000,
  '$100M+': 100000001
}
export default (entry, form, vm) => {
  if ('AnnualRevenue' in form) {
    form.AnnualRevenue = revenueMap[form.AnnualRevenue]
  }

  window.ChiliPiper &&
    window.ChiliPiper.submit(entry.accountName, entry.router, {
      lead: {
        ...form
      }
    })
}
