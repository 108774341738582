import { defineStore } from 'pinia'

export const useVideoStore = defineStore('video', {
  state: () => ({
    modalFile: null,
    videoModal: false
  }),
  getters: {
    getModalFile: (state) => {
      return state.modalFile
    },
    getVideoModal: (state) => {
      return state.videoModal
    },
    actions: {
      setModalFile(fileUrl) {
        this.modalFile = fileUrl
      },
      toggleVideoModal() {
        this.videoModal = !this.videoModal
      }
    }
  }
})
