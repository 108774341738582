<template>
  <div>
    <img
      class="hidden lg:block my-0 mx-auto"
      :class="colWidth"
      :alt="image.alt"
      :src="image.filename"
    />
    <img
      class="w-full lg:hidden"
      :alt="mobileImage.alt || image.alt"
      :src="mobileImage.filename || image.filename"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true
    },
    mobileImage: {
      type: Object,
      default: () => ({
        alt: '',
        filename: ''
      })
    },
    columnSpan: {
      type: String,
      default: '12'
    }
  },

  computed: {
    colWidth() {
      // read this medium article to learn more why I couldn't just use template string interpolation here (doesn't work with TW JIT engine):
      // https://medium.com/coding-at-dawn/the-tailwind-css-jit-mode-bug-that-only-happens-in-production-4f25ef009fe8
      switch (this.columnSpan) {
        case '1':
          return 'w-1/12'
        case '2':
          return 'w-2/12'
        case '3':
          return 'w-3/12'
        case '4':
          return 'w-4/12'
        case '5':
          return 'w-5/12'
        case '6':
          return 'w-6/12'
        case '7':
          return 'w-7/12'
        case '8':
          return 'w-8/12'
        case '9':
          return 'w-9/12'
        case '10':
          return 'w-10/12'
        case '11':
          return 'w-11/12'
        default:
          return 'w-full'
      }
    }
  }
}
</script>
