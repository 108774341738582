<template>
  <BaseModal>
    <template v-slot:button>
      <div
        class="flex flex-col h-full transform translate-y-0 hover:-translate-y-1"
      >
        <div class="relative h-[250px] video-card-bg">
          <div class="absolute p-4 text-white">{{ video.videoPreTitle }}</div>
          <div class="absolute w-full h-full flex items-center justify-center">
            <img
              src="/nacelle-playbutton.svg"
              class="filter invert w-[45px]"
              alt=""
            />
          </div>
        </div>
        <div class="bg-white h-auto p-4 flex-1 flex flex-col">
          <div class="flex items-center text-[#F6002B] mb-[5px]">
            <NacelleClock color="#F6002B" class="w-4 h-4" />
            <span class="ml-[10px] text-[14px] tracking-[.5px]">{{
              video.videoDuration
            }}</span>
          </div>
          <div
            class="text-[26px] leading-[30px] mb-[10px] tracking-[.54px] flex-grow text-left"
          >
            {{ video.videoTitle }}
          </div>
          <div
            class="flex items-center mt-8 mb-[14px] text-base text-[#00BAFF] font-semibold"
          >
            Watch Now
            <img
              src="/nacelle-arrow-right.svg"
              class="ml-[5px] watch-arrow"
              alt="right-arrow"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <video
        width="100%"
        height="auto"
        controls="controls"
        class="VideoListCard__Modal_Video"
        :src="video.videoUrl"
      >
        Your browser does not support the video tag.
      </video></template
    >
  </BaseModal>
</template>

<script>
import { mapActions } from 'pinia'
import { useVideoStore } from '~/store/video'
import NacelleClock from './NacelleClock.vue'
import BaseModal from '~/components/BaseModal/BaseModal'

export default {
  components: {
    NacelleClock,
    BaseModal
  },
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions(useVideoStore, ['toggleVideoModal', 'setModalFile']),
    handleModalClick() {
      this.setModalFile(this.videoUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-card-bg {
  background: url('https://nacelle.com/video-bg.png') top left rgb(30, 39, 72);
  background-size: cover;
}
.watch-arrow {
  margin-left: 5px;
  filter: invert(41%) sepia(12%) saturate(4731%) hue-rotate(196deg)
    brightness(89%) contrast(90%);
}
</style>
