<template>
  <section class="max-w-[969px] mx-auto">
    <rich-text-renderer v-if="content" :document="content" />
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
