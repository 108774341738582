export default () => {
  const rootScript = document.getElementsByTagName('script')[0]
  const cpScript = document.createElement('script')
  cpScript.type = 'text/javascript'
  cpScript.async = true
  cpScript.src = 'https://js.chilipiper.com/marketing.js'

  const cp2Script = document.createElement('script')
  cp2Script.text = `function q(a) {
    return function() {
      ChiliPiper[a].q = (ChiliPiper[a].q || []).concat([arguments])
    }
  }
  window.ChiliPiper =
    window.ChiliPiper ||
    'submit scheduling showCalendar submit widget bookMeeting'
      .split(' ')
      .reduce(function(a, b) {
        a[b] = q(b)
        return a
      }, {})`

  rootScript.parentNode.insertBefore(cp2Script, rootScript)
  rootScript.parentNode.insertBefore(cpScript, rootScript)
}
