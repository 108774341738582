import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    loaded: false,
    globals: null,
    logo: null,
    mainNav: null,
    landingNav: null,
    landingNavPages: null,
    footer: null,
    inStoryblok: false,
    queryParams: {},
    globalSections: null
  }),
  actions: {
    setGlobals(globals) {
      this.globals = globals
      this.logo = globals.logo
      this.mainNav = globals.mainNav
      this.landingNav = globals.landingNav
      this.landingNavPages = globals.landingNavPages
      this.globalSections = globals.sections

      // footer mapping
      this.footer = {}
      const footerG2widget = globals.footer.find(
        (item) => item.component === 'G2Widget'
      )
      const footerNavSection = globals.footer.find(
        (item) => item.title === 'Footer Navigation'
      )
      const footerLegalSection = globals.footer.find(
        (item) => item.title === 'Footer Legal'
      )
      this.footer.g2widget = footerG2widget
      this.footer.navSection = footerNavSection.sections
      this.footer.legalSection = footerLegalSection.sections[0]
    },
    setQueryParams(query) {
      for (let keyToAdd in query) {
        if (
          query.hasOwnProperty(keyToAdd) &&
          !this.queryParams.hasOwnProperty(keyToAdd)
        ) {
          this.queryParams[keyToAdd] = query[keyToAdd]
        }
      }
    },
    setLoaded(loaded) {
      this.loaded = loaded
    }
  }
})
