<template>
  <div data-cy="docs-feedback" class="relative z-10">
    <!-- mobile tab -->
    <div class="lg:hidden fixed bottom-0 right-0 w-36 h-10">
      <div
        class="absolute bottom-6 -right-full -rotate-90 origin-bottom-left flex items-center justify-center bg-white shadow h-full w-full transition ease-in-out duration-300"
        :class="{
          'opacity-0 translate-x-4 pointer-events-none': !init || mobileInit,
          'opacity-100 translate-x-0': init && !mobileInit
        }"
      >
        <button
          @click="handleMobileInit()"
          class="border-0 bg-white bg-opacity-0 cursor-pointer text-sm text-gray-700 p-0 flex items-center"
        >
          <span class="text-lg mr-4 opacity-50">&#129300;</span>Feedback
        </button>

        <button
          class="border-0 bg-white bg-opacity-0 cursor-pointer p-0 ml-4 flex items-center"
          @click="init = false"
        >
          <img src="/svg/gray-x.svg" alt="" />
        </button>
      </div>
    </div>

    <!-- main tab -->
    <div
      data-cy="docs-feedback-tab"
      class="fixed bottom-6 left-6 right-6 bg-white border-0 shadow px-3 py-2 transition ease-in-out duration-300 lg:right-6 lg:left-[unset]"
      :class="{
        'lg:opacity-0 lg:translate-y-4 lg:pointer-events-none': !init,
        'lg:opacity-100 lg:translate-y-0 pointer-events-auto': init,
        'opacity-0 translate-y-4 pointer-events-none': !mobileInit,
        'opacity-100 translate-y-0': mobileInit
      }"
    >
      <button
        class="absolute top-3 right-3 transition ease-in-out border-0 bg-white bg-opacity-0 cursor-pointer p-0"
        :class="{
          'opacity-0 pointer-events-none': !expanded,
          'opacity-100': expanded
        }"
        :tabindex="expanded? 0 : -1"
        aria-label="Hide feedback form"
        @click="handleClose"
      >
        <img src="/svg/gray-x.svg" alt="" />
      </button>
      <div v-if="!submitted">
        <p class="text-sm text-gray-700 mt-0 mb-1">
          How's your docs experience?
        </p>
        <div class="flex">
          <button
            v-for="(button, index) in buttonOptions"
            :key="index"
            :aria-label="button.label"
            @click="handleClick(button)"
            class="text-xl border-0 cursor-pointer p-0 pr-1 mr-2 w-6 h-6 flex items-center justify-center bg-white bg-opacity-0 transition"
            :class="{
              'opacity-50': button !== activeButton,
              'opacity-100': button === activeButton
            }"
          >
            <span
              v-html="button.icon"
              class="block w-full ml-[.17em] mt-[.038em]"
            />
          </button>
        </div>
        <div v-if="requireRating" aria-live="polite" class="text-xs text-red-500">
          Please select a rating
        </div>
      </div>
      <div
        v-if="expanded"
        class="lg:w-80"
      >
        <div
          v-if="!submitted"
          data-cy="docs-feedback-form"
        >
          <p class="text-sm text-gray-700 mt-6 mb-1">
            Thank you. Can you explain the reasoning for your rating?<br />
            <span class="text-xs text-gray-600">
              (optional)
            </span>
          </p>
          <form
            @submit.prevent="handleSubmit"
          >
            <div class="flex">
              <label
                class="sr-only"
                for="feedback-text"
                id="feedback-text-label"
              >
                Feedback
              </label>
              <textarea
                aria-labelledby="feedback-text-label"
                name="feedback-text"
                class="w-full border-1 border-gray-700 p-1"
                rows="3"
                ref="feedback"
              />
            </div>
            <div class="flex justify-end mt-2">
              <button
                type="submit"
                class="text-sm border-0 cursor-pointer py-2 px-3 bg-red-700 text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div
          v-if="submitted"
          data-cy="docs-feedback-submitted"
        >
          <p class="text-sm text-black my-3">
            Thanks for your feedback!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      init: false,
      mobileInit: false,
      activeButton: null,
      requireRating: false,
      buttonOptions: [
        {
          icon: '&#128545;',
          label: 'Hate it'
        },
        {
          icon: '&#128577;',
          label: 'Not good'
        },
        {
          icon: '&#128528;',
          label: 'Just OK'
        },
        {
          icon: '&#128578;',
          label: 'Pretty Good'
        },
        {
          icon: '&#128525;',
          label: 'Love it'
        }
      ],
      expanded: false,
      submitted: false
    }
  },
  methods: {
    handleClick(button) {
      this.expanded = true
      this.requireRating = false
      this.activeButton = button
    },
    handleMobileInit() {
      this.mobileInit = true
      this.expanded = true
    },
    handleSubmit() {
      if (this.activeButton) {
        this.submitted = true
        window.analytics?.track('Docs Feedback Submitted', {
          rating: this.activeButton.label,
          feedback: this.$refs.feedback.value
        })
      } else {
        this.requireRating = true
      }
    },
    handleClose() {
      if (this.submitted) {
        // reset the state
        this.init = false
        this.mobileInit = false
        setTimeout(() => {
          this.activeButton = false
          this.expanded = false
          this.submitted = false
        }, 500)
      } else {
        this.submitted = false
        this.expanded = false
        this.mobileInit = false
        this.activeButton = false
      }
    }
  },
  mounted() {
    if (this.$route?.path !== '/docs/') {
      setTimeout(() => {
        this.init = true
      }, 1000)
    }
  },
  watch: {
    $route() {
      if (this.$route.path !== '/docs/') {
        setTimeout(() => {
          this.init = true
        }, 1000)
      }
    }
  }
}
</script>
