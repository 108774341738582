import Vue from 'vue'

// List Components
const components = {
  ABTest: require('~/components/ABTest/ABTest'),
  Accordion: require('~/components/Accordion/Accordion'),
  ActionHero: require('~/components/ActionHero/ActionHero'),
  Banner: require('~/components/Banner/Banner'),
  BlogGrid: require('~/components/Blog/BlogGrid'),
  BlogPost: require('~/components/BlogPost/BlogPost'),
  CareersAccordion: require('~/components/CareersAccordion/CareersAccordion'),
  Carousel: require('~/components/Carousel/Carousel'),
  CaseStudyCard: require('~/components/CaseStudyCard/CaseStudyCard'),
  CaseStudyContent: require('~/components/CaseStudyContent/CaseStudyContent'),
  CaseStudySection: require('~/components/CaseStudySection/CaseStudySection'),
  ContentCard: require('~/components/ContentCard/ContentCard'),
  CaseStudyCustomerDetails: require('~/components/CaseStudyCustomerDetails/CaseStudyCustomerDetails'),
  Cta: require('~/components/Cta/Cta'),
  CultureValues: require('~/components/CultureValues/CultureValues'),
  DocumentationContent: require('~/components/Docs/DocumentationContent/DocumentationContent'),
  EventHero: require('~/components/EventHero/EventHero'),
  EventGrid: require('~/components/Events/EventGrid/EventGrid'),
  EventLogoGrid: require('~/components/Events/EventLogoGrid/EventLogoGrid'),
  FeaturedEvent: require('~/components/Events/FeaturedEvent/FeaturedEvent'),
  Features: require('~/components/Features/Features'),
  FindUs: require('~/components/FindUs/FindUs'),
  ResourceForm: require('~/components/ResourceForm/ResourceForm'),
  ImageBlock: require('~/components/ImageBlock/ImageBlock'),
  KeyStats: require('~/components/KeyStats/KeyStats'),
  MarketoForm: require('~/components/MarketoForm'),
  NewsAndUpdates: require('~/components/NewsAndUpdates/NewsAndUpdates'),
  NewsletterPopout: require('~/components/NewsletterPopout/NewsletterPopout'),
  NSection: require('~/components/NSection/NSection'),
  Page: require('~/components/Page'),
  Partners: require('~/components/Partners/Partners'),
  Quote: require('~/components/Quote/Quote'),
  RevealingHero: require('~/components/RevealingHero/RevealingHero'),
  ResourceContent: require('~/components/ResourceContent/ResourceContent'),
  ResourceGrid: require('~/components/Resources/ResourceGrid'),
  RichText: require('~/components/RichText/RichText'),
  Sandbox: require('~/components/Sandbox/Sandbox'),
  SiteForm: require('~/components/SiteForm/SiteForm'),
  SpeakersSection: require('~/components/SpeakersSection/SpeakersSection'),
  StoryblokResolver: require('~/components/StoryblokResolver'),
  SectionRichText: require('~/components/SectionRichText/SectionRichText'),
  TeamTestimonial: require('~/components/TeamTestimonial/TeamTestimonial'),
  TestBlock: require('~/components/TestBlock/TestBlock'),
  TextHero: require('~/components/TextHero/TextHero'),
  TrustedBrands: require('~/components/TrustedBrands/TrustedBrands'),
  TwoColForm: require('~/components/TwoColForm/TwoColForm'),
  VideoEmbed: require('~/components/VideoEmbed/VideoEmbed'),
  VideoSeriesHosts: require('~/components/VideoSeriesHosts/VideoSeriesHosts'),
  VideoSeriesItem: require('~/components/VideoSeriesItem/VideoSeriesItem'),
  VideoSeriesVideoListSection: require('~/components/VideoSeriesVideoListSection/VideoSeriesVideoListSection'),
  WebinarHero: require('~/components/WebinarHero/WebinarHero')
}
// New Rebuild Components
const componentsRebuild = {
  Hero: require('~/components/V2/Hero/Hero'),
  Button: require('~/components/V2/Base/BaseButton/BaseButton'),
  Section: require('~/components/V2/Section/Section'),
  CaseStudy: require('~/components/V2/CaseStudy/CaseStudy'),
  Testimonials: require('~/components/V2/Testimonials/Testimonials'),
  GlobalSections: require('~/components/V2/GlobalSections/GlobalSections'),
  Carousel: require('~/components/V2/Carousel/Carousel'),
  PageHeader: require('~/components/V2/PageHeader/PageHeader')
}
// Register components
for (const name in components) {
  Vue.component(name, components[name].default)
}

for (const name in componentsRebuild) {
  Vue.component(name, componentsRebuild[name].default)
}
