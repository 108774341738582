import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
    state: () => ({
        pageHasModalOpen: false
    }),
    actions: {
        isModalOpen(bool) {
            this.pageHasModalOpen = bool
        }
    }
})