import { get } from 'es-cookie'
import { v4 as uuid } from 'uuid'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    userId: null
  }),
  actions: {
    setUser(userId) {
      this.userId = userId
    },
    getUser() {
      const userId = get('userId')
      userId ? this.setUser(userId) : this.setUser(uuid())
    }
  }
})
