<template>
  <div
    class="event-card bg-white hover:shadow-lg hover:cursor-pointer relative"
    :class="
      isFeatured
        ? 'max-w-[1080px] mx-auto lg:flex shadow-light lg:h-[300px]'
        : 'border-2 border-solid border-gray-300 lg:h-[610px]'
    "
    @click="goTo(slug)"
  >
    <div
      class="lg:h-[300px] lg:min-w-[444px] max-w-full"
      :class="{ 'lg:min-w-[540px]': isFeatured }"
    >
      <img
        v-if="image"
        :src="image.filename"
        :alt="image.alt"
        class="w-full h-full object-cover"
      />
    </div>
    <div class="py-4 px-6">
      <p class="uppercase text-red-500 text-xs m-0">{{ category }}</p>
      <nuxt-link
        :to="`/${slug}`"
        class="no-underline"
        @click.native="$event.stopImmediatePropagation()"
      >
        <h4
          class="text-cyan-900 font-medium text-lg leading-snug mt-4 mb-4 lg:mb-2 lg:h-[52px]"
        >
          {{ title }}
        </h4>
      </nuxt-link>
      <p
        class="text-gray-600 text-xs md:text-sm font-semibold m-0 flex items-center"
      >
        <Icon file="Calendar" alt="Calendar Icon" class="text-red-500" />&nbsp;
        {{ formattedDate }}&nbsp;
        <Icon file="Middot" alt="Dot Icon" class="text-red-500" />&nbsp;
        {{ formattedTime }}
      </p>
      <p
        class="text-gray-600 text-xs md:text-sm font-semibold mb-7 lg:mb-0 mx-0 mt-1 flex items-center"
      >
        <Icon file="Location" alt="Location Icon" class="text-red-500" />&nbsp;
        {{ location }}
      </p>
      <p class="text-cyan-900 text-sm leading-relaxed hidden md:block">
        {{ description }}
      </p>
      <div class="card-arrow static lg:absolute lg:bottom-4 flex items-center">
        <p v-if="isWebinar && isPast">Watch Recording</p>
        <p v-else-if="isWebinar">Register</p>
        <p v-else>Details</p>
        <Icon
          file="ArrowForward"
          alt="Forward Arrow Icon"
          class="text-cyan-500"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '~/components/BaseIcon/BaseIcon'
export default {
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: () => ({
        filename: '',
        alt: ''
      })
    },
    slug: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    dateTime: {
      type: String,
      required: true
    },
    location: {
      type: String,
      required: true
    },
    isFeatured: {
      type: Boolean,
      default: false
    },
    isPast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isWebinar() {
      return this.category === 'Webinar'
    },
    formattedDate() {
      return this.dateTime.split('@')[0]
    },
    formattedTime() {
      return this.dateTime.split('@')[1]
    }
  },
  methods: {
    goTo(path) {
      console.log(path)
      this.$router.push(`/${path}`)
    }
  }
}
</script>

<style scoped lang="scss">
.event-card {
  &:focus-within {
    @apply ring-1 ring-cyan-500;

    /deep/ a:focus {
      @apply outline-none;
    }
  }
}
.card-arrow {
  p {
    color: #00baff;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin: 0 12px 0 0;
  }
}
</style>
