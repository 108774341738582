<template>
  <div class="flex flex-col justify-center">
    <h1>404 - Not found!</h1>
    <p>Hmm... can't find that page.</p>
  </div>
</template>

<script>
export default {}
</script>
