<template>
  <BaseSection
    :bg-color="bgColor"
    :bg-hex="backgroundHex"
    :bg-image="backgroundImage.filename"
    :bg-position="backgroundPosition"
    :bg-cover="backgroundCover"
    padding="px-10 py-16"
  >
    <div
      class="flex"
      :class="{
        'flex-row-reverse': type === 'contentRight',
        'flex-row': type === 'contentLeft'
      }"
    >
      <div
        :class="{
          'w-1/2': isTwoCol,
          'w-full': isSingleCol,
          'w-2/5': isNarrowTwoCol,
          'max-w-2xl w-full text-center mx-auto': type === 'singleCentered'
        }"
      >
        <div
          :class="{
            'ml-20': type === 'contentRight',
            'mr-20': type === 'contentLeft' || isNarrowTwoCol,
            'pt-20': isTwoCol && illustration.filename
          }"
        >
          <Heading type="heading" class="mb-10">
            <SplitHeading v-if="isMulticolorHeading" :heading="header" />
            <span v-else>{{ header }}</span></Heading
          >
          <RichTextRenderer :document="copy" />
          <Cta
            v-if="cta.length"
            :cta="cta[0]"
            :class="{ 'mx-auto': type === 'singleCentered' }"
          />
        </div>
      </div>
      <div
        v-if="numberCards.length && (isTwoCol || isNarrowTwoCol)"
        :class="{
          'w-1/2': isTwoCol,
          'w-3/5': isNarrowTwoCol
        }"
      >
        <NumberCards :number-cards="numberCards" />
      </div>
      <div
        v-if="iconCards.length && (isTwoCol || isNarrowTwoCol)"
        :class="{
          'w-1/2': isTwoCol,
          'w-3/5': isNarrowTwoCol
        }"
      >
        <IconCards :icon-cards="iconCards" />
      </div>
      <div
        v-if="illustration.filename && (isTwoCol || isNarrowTwoCol)"
        class="flex justify-center"
        :class="{
          'w-1/2': isTwoCol,
          'w-3/5': isNarrowTwoCol
        }"
      >
        <img
          :src="illustration.filename"
          :alt="illustration.alt"
          class="max-w-full"
        />
      </div>
    </div>
    <LogoGrid v-if="logoGrid.length" :logo-cards="logoGrid[0].logoCard" />
  </BaseSection>
</template>

<script>
import BaseSection from '~/components/V2/Base/BaseSection/BaseSection.vue'
import Cta from '~/components/V2/Cta/Cta.vue'
import NumberCards from '~/components/V2/NumberCards/NumberCards.vue'
import IconCards from '~/components/V2/IconCards/IconCards.vue'
import SplitHeading from '~/components/V2/Base/BaseText/SplitHeading'
import LogoGrid from '~/components/V2/LogoGrid/LogoGrid.vue'

export default {
  components: {
    BaseSection,
    Cta,
    NumberCards,
    IconCards,
    SplitHeading,
    LogoGrid
  },
  props: {
    illustration: {
      type: Object,
      default: () => ({
        filename: '',
        alt: ''
      })
    },
    header: {
      type: String,
      required: true
    },
    copy: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return (
          [
            'contentRight',
            'contentLeft',
            'singleLeft',
            'singleCentered',
            'narrowContent'
          ].indexOf(value) !== -1
        )
      }
    },
    cta: {
      type: Array,
      default: () => []
    },
    numberCards: {
      type: Array,
      default: () => []
    },
    iconCards: {
      type: Array,
      default: () => []
    },
    logoGrid: {
      type: Array,
      default: () => []
    },
    backgroundColor: {
      type: String,
      default: 'white',
      validator: (value) => {
        return ['white', 'lightGray', 'darkGray'].indexOf(value) !== -1
      }
    },
    backgroundHex: {
      type: String
    },
    backgroundImage: {
      type: Object,
      default: () => ({
        filename: '',
        alt: ''
      })
    },
    backgroundPosition: {
      type: String,
      default: 'top center'
    },
    backgroundCover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgColor() {
      if (this.backgroundColor === 'lightGray') return 'bg-gray-100'
      if (this.backgroundColor === 'darkGray') return 'bg-[#CBCCD6]'
      return 'bg-white'
    },
    isTwoCol() {
      return this.type === 'contentRight' || this.type === 'contentLeft'
    },
    isSingleCol() {
      return this.type === 'singleLeft' || this.type === 'singleCentered'
    },
    isNarrowTwoCol() {
      return this.type === 'narrowContent'
    },
    isMulticolorHeading() {
      return this.header.includes('*')
    }
  }
}
</script>
