var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex",class:_vm.theme.checkbox.wrapper},[_c('label',{staticClass:"order-last mr-2 select-none",class:_vm.theme.checkbox.label,attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('span',{class:[
      _vm.theme.checkbox.box,
      _vm.theme.checkbox.border.el,
      ( _obj = {}, _obj[_vm.theme.checkbox.backgroundColor.checked] = _vm.checked, _obj[_vm.theme.checkbox.border.checked] = _vm.checked, _obj[_vm.theme.checkbox.backgroundColor.unchecked] = !_vm.checked, _obj[_vm.theme.checkbox.border.unchecked] = !_vm.checked, _obj[_vm.theme.checkbox.focus] = _vm.isFocused, _obj )
    ]},[_c('i',{staticClass:"absolute top-[1px] border-r-3 border-b-3 border-t-0 border-l-0 border-solid w-[6px] h-[10px] transform rotate-45 duration-100 scale-1 ease-out transform-center",class:( _obj$1 = {
        'opacity-0 scale-0': !_vm.checked
      }, _obj$1[_vm.theme.checkbox.checkmarkColor] = _vm.checked, _obj$1 )})]),_vm._v(" "),_c('input',_vm._b({staticClass:"absolute w-5 h-5 appearance-none outline-none border-none",attrs:{"placeholder":_vm.label,"id":_vm.id,"name":_vm.id},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},'input',_vm.$attrs,false))])}
var staticRenderFns = []

export { render, staticRenderFns }