<template>
  <div
    class="w-auto max-w-[1080px] py-10 md:py-12 mx-auto flex flex-col md:flex-row"
  >
    <div class="px-10 w-auto md:w-1/2 flex flex-col justify-center">
      <h2
        class="my-0 text-[24px] leading-[30px] md:text-[32px] md:leading-[44px] font-semibold"
      >
        {{ title }}
      </h2>
      <p class="text-base leading-[20px] font-normal mt-1">
        {{ description }}
      </p>
      <div>
        <button
          v-if="buttonUrl"
          type="button"
          class="w-auto appearance-none py-2 px-4 mt-4 md:mt-7 border-2 border-solid border-white bg-transparent text-white font-semibold text-[14px] rounded-[10px]"
          @click="handleClick"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <div
      class="text-center mt-8 mx-10 md:m-0 md:mr-10 py-6 px-12 border-2 border-solid border-white px-10 w-auto md:w-1/2 rounded"
    >
      <h3 class="m-0 p-0 text-[14px]">{{ cardTitle }}</h3>
      <p class="text-[60px] font-bold my-[50px]">{{ cardText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    buttonText: {
      type: String
    },
    buttonUrl: {
      type: String
    },
    cardTitle: {
      type: String
    },
    cardText: {
      type: String
    }
  },
  methods: {
    handleClick() {
      if (this.buttonUrl) {
        window.open(this.buttonUrl, '_blank')
      }
      if ('ChiliPiper' in window) {
      }
    }
  }
}
</script>

<style></style>
