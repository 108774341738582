<template>
  <section :class="{ 'bg-white py-12 px-5 lg:px-0': theme === 'light' }">
    <figure
      class="max-w-4xl m-0 mx-auto relative my-12"
      :class="
        isHorizontal
          ? 'horizontal border-0 lg:border-b border-solid border-cyan-500 pl-20 lg:pb-12'
          : 'flex flex-col items-center'
      "
    >
      <blockquote
        class="text-xl lg:text-2xl"
        :class="[
          isHorizontal
            ? 'pt-0 mt-2 mb-0 mx-5 leading-7 lg:leading-9'
            : 'pt-14 mt-2 mb-6 mx-10 leading-8 tracking-tight text-center',
          textColor
        ]"
      >
        {{ quote }}
      </blockquote>
      <figcaption
        class="flex flex-col text-lg"
        :class="
          isHorizontal
            ? 'ml-5 mt-11 lg:mt-6'
            : 'lg:flex-row text-center items-center'
        "
      >
        <h5
          class="my-0"
          :class="[
            isHorizontal ? 'lg:text-lg' : 'lg:text-2xl italic font-normal',
            textColor
          ]"
        >
          {{ name }}
        </h5>
        <span
          v-if="!isHorizontal"
          class="w-16 h-0 border border-solid border-cyan-500 m-3"
        />
        <span
          :class="[
            isHorizontal ? 'lg:text-xl' : 'lg:text-2xl italic',
            textColor
          ]"
          >{{ title }}</span
        >
      </figcaption>
    </figure>
  </section>
</template>

<script>
export default {
  props: {
    quote: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: 'vertical',
      validator: (value) => {
        return ['horizontal', 'vertical'].indexOf(value) !== -1
      }
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => {
        return ['light', 'dark'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    isHorizontal() {
      return this.layout === 'horizontal'
    },
    textColor() {
      return this.theme === 'dark' ? 'text-white' : 'text-black'
    }
  }
}
</script>

<style lang="scss" scoped>
figure::before {
  content: '';
  position: absolute;
  left: calc(50%-40px);
  top: 0;
  width: 80px;
  height: 60px;
  background: url(/nacelle-quote.svg);
  background-position: top center;
  filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
    brightness(102%) contrast(104%);
  background-repeat: no-repeat;
}
figure.horizontal::before {
  top: 5px;
  left: 0px;
  height: 100px;
  background-position: top left;
}
</style>
