var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative z-10",attrs:{"data-cy":"docs-feedback"}},[_c('div',{staticClass:"lg:hidden fixed bottom-0 right-0 w-36 h-10"},[_c('div',{staticClass:"absolute bottom-6 -right-full -rotate-90 origin-bottom-left flex items-center justify-center bg-white shadow h-full w-full transition ease-in-out duration-300",class:{
        'opacity-0 translate-x-4 pointer-events-none': !_vm.init || _vm.mobileInit,
        'opacity-100 translate-x-0': _vm.init && !_vm.mobileInit
      }},[_c('button',{staticClass:"border-0 bg-white bg-opacity-0 cursor-pointer text-sm text-gray-700 p-0 flex items-center",on:{"click":function($event){return _vm.handleMobileInit()}}},[_c('span',{staticClass:"text-lg mr-4 opacity-50"},[_vm._v("🤔")]),_vm._v("Feedback\n      ")]),_vm._v(" "),_c('button',{staticClass:"border-0 bg-white bg-opacity-0 cursor-pointer p-0 ml-4 flex items-center",on:{"click":function($event){_vm.init = false}}},[_c('img',{attrs:{"src":"/svg/gray-x.svg","alt":""}})])])]),_vm._v(" "),_c('div',{staticClass:"fixed bottom-6 left-6 right-6 bg-white border-0 shadow px-3 py-2 transition ease-in-out duration-300 lg:right-6 lg:left-[unset]",class:{
      'lg:opacity-0 lg:translate-y-4 lg:pointer-events-none': !_vm.init,
      'lg:opacity-100 lg:translate-y-0 pointer-events-auto': _vm.init,
      'opacity-0 translate-y-4 pointer-events-none': !_vm.mobileInit,
      'opacity-100 translate-y-0': _vm.mobileInit
    },attrs:{"data-cy":"docs-feedback-tab"}},[_c('button',{staticClass:"absolute top-3 right-3 transition ease-in-out border-0 bg-white bg-opacity-0 cursor-pointer p-0",class:{
        'opacity-0 pointer-events-none': !_vm.expanded,
        'opacity-100': _vm.expanded
      },attrs:{"tabindex":_vm.expanded? 0 : -1,"aria-label":"Hide feedback form"},on:{"click":_vm.handleClose}},[_c('img',{attrs:{"src":"/svg/gray-x.svg","alt":""}})]),_vm._v(" "),(!_vm.submitted)?_c('div',[_c('p',{staticClass:"text-sm text-gray-700 mt-0 mb-1"},[_vm._v("\n        How's your docs experience?\n      ")]),_vm._v(" "),_c('div',{staticClass:"flex"},_vm._l((_vm.buttonOptions),function(button,index){return _c('button',{key:index,staticClass:"text-xl border-0 cursor-pointer p-0 pr-1 mr-2 w-6 h-6 flex items-center justify-center bg-white bg-opacity-0 transition",class:{
            'opacity-50': button !== _vm.activeButton,
            'opacity-100': button === _vm.activeButton
          },attrs:{"aria-label":button.label},on:{"click":function($event){return _vm.handleClick(button)}}},[_c('span',{staticClass:"block w-full ml-[.17em] mt-[.038em]",domProps:{"innerHTML":_vm._s(button.icon)}})])}),0),_vm._v(" "),(_vm.requireRating)?_c('div',{staticClass:"text-xs text-red-500",attrs:{"aria-live":"polite"}},[_vm._v("\n        Please select a rating\n      ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.expanded)?_c('div',{staticClass:"lg:w-80"},[(!_vm.submitted)?_c('div',{attrs:{"data-cy":"docs-feedback-form"}},[_vm._m(0),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"flex"},[_c('label',{staticClass:"sr-only",attrs:{"for":"feedback-text","id":"feedback-text-label"}},[_vm._v("\n              Feedback\n            ")]),_vm._v(" "),_c('textarea',{ref:"feedback",staticClass:"w-full border-1 border-gray-700 p-1",attrs:{"aria-labelledby":"feedback-text-label","name":"feedback-text","rows":"3"}})]),_vm._v(" "),_vm._m(1)])]):_vm._e(),_vm._v(" "),(_vm.submitted)?_c('div',{attrs:{"data-cy":"docs-feedback-submitted"}},[_c('p',{staticClass:"text-sm text-black my-3"},[_vm._v("\n          Thanks for your feedback!\n        ")])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-sm text-gray-700 mt-6 mb-1"},[_vm._v("\n          Thank you. Can you explain the reasoning for your rating?"),_c('br'),_vm._v(" "),_c('span',{staticClass:"text-xs text-gray-600"},[_vm._v("\n            (optional)\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-end mt-2"},[_c('button',{staticClass:"text-sm border-0 cursor-pointer py-2 px-3 bg-red-700 text-white",attrs:{"type":"submit"}},[_vm._v("\n              Submit\n            ")])])}]

export { render, staticRenderFns }