const VueScrollTo = require('vue-scrollto')

const scrollToCustomElement = (selector) => {
  const formId = document.querySelector(selector).id
  const formContainer = document.getElementById(formId).parentNode
  const formOpacity = window
    .getComputedStyle(formContainer)
    .getPropertyValue('opacity')
  const offset = formOpacity === '0' ? -170 : -120
  VueScrollTo.scrollTo(`#${formId}`, 300, {
    offset
  })
}

// initSmoothScroll functions
const removeTabIndex = (element) => {
  element.removeAttribute('tabindex')
  element.removeEventListener('blur', () => {})
}

const initSmoothScroll = (topOffset) => {
  setTimeout(() => {
    document.querySelectorAll('a[href*="#"]').forEach((anchor) => {
      if (anchor.getAttribute('href') !== '#') {
        anchor.addEventListener('click', function(e) {
          e.preventDefault()
          const elementId = this.getAttribute('href').split('#')[1]
          const scrollTimer = anchor.getAttribute('data-scroll-timer') || 500

          VueScrollTo.scrollTo(`#${elementId}`, {
            duration: scrollTimer,
            offset: topOffset,
            easing: 'ease-in-out',
            cancelable: false,
            onDone: (element) => {
              element.setAttribute('tabindex', '-1')
              element.focus()
              element.addEventListener('blur', removeTabIndex(element))
            }
          })
        })
      }
    })
  }, 500)
}

export { scrollToCustomElement, initSmoothScroll }
