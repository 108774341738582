<template>
  <div
    class="blog-card relative"
    :class="{ 'blog-card-gradient': !image }"
    @click="goTo(handle)"
  >
    <div class="blog-card-image-box">
      <img v-if="image" :src="image.filename" :alt="image.alt" />
    </div>

    <p v-if="category" class="blog-card-category">{{ category }}</p>

    <h4>
      <nuxt-link :to="handle" class="no-underline text-white font-medium">
        {{ title }}
      </nuxt-link>
    </h4>

    <img
      class="blog-card-arrow"
      src="/nacelle-arrow-right.svg"
      alt="nacelle arrow right"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    handle: {
      type: String,
      required: true
    },
    category: {
      type: String,
      default: ''
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.blog-card {
  height: 480px;
  width: 366px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  margin: 25px 8px;
  border-bottom: 1px solid #00baff;
  color: #fff;
  position: relative;
  transition: all 0.3s ease;

  &::after {
    position: absolute;
    width: 0;
    content: '';
    height: 3px;
    left: 0;
    bottom: -2px;
    background: #00baff;
    transition: all 0.3s ease;
  }

  &:hover {
    cursor: pointer;
    &::after {
      width: 100%;
    }
  }

  &:focus-within {
    @apply ring-1 ring-cyan-500;

    /deep/ a:focus {
      @apply outline-none;
    }
  }
}

h4 {
  font-weight: 400;
}

.blog-card-gradient {
  background: #0e1328;

  &:hover {
    background: #22346b;
  }

  h4 {
    font-weight: 700;
  }

  .blog-card-image-box {
    height: 30px;
  }
}

.blog-card-category {
  position: absolute;
  top: -2px;
  left: 21px;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #fff;
  color: #00baff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.42px;
  line-height: 19px;
  text-align: center;
  // text-transform: uppercase;
}
.blog-card-image-box,
.blog-card-image-box img {
  width: 370px;
}

h4 {
  font-size: 28px;
  letter-spacing: 0.88px;
  line-height: 40px;
  // margin: 30px;
}

.blog-card-arrow {
  position: absolute;
  bottom: 20px;
  right: 30px;
  filter: invert(58%) sepia(35%) saturate(4655%) hue-rotate(162deg)
    brightness(102%) contrast(104%); //Makes color #00BAFF (Blue)
}

@include touch {
  .blog-card-image-box,
  .blog-card-image-box img {
    width: 312px;
  }
}
</style>
