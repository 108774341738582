import { defineStore } from 'pinia'

export const useFormStore = defineStore('form', {
    state: () => ({
        email: null,
        firstName: null,
        lastName: null,
        headlessPopup: false
      }),
      getters: {
        headlessPopup: (state) => {
            return state.headlessPopup
          }
      },
      actions: {
        setEmail(email) {
            this.email = email
          },
          setFirstName(name) {
            this.firstName = name
          },
          setLastName(name) {
            this.lastName = name
          },
          toggleHeadlessPopup() {
            this.headlessPopup = !this.headlessPopup
          }         
      }
})
  