<template>
  <div
    class="flex flex-col md:flex-row max-w-[1170px] my-0 mx-auto"
    :data-time-state="timeState"
  >
    <div class="m-5 lg:ml-5" style="flex: 50%">
      <p class="eyebrow mb-5">{{ category }}</p>
      <h1 class="page-title">{{ title }}</h1>
      <div v-if="!isPastEvent">
        <p>{{ webinarDate }}</p>
      </div>
      <nacelle-button @click="scrollToElement" class="hero-button mx-0 my-1">
        {{ btnTextComparison }}
      </nacelle-button>
    </div>
    <div class="flex my-o mx-5 justify-center items-center" style="flex: 50%">
      <img class="max-w-full" :src="image.filename" :alt="image.alt" />
    </div>
  </div>
</template>

<script>
import NacelleButton from '~/components/NacelleButton/NacelleButton'
import { convertDateTimeToUserTimezone } from '~/utils/dateTime'
import { scrollToCustomElement } from '~/utils/scroll'

export default {
  name: 'WebinarHero',
  props: {
    storyFields: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    pastBtnText: {
      type: String,
      default: 'Watch Now'
    },
    futureBtnText: {
      type: String,
      default: 'Register'
    },
    intermediateText: {
      type: String,
      default: 'Stay tuned for the recap!'
    },
    date: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: () => ({
        filename: {
          type: String,
          default: ''
        },
        alt: {
          type: String,
          default: ''
        }
      })
    }
  },
  components: {
    NacelleButton
  },
  computed: {
    timeState() {
      if (this.btnTextComparison === this.pastBtnText) {
        return 'past'
      }
      if (this.btnTextComparison === this.intermediateText) {
        return 'intermediate'
      } else {
        return 'future'
      }
    },
    btnTextComparison() {
      if (!this.isPastEvent) {
        return this.futureBtnText
      } else {
        if (
          this.storyFields.webinarLink &&
          this.storyFields.webinarLink.cached_url
        ) {
          return this.pastBtnText
        } else {
          return this.intermediateText
        }
      }
    },
    currentDate() {
      return new Date().getTime()
    },
    eventDate() {
      /**
       * this.date always comes back in UTC time from Storyblok, regardless of user's timezone selected in SB
       */
      return new Date(`${this.date.replace(/-/g, '/')} UTC`).getTime()
    },
    isPastEvent() {
      /**
       * Sets the current date and event date (plus one hour since most webinars last about an hour) to time in miliseconds for comparison (i.e.: 1631734700814)
       */
      const eventEndDate = this.eventDate + 1 * 60 * 60 * 1000
      return this.currentDate > eventEndDate
    },
    webinarDate() {
      return convertDateTimeToUserTimezone(
        this.eventDate,
        'MMM. dd, yyyy @ h:mm a zzz'
      )
    }
  },
  methods: {
    scrollToElement() {
      scrollToCustomElement('[id^="siteform-"]')
    }
  }
}
</script>

<style lang="scss" scoped>
.webinar-link__button /deep/ button {
  background: #00baff;
  padding: 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);

  &:hover {
    background: #22346b;
  }
}
</style>
