<template>
  <component :is="`h${attrs.level}`" :id="getId">
    <slot />
  </component>
</template>

<script>
/**
 * Tailwind Whitelist. Custom styles available via Storyblok Rich Text classes:
 * text-3xl lg:text-5xl font-semibold leading-tight lg:leading-tight
 */
export default {
  name: 'RichTextHeading',
  props: {
    attrs: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    getId() {
      if (this.isMounted) {
        return (
          'h' +
          this.attrs.level +
          this.$el.innerText.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
        )
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.isMounted = true
  }
}
</script>
