<template>
  <div class="doc-content">
    <rich-text-renderer v-if="body.content" :document="body.content" />
  </div>
</template>

<script>
import Prism from '~/plugins/prism'
export default {
  name: 'DocumentationContent',
  props: {
    body: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mounted() {
    Prism.highlightAll()
  }
}
</script>
<style lang="scss" scoped>
.doc-content {
  /deep/ {
    * {
      letter-spacing: normal;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #001c27;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
    }
    ul,
    ol,
    p {
      color: #505a5f;
      font-size: 16px;
      > code {
        background-color: #eaeaea;
      }
    }
    a {
      color: #009dd8;
    }
    hr {
      width: 100%;
      border: none;
      border-top: 1px solid #cacaca;
    }
    img {
      width: 100%;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 18px;
    }
    pre > code {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}
</style>
