<template>
  <div
    class="flex flex-col text-white font-semibold text-[12px] leading-5 tracking-[4%] sm:text-[14px] sm:leading-[18px]"
  >
    <div class="flex text-white" v-if="!isPastEvent">
      <div class="flex items-center">
        <Icon file="Calendar" class="mr-2 text-red-500" alt="Calendar" />{{
          date
        }}
      </div>
      <div class="flex items-center ml-2">
        <Icon file="Middot" class="mr-2 text-red-500" alt="Ellipse" />{{ time }}
      </div>
    </div>
    <div class="flex items-center">
      <Icon file="Location" class="mr-2 text-red-500" alt="Location" />{{
        location
      }}
    </div>
  </div>
</template>

<script>
import Icon from '~/components/BaseIcon/BaseIcon.vue'

export default {
  components: {
    Icon
  },
  props: {
    date: { type: String },
    time: { type: String },
    location: { type: String },
    isPastEvent: { type: Boolean, default: false }
  }
}
</script>

<style></style>
