<template>
  <div class="bg-white py-5 lg:py-11 px-7">
    <div class="max-w-[1080px] w-full mx-auto flex flex-col items-center">
      <p v-if="title" class="text-red-500 font-medium mb-2 mt-5 mx-0">
        {{ title }}
      </p>
      <h2
        v-if="subtitle"
        class="text-cyan-900 font-semibold text-2xl lg:text-[33px] lg:leading-snug m-0 mb-11 text-center"
      >
        {{ subtitle }}
      </h2>
      <div class="flex flex-wrap justify-center">
        <speaker-card
          v-for="speaker in speakers"
          :key="speaker._uid"
          v-bind="speaker"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SpeakerCard from './SpeakerCard.vue'
export default {
  components: {
    SpeakerCard
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    speakers: {
      type: Array,
      default: () => []
    }
  }
}
</script>
