<template>
  <div
    class="site-comparison relative flex items-center justify-center max-w-screen-xl my-5 mx-auto lg:my-20"
  >
    <span v-if="description" class="sr-only">{{ description }}</span>
    <video ref="comparison" @click="handlePlayClick" muted loop class="w-full">
      <source :src="video" type="video/mp4" />
    </video>
    <img
      v-if="placeholder"
      :src="placeholder"
      alt=""
      class="absolute inset-0 h-full w-full object-cover object-center z-[98] transition-opacity ease-in-out duration-300"
      :class="{ 'opacity-0 pointer-events-none': hasPlayed }"
    />
    <button
      @click="handlePlayClick"
      class="w-20 h-20 bg-cyan-500 bg-opacity-0 border-none absolute z-[99] cursor-pointer transition-opacity ease-in-out duration-300"
      :class="{ 'opacity-0 pointer-events-none': isPlaying }"
      :aria-label="isPlaying ? 'Pause video' : 'Play video'"
      :aria-hidden="[isPlaying]"
    >
      <img src="/playbutton-nacelle.svg" class="w-full h-full" alt="" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    video: String,
    description: String,
    placeholder: String
  },
  data() {
    return {
      isPlaying: false,
      hasPlayed: false
    }
  },
  methods: {
    handlePlayClick() {
      this.hasPlayed = true
      this.$refs.comparison.paused
        ? this.$refs.comparison.play()
        : this.$refs.comparison.pause()
      this.isPlaying = !this.$refs.comparison.paused
    }
  }
}
</script>
